
import React, { useEffect, useState } from "react";

import {Helmet} from "react-helmet";
import HeaderLogin from "./Components/HeaderLogin";
import Image1 from "./images/help-desk-1.png"
import Image2 from "./images/help-desk-2.png"
import Phone from "./images/Phone.svg"
import Feedback from "./images/feedback-logo.svg"
import ScheduleCall from "./images/scheduleCall.svg"
import Certification from "./images/certification-logo.svg"
import HelpTicket from "./images/help-ticket-logo.svg"
import EarlyAlert from "./images/early-alert-logo.svg"
import CoachingPods from "./images/coaching-pod-logo.svg"
import Letter from "./images/letter-logo.svg"
import ArrowBack from "./images/arrow-back-icon.svg"
import Graduate from "./images/graduate-story.svg"
import Blogging from "./images/blogging.svg"
import { Link } from "react-router-dom";

const boxStyle = 'p-5 hover:shadow-lg flex items-center gap-y-2 max-w-300  flex-col h-full bg-white rounded-lg'
const boxStyleMobile = 'hover:shadow-lg grid grid-cols-4 gap-y-2 items-center max-w-300  flex-row h-full '
const boxStyleMobileOpacityEffect = 'hover:opacity-90 grid grid-cols-4 gap-y-2 items-center max-w-300 mx-auto sm:mx-0 flex-row h-full '

const links = [
    {
        logo : <img src={HelpTicket} className='w-9 block' alt="Help Ticket Icon"/>,
        title: 'Submit a Help Ticket',
        text: 'Ask a question or report a problem. Our support team will answer you within one business day.',
        link: '/help-ticket'
    },
    {
        logo : <img src={ScheduleCall} className='w-9 block bg-white' alt="Schedule a Call Icon"/>,
        title: 'Speak with an Advisor',
        text: 'We invite you to share in an open and honest way your personal experience with the Coach Training EDU training program in which you  completed.',
        link: 'https://meetings.hubspot.com/andrew-hock/andrew-hock'
    },
]

const linksStudentsAlumni = [
    {
        logo : <img src={Certification} className='w-9 block' alt="Request Certification Icon"/>,
        title: 'Certification Request',
        text: 'After completing your coach training program, request your certification by completing this form and the required supporting documentation.',
        link: '/certification-application'
    },
    {
        logo : <img src={Letter} className='w-9 block' alt="Letter of Completion Icon"/>,
        title: 'Letter of Completion',
        text: 'Submit your application for the official Letter of Completion with CTEDU.',
        link: '/letter-of-completion'
    },
    {
        logo : <img src={EarlyAlert} className='w-9 block' alt="Early Alert Icon"/>,
        title: 'Submit Early Alert',
        text: 'Please use this form to share with our Early Alert Coordinator the details of a coach of concern, so we may offer the necessary support for their success.',
        link: '/early-alert'
    },
    {
        logo : <img src={CoachingPods} className='w-9 block' alt="Coaching pods Icon"/>,
        title: 'Coaching Pod Registration',
        text: 'Coaching Pods provide an opportunity for CTEDU coaches to partner for practice on your own schedule.',
        link: '/coaching-pod'
    },
    {
        logo : <img src={Feedback} className='w-9 block' alt="Feedback Icon"/>,
        title: 'Program Training Feedback',
        text: 'We invite you to share in an open and honest way your personal experience with the Coach Training EDU training program in which you  completed.',
        link: '/feedback-programmatic-trainers'
    },
    {
        logo : <img src={Graduate} className='w-9 block' alt="Graduate Story Icon"/>,
        title: 'Submit Your Graduate Story',
        text: 'We love the inspiration that comes from hearing our coaches story.',
        link: '/graduate-story-submission'
    },
    {
        logo : <img src={Blogging} className='w-9 block' alt="Guest Icon"/>,
        title: 'Guest Speaking & Blogging',
        text: 'Submit ideas to be considered for a guest speaking spot or blog guest writer for our Coaching to Flourish Series.',
        link: '/guest-submission'
    }
]

function LinkCard(logo, title, text, link){
    
    if(link.includes('https')){
        return(
            <>
                <a href={link} className={`hidden sm:flex ${boxStyle}`}>
                    {logo}
                    <p className="text-lg font-bold">{title}</p>
                    <p className="text-center">{text}</p>
                </a>
                <a href={link} className={`flex sm:hidden ${boxStyleMobile}`}>
                    <div className="flex justify-center items-center mr-2 rounded-lg bg-white w-16 h-16">
                        {logo}
                    </div>
                    <div  className="col-span-3">
                        <p className="text-lg font-bold">{title}</p>
                        <p >{text}</p>
                    </div>
                </a>
            </>
        )
    }
    return(
        <>
            <Link to={link} className={`hidden sm:flex ${boxStyle}`}>
                {logo}
                <p className="text-lg font-bold">{title}</p>
                <p className="text-center">{text}</p>
            </Link>
            <Link to={link} className={`flex sm:hidden ${boxStyleMobile}`}>
                <div className=" mr-2 flex justify-center items-center rounded-lg bg-white w-16 h-16">
                    {logo}
                </div>
                <div className="col-span-3">
                    <p className="text-lg font-bold">{title}</p>
                    <p >{text}</p>
                </div>
            </Link>
        </>
    )
}
 
function HelpDesk() {
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12 min-h-screen bg-newBranding-beige">
      <Helmet>
            <title>Coach Training EDU Help Desk Support</title>
            <meta name="description" content="The Coach Training EDU Support Portal is designed to make it easier for students and alumni to request help with specific problems, issues or questions." />
            <meta name="title" content="Coach Training EDU Help Desk Support" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full">
        <div className="max-w-1220 mx-auto">
            <div className="sm:px-10">
                <div className="flex mt-10 rounded-lg items-center mx-10 sm:mx-auto max-w-700 lg:max-w-1220 justify-between sm:px-10 py-5 m-auto bg-newBranding-jet">
                    <img className="w-36 lg:w-64 hidden sm:block" src={Image1} />
                    <h1 className="text-white text-2xl lg:text-5xl text-center">The Help Desk at<br/>Coach Training EDU</h1>
                    <img className="w-36 lg:w-64 hidden sm:block" src={Image2} />
                    <img className="w-20 sm:w-36 lg:w-64 block sm:hidden" src={Image1} />
                </div>
            </div>
            <div className="lg:max-w-1000 items-center w-max-content max-w-700 max-auto justify-center lg:justify-between m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10 gap-16">
                {links.map((link, i ) => {
                    return LinkCard(link.logo, link.title, link.text, link.link)
                })}
            </div>

            <hr className="my-10 mx-10 sm:mx-auto border-newBranding-beigeText max-w-700 lg:max-w-1140"/>
            <h2 className="text-2xl lg:text-3xl text-center font-serif font-medium">Student and Alumni Support</h2>
            <div className="lg:max-w-1000 items-center w-max-content max-w-700 max-auto justify-center lg:justify-between m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10 gap-16">
                {linksStudentsAlumni.map((link, i ) => {
                    return LinkCard(link.logo, link.title, link.text, link.link)
                })}
            </div>
            <hr className="my-10 mx-10 sm:mx-auto border-newBranding-beigeText max-w-700 lg:max-w-1140"/>
            <div className="max-w-700 lg:max-w-1000 mx-auto grid grid-cols-1 lg:grid-cols-2 mb-10 gap-10">
                <a href='tel:5033606700' className={` ${boxStyleMobileOpacityEffect}`}>
                    <div className="flex justify-center items-center mr-2 rounded-lg bg-white w-16 h-16">
                        <img src={Phone} className='w-9 block' alt="Contact Support Icon"/>
                    </div>
                    <div  className="col-span-3">
                        <p className="text-lg font-bold">Contact support</p>
                        <p>Phone: (503) 360.6700</p>
                    </div>
                </a>
                <a href='https://coachtrainingedu.com/' className={`${boxStyleMobileOpacityEffect}`}>
                    <div className="flex justify-center items-center mr-2 rounded-lg bg-white w-16 h-16">
                        <img src={ArrowBack} className='w-9 block' alt="Back to CTEDU Icon"/>
                    </div>
                    <div  className="col-span-3">
                        <p className="text-lg font-bold">Go to CTEDU home</p>
                        <p>CTEDU has coach training plans and packages for everyone.</p>
                    </div>
                </a>
            </div>
        </div>
      </div>
    </div>
  );
}
export default HelpDesk;