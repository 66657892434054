
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';

import Image from "../images/form-university.png"


 
function UniversityApplication() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [institutionName, setInstitutionName] = useState("")
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [birthdate, setBirthdate] =  useState("");
    const [previousCoachTraining, setPreviousCoachTraining] =  useState("");
    const [supervise, setSupervise] =  useState("");
    const [report, setReport] =  useState("");
    const [termsConditions, setTermsConditions] =  useState("");
    const [socialMedia, setSocialMedia] =  useState("");
    const [hope, setHope] =  useState("");
    const [namePreviousProgram, setNamePreviousProgram] =  useState("");
    const [position, setPosition] =  useState("");
    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['institutionName', 'firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'state', 'country', 'position', 'birthdate', 'previousCoachTraining', 'supervise', 'report', 'termsConditions']

    if(previousCoachTraining === 'yes'){
        required.push('namePreviousProgram')
    }

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setInstitutionName(values.institutionName)
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setPosition(values.position)
      setEmail(values.email)
      setPhone(values.phone)
      setState(values.state)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setBirthdate(values.birthdate)
      setPreviousCoachTraining(values.previousCoachTraining)
      setSupervise(values.supervise)
      setReport(values.report)
      setSocialMedia(values.socialMedia)
      setHope(values.hope)
      setTermsConditions(values.termsConditions)
      setNamePreviousProgram(values.namePreviousProgram)

      console.log('Report ', report)
      console.log('Supervise ', supervise)

     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['institutionName', 'firstName', 'lastName', 'email', 'phone', 'state', 'street', 'city', 'zip', 'country', 'position', 'birthdate', 'previousCoachTraining', 'supervise', 'report', 'termsConditions']

    if(previousCoachTraining === 'yes'){
        required.push('namePreviousProgram')
    }

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      institutionName:institutionName,
      firstName: firstname,
      lastName: lastname,
      position:position,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      birthdate:birthdate,
      previousCoachTraining:previousCoachTraining,
      supervise:supervise,
      report:report,
      termsConditions:termsConditions,
      socialMedia:socialMedia,
      hope:hope,
      namePreviousProgram:namePreviousProgram,
      university_application_submitted: true
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
      console.log('values ', values)
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'university-application'}

      axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/thank-you')
        })

       // navigate('/thank-you')
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>University Application</title>
            <meta name="description" content="University Application" />
            <meta name="title" content="CTEDU University Application" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <h1 className="text-4xl sm:text-5xl text-center lg:text-left text-white order-2 lg:order-1">University & Organization Application</h1>
            <div className=" m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='university-application' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className=" text-center lg:text-left font-bold pb-5">Basic Information</p>
        <div className="grid-cols-2 max-w-500 m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 border-newBranding-beigeText pb-10 w-full border-t pt-10">

            <div className="col-span-2 flex flex-col justify-between">
                <label>Name of Institution*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="institutionName"
                onChange={formik.handleChange}
                value={formik.values.institutionName}
                />
                {errorsValidation.institutionName ? <div className='text-red-500'>{errorsValidation.institutionName}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>First Name*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
                {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Last Name*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
                {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>What is your position at the University/Organization?*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="position"
                name="position"
                onChange={formik.handleChange}
                value={formik.values.position}
                />
                {errorsValidation.position ? <div className='text-red-500'>{errorsValidation.position}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Email*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
                {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Phone*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                />
                {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
            <label>Birthdate*</label>
            <input type='date'
                className="h-10 rounded-md w-full"
                value={formik.values.birthdate}
                onChange={formik.handleChange}
                name='birthdate'
                max={current}
                />
            {errorsValidation.birthdate ? <div className='text-red-500'>{errorsValidation.birthdate}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Social Media Handle</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="socialMedia"
                onChange={formik.handleChange}
                value={formik.values.socialMedia}
                />
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Address*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="street"
                onChange={formik.handleChange}
                value={formik.values.street}
                />
                {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>City*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                />
                {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>State*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="state"
                onChange={formik.handleChange}
                value={formik.values.state}
                />
                {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>Zip Code*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="zip"
                name='zip'
                onChange={formik.handleChange}
                value={formik.values.zip}
                />
                {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Country*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10"
                id="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                />
                {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>What do you hope to accomplish in this training?</label>
                <textarea
                    type="text"
                    className="rounded-md w-full h-32"
                    id="hope"
                    name='hope'
                    onChange={formik.handleChange}
                    value={formik.values.hope}
                    />
            </div>

            <div className="col-span-2 flex flex-col">
                <label>Have you previously completed a coach training?* </label>
                <div>
                <label className="radio-item relative">
                    <input
                    id="yes"
                    value="yes"
                    className='opacity-0'
                    checked={formik.values.previousCoachTraining === 'yes'}
                    name="previousCoachTraining"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.previousCoachTraining === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                    id="no"
                    value='no'
                    className='opacity-0'
                    checked={formik.values.previousCoachTraining === 'no'}
                    name="previousCoachTraining"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.previousCoachTraining === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.previousCoachTraining ? <div className='text-red-500'>{errorsValidation.previousCoachTraining}</div> : null}
                </div>
            </div>

            <div className="col-span-2 flex flex-col">
                <label>If yes, what was the name of the training program?</label>
                <input
                    type="text"
                    className="rounded-md w-full h-10"
                    id="namePreviousProgram"
                    name='namePreviousProgram'
                    onChange={formik.handleChange}
                    value={formik.values.namePreviousProgram}
                    />
                {errorsValidation.namePreviousProgram ? <div className='text-red-500'>{errorsValidation.namePreviousProgram}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Do you supervise anyone (directly or indirectly) who is also enrolling in the same cohort as you?*</label>
                <div>
                <label className="radio-item relative">
                    <input
                        id="yes"
                        value="yes"
                        className='opacity-0'
                        checked={formik.values.supervise === 'yes'}
                        name="supervise"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.supervise === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                        id="no"
                        value='no'
                        className='opacity-0'
                        checked={formik.values.supervise === 'no'}
                        name="supervise"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.supervise === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.supervise ? <div className='text-red-500'>{errorsValidation.supervise}</div> : null}
                </div>
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Do you report to anyone (directly or indirectly) who is also enrolling in the same cohort as you?*</label>
                <div>
                <label className="radio-item relative">
                    <input
                        id="yes"
                        value="yes"
                        className=''
                        checked={formik.values.report === 'yes'}
                        name="report"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.report === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                        id="no"
                        value='no'
                        className=''
                        checked={formik.values.report === 'no'}
                        name="report"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.report === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                </div>
                {errorsValidation.report ? <div className='text-red-500'>{errorsValidation.report}</div> : null}
            </div>
        </div>

        <p className=" text-center lg:text-left font-bold pb-5">Terms and Conditions</p>
        <div className="grid grid-cols-1 max-w-500 lg:max-w-none m-auto lg:grid-cols-3 pt-3 gap-y-7 gap-x-5 border-newBranding-beigeText pb-10 w-full border-t pt-10">

            <div className="col-span-2 flex flex-col justify-between">
                <label>Please indicate that you have read and agree to Coach Training EDU's Terms and Conditions. Found at <a className="underline" href="https://campus.coachtrainingedu.com/wp-content/uploads/2021/12/Term-and-conditions.pdf" target='_blank'>https://campus.coachtrainingedu.com/wp-content/uploads/2021/12/Term-and-conditions.pdf.</a> Check 'yes' if you accept these terms.</label>
                <div>
                <label className="radio-item relative">
                    <input
                        id="yes"
                        value="yes"
                        className='opacity-0'
                        checked={formik.values.termsConditions === 'yes'}
                        name="termsConditions"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.termsConditions === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                        id="no"
                        value='no'
                        className='opacity-0'
                        checked={formik.values.termsConditions === 'no'}
                        name="termsConditions"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                    />
                    {formik.values.termsConditions === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.termsConditions ? <div className='text-red-500'>{errorsValidation.termsConditions}</div> : null}
                </div>
            </div>

        </div>
        <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default UniversityApplication;