import React,  {  useState, useEffect } from "react";
import CheckoutForm from "./CheckoutForm";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import { useNavigate } from "react-router-dom";


var data = {
  prices : {
     associatePath : [
       '12 Monthly Payments - $300',
       'Pay in Full - $3240'
     ],
     professionalPath :[
       '12 Monthly Payments - $500',
       'Pay in Full - $5400'
     ],
     advancedPath :[
      '12 Monthly Payments - $650',
      'Pay in Full - $7020'
    ],
    masterPath :[
      '12 Monthly Payments - $1050',
      'Pay in Full - $11340'
    ]
  }
}

function Payment(props) {
    const [clientSecret, setClientSecret] = useState("");
    const stripePromise = loadStripe('pk_live_DEPpyiHqAI5v3Dvc3cofhaqK');
    const [dateLoc, setDateLoc] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [path, setPath] = useState("");
    const navigate = useNavigate();
    

    var payment =[]

    useEffect(() => {
      props.getValue('dateLoc').then((result) => {
        setDateLoc(result)  
      })
      props.getValue('path').then((result) => {
        setPath(result)
          if(result === 'associate'){
            payment = data.prices.associatePath 
          }
          else if(result === 'advanced'){
            payment = data.prices.advancedPath
          }
          else if(result === 'master'){
            payment = data.prices.masterPath
          }
      })
      
      const lastNamePromise = props.getValue('lastName')
      const firstNamePromise = props.getValue('firstName')

     
      Promise.all([firstNamePromise, lastNamePromise]).then((values) => {
        const url =
        "https://us-central1-ctedu-lms.cloudfunctions.net/application/create-payment-intent/"
        const data = { 
          items: 'application',
          name: values[0] + " "+ values[1],
          email: props.user.email
        }
        axios.post(url,  data, {
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
          }}).then((res) => {
            return res.data
            //setClientSecret(res.clientSecret)
          })
          .then((data) => {
            setClientSecret(data)
          })
          .catch((e) => console.log('error', e))

      });

      

      // Create PaymentIntent as soon as the page loads
     /* fetch("/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          items: 'application',
         name: props.user.displayName,
         email: props.user.email
        }),
      })*/
        
     
        
    }, [1]);
  
    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };
  if(clientSecret === ''){
  return (
    <div class=" flex justify-center items-center h-screen">
      <div class=" animate-spin rounded-full h-10 w-10 border-b-4 border-l-4 border-t-4 border-newBranding-blue"></div>
    </div>
  )
  }

  if(path === 'associate'){
    payment = data.prices.associatePath 
  }else if(path === 'professional'){
    payment = data.prices.professionalPath
  }else if(path === 'advanced'){
    payment = data.prices.advancedPath
  }else if(path === 'master'){
    payment = data.prices.masterPath
  }
  
  if(props.user === null){
    navigate('/')
    return null
  }
  return (
    <div className="mr-2">
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm handleBack={props.handleBack} email={props.user.email} paymentMethod={paymentMethod} payment={payment} date={dateLoc}/>
      </Elements>
  </div>
  );
}

export default Payment;