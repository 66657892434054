import './App.css';
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import Login from "./Login.js"
import ApplicationForm from "./ApplicationForm";
import Register from "./Register";
import Reset from "./Reset";
import Success from "./Success"
import AdvancedCourse2 from './FormsPage/AdvanceCourse2';
import CertificationApplication from './FormsPage/Certification';
import LetterOfCompletion from './FormsPage/LetterOfCompletion';
import FeedbackProgrammaticTrainers from './FormsPage/FeedbackProgrammaticTrainers';
import ScholarshipApplication from './FormsPage/Scholarship';
import UniversityApplication from './FormsPage/University';
import EarlyAlert from './FormsPage/EarlyAlert';
import CoachingPod from './FormsPage/CoachingPods';
import ThankYou from './thank-you';
import SuccessAdvanced from './SuccessAdvanced';
import HelpDesk from './helpdesk';
import {Helmet} from "react-helmet";
import HelpTicket from './FormsPage/HelpTicket';
import ThankYouHelpTicket from './thank-you-help-ticket';
import GraduateStoryForm from './FormsPage/GraduateStoryForm';
import GuestForm from './FormsPage/GuestForm';
import AdminDashboard from './AdminDashboard'
import CoachTheoryFeedback from './FormsPage/CoachTheoryFeedback';
import ThankYouCertification from './thank-you-certification';



function App() {
  return (
    <div className="app w-full">
        <Helmet>
        <title>Application</title>
        <meta name="description" content="Application App" />
      </Helmet>
      <Router>
        <Routes >
          <Route exact path="/" element={<Login/>} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/reset" element={<Reset/>} />
          <Route exact path="/application-form" element={<ApplicationForm/>} />
          <Route exact path="/success" element={<Success/>} />
          <Route exact path="/advanced-course-enrollment" element={<AdvancedCourse2/>} />
          <Route exact path="/success-advanced-course" element={<SuccessAdvanced/>} />
          <Route exact path="/certification-application" element={<CertificationApplication/>} />
          <Route exact path="/letter-of-completion" element={<LetterOfCompletion/>} />
          <Route exact path="/feedback-programmatic-trainers" element={<FeedbackProgrammaticTrainers/>} />
          <Route exact path="/scholarship-application" element={<ScholarshipApplication/>} />
          <Route exact path="/university-application" element={<UniversityApplication/>} />
          <Route exact path="/early-alert" element={<EarlyAlert/>} />
          <Route exact path="/coaching-pod" element={<CoachingPod/>} />
          <Route exact path="/thank-you" element={<ThankYou/>} />
          <Route exact path="/help-desk" element={<HelpDesk/>} />
          <Route exact path="/help-ticket" element={<HelpTicket/>} />
          <Route exact path='/thank-you-help-ticket' element={<ThankYouHelpTicket/>}/>
          <Route exact path="/graduate-story-submission" element={<GraduateStoryForm/>} />
          <Route exact path='/guest-submission' element={<GuestForm/>}/>
          <Route exact path='/guest-submission' element={<GuestForm/>}/>
          <Route exact path='/admin-dashboard' element={<AdminDashboard/>}/>
          <Route exact path='/coach-theory-feedback' element={<CoachTheoryFeedback/>}/>
          <Route exact path='thank-you-certification' element={<ThankYouCertification/>}/>
        </Routes >
      </Router>
</div>
  );
}

export default App;
