
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image from "../images/form-scholarship.png"




//import  response  from 'express';

 
 
function ScholarshipApplication() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [course, setCourse] = useState("");
    const [gmc, setGmc] = useState("");
    const [story, setStory] = useState("");
    const [proofIncome, setProofIncome] = useState("");
    const [income, setIncome] = useState("");
    const [notes, setNotes] = useState("");
    
   const [errorsValidation, setErrorsValidation] = useState({})
 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'course', 'gmc', 'story', 'proofIncome']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setState(values.state)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setCourse(values.course)
      setGmc(values.gmc)
      setStory(values.story)
      setProofIncome(values.proofIncome)
      setIncome(values.income)
      setNotes(values.notes) 

  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'course', 'gmc', 'story', 'proofIncome']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      course:course,
      gmc:gmc,
      story:story,
      proofIncome:proofIncome,
      income:income,
      notes:notes
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'scholarship-application'}

      axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/thank-you')
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
  })

  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Scholarship Application</title>
            <meta name="description" content="Scholarship Application" />
            <meta name="title" content="Scholarship Application" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1  className="text-4xl sm:text-5xl mb-5">CTEDU Scholarship</h1>
              <p className="font-bold text-xl">Coach Training EDU offers scholarships to a select number of participants.</p>
              <p>Our ability to meet your needs is based on the amount you are able to contribute to a 12 month payment plan and the funds we have available for the particular course you are interested in joining.</p>
              <p>If you are interested in receiving financial assistance to participate in a program, please complete the application below to get started.</p>
            </div>
            <div className=" m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='scholarship-application' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col  w-full ">
        <div className="grid-cols-2 max-w-500 m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 border-b border-newBranding-beigeText pb-10">

            <label className="col-span-2"> First Name
            <input
                type="text"
                className="rounded-md w-full  h-10 p-2"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
                {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
            </label>

            <label className="col-span-2"> Last Name
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
                {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
            </label>

            <label className="col-span-2"> Email
            <input
                type="email"
                className="rounded-md w-full h-10 p-2"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
                {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
            </label>

            <label className="col-span-2"> Phone
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                />
                {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
            </label>
        
            <label className=" w-full col-span-2 "> Address
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="street"
                onChange={formik.handleChange}
                value={formik.values.street}
                />
                {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
            </label>
    
            <label className="col-span-2"> City
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                />
                {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
            </label>

            <label className="col-span-1"> State
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="state"
                onChange={formik.handleChange}
                value={formik.values.state}
                />
                {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
            </label>
    
            <label className=" w-full col-span-1"> ZIP code
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="zip"
                name='zip'
                onChange={formik.handleChange}
                value={formik.values.zip}
                />
                {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
            </label>

            <label className="w-full col-span-2"> Country
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                />
                {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
            </label>

            <div className="col-span-2 flex flex-col justify-between">
                <label>1.0 Course Selection:* </label>
                    <select
                        id="course"
                        name="course"
                        className='h-10 rounded-md w-full '
                        value={formik.values.course}
                        onChange={(e) => {
                            formik.handleChange(e);
                        }}>
                            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
                            <option>1.0 Wellness Life Coach Training</option>
                            <option>1.0 Academic Life Coach Training</option>
                            <option>1.0 Executive Life Coach Training</option>
                            <option>1.0 Essential Life Coach Training</option>
                </select>
                {errorsValidation.course ? <div className='text-red-500'>{errorsValidation.course}</div> : null}
            </div>

            <div className="col-span-2 lg:col-span-4 flex flex-col justify-between">
                <label>Group Mentor Coaching*</label>
                <div className='mt-5'>
                <label className="radio-item relative">
                    <input
                    id="addGmc"
                    value="addGmc"
                    className='opacity-0'
                    checked={formik.values.gmc === 'addGmc'}
                    name="gmc"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.gmc === 'addGmc' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="addGmc" className="ml-5 text-base">Add Group Mentor Coaching</label>
                </label>

                <label className="radio-item relative ml-5">
                    <input
                    id="noGmc"
                    value='noGmc'
                    className='opacity-0'
                    checked={formik.values.gmc === 'noGmc'}
                    name="gmc"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.gmc === 'noGmc' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="noGmc" className="ml-5 text-base">No Group Mentor Coaching</label>
                </label>

                <label className="radio-item relative mt-5 sm:mt-0 sm:ml-5 block sm:inline">
                    <input
                    id="notSure"
                    value='notSure'
                    className='opacity-0'
                    checked={formik.values.gmc === 'notSure'}
                    name="gmc"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.gmc === 'notSure' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="notSure" className="ml-5 text-base">Not Sure</label>
                </label>
                </div>
                {errorsValidation.gmc  ? <div className='text-red-500'>{errorsValidation.gmc}</div> : null}
            </div>

            <div className="col-span-2 flex flex-col justify-between">
                <label>Annual Income (in $)</label>
                <input
                    type="text"
                    className="rounded-md w-full h-10 p-2"
                    id="income"
                    name='income'
                    onChange={formik.handleChange}
                    value={formik.values.income}
                    />
            </div>
        </div>

        <div className="grid grid-cols-1 max-w-500 lg:max-w-none m-auto lg:grid-cols-3 pt-5 gap-y-10 gap-x-5">

          <div className="col-span-1 flex flex-col justify-between">
            <label>Your story matters! Please explain why you would you need a scholarship.*<br/><span className="text-sm">(250 words or less)</span></label>
            <textarea 
              type='text'
              className="h-10 rounded-md w-full h-32 p-2"
              value={formik.values.story}
              onChange={formik.handleChange}
              name='story'
              id='story'
            />
            {errorsValidation.story ? <div className='text-red-500'>{errorsValidation.story}</div> : null}
          </div>

          <div className="col-span-1 flex flex-col ">
            <label>Are you willing to provide proof income?*</label>
            <div className="lg:mt-12">
                <label className="radio-item relative">
                    <input
                    id="yes"
                    value="yes"
                    className='opacity-0'
                    checked={formik.values.proofIncome === 'yes'}
                    name="proofIncome"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.proofIncome  === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                    id="no"
                    value='no'
                    className='opacity-0'
                    checked={formik.values.proofIncome  === 'no'}
                    name="proofIncome"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.proofIncome  === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.proofIncome  ? <div className='text-red-500'>{errorsValidation.proofIncome}</div> : null}
            </div>
          </div>
                   
          <div className="col-span-1 flex flex-col justify-between">
            <label>Any other notes for our team?</label>
            <textarea
                type="text"
                className="rounded-md w-full h-32 p-2"
                id="notes"
                name='notes'
                onChange={formik.handleChange}
                value={formik.values.notes}
                />
          </div>
          
        </div>
        <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default ScholarshipApplication;