
import React, { useEffect, useState } from "react";

import {Helmet} from "react-helmet";
import HeaderLogin from "./Components/HeaderLogin";
import Image from "./images/thank-you-ticket.png"
 
function ThankYouHelpTicket() {
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12 min-h-screen bg-newBranding-beige">
      <Helmet>
            <title>Thank You!</title>
            <meta name="description" content="Thank you" />
            <meta name="title" content="Thank You for submitting a form" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10 ">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-10 order-2 lg:order-1">
              <h1 className="text-4xl sm:text-5xl mb-5 pt-10">We’re on the case!</h1>
              <p className="font-bold text-xl">Thank you for submitting your support request.</p>
              <p className="mb-10">Our support team will be in touch soon.  We try to respond to all requests within one business day.  An email verification of receipt of your request will follow soon.  If you’d like, reply to that email to add any screenshots, links, files or additional information that might help us understand your issue.</p>
            </div>
            <div className="lg:w-4/5 m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
    </div>
  );
}
export default ThankYouHelpTicket;