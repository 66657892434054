import React, { useEffect, useState } from "react";

import { useFormik } from "formik"

import {useRef} from 'react';
import Community1 from '../images/community-1.png'
import Community2 from '../images/community-2.png'
import Community3 from '../images/community-3.png'


function CreateCommunity(props) {

  let btnRef = useRef();
 
  const [socialMedia, setSocialMedia] = useState("");
  const [mediaRelease, setMediaRelease] = useState(true);
  const [initialSocialMedia, setInitialSocialMedia] = useState("")
  const [initialMediaRelease, setInitialMediaRelease] = useState(true);
 

  useEffect(() => {
    props.getValue('socialMedia').then((result) => {
      setSocialMedia(result)
      setInitialSocialMedia(result)
    })
    props.getValue('mediaRelease').then((result) => {
      setMediaRelease(result)
      setInitialMediaRelease(result)
    })
  })

  const validate = (values) => {
    const errors = {};
    const required = []
  
    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });

  
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      socialMedia: socialMedia,
      mediaRelease: mediaRelease,
      step:5
    },
    enableReinitialize: true,
   

    validate,
    onSubmit: values => {
      btnRef.current.setAttribute("disabled", "disabled");
     let data = values
     let dataChanged = false
     if((data.mediaRelease !== initialMediaRelease) || (data.socialMedia !== initialSocialMedia)) dataChanged = true
     props.onSubmit(data , dataChanged)
      
    },
  })

  
  return (
    <div >
      <div className="">
      <h2 className='mb-5 text-center md:text-left font-bold'>Community & Media</h2>
      <p className='mb-10 text-sm'>We are grateful for a global community of dedicated and talented coaches who share our mission. If you want to share your media accounts here, we'll connect you with our larger online global community.</p>
      <form onSubmit={formik.handleSubmit} className="mb-10 flex flex-col justify-center">
      <div>
      <label className='mb-5 flex flex-col lg:flex-row relative text-newBranding-darkGrey'> 
         <div className="flex flex-row">
          <span className='opacity-75 whitespace-nowrap lg:my-auto -mt-1'>Social Media Handle(s):</span>
          <div className="flex flex-row ">
            <i className="fab fa-facebook-square text-newBranding-blue lg:my-auto ml-2 mr-2 fa-lg"></i>
            <i className="fab fa-instagram-square text-newBranding-blue lg:my-auto  mr-2 fa-lg"></i>
            <i className="fab fa-twitter-square text-newBranding-blue lg:my-auto mr-2 fa-lg"></i>
            <i className="fab fa-youtube-square text-newBranding-blue lg:my-auto fa-lg mr-2"></i>
         </div>
         </div>
        <textarea
            type="text"
            className="  border border-newBranding-blue border-opacity-50 w-full rounded-md h-9 h-16 pl-2"
            id="socialMedia"
            onChange={formik.handleChange}
            value={formik.values.socialMedia}
            />
        </label>
        </div>
             <div className="h-32 bg-white overflow-y-scroll mt-5 border border-newBranding-blue border-opacity-50 rounded-md p-2 mt-2 text-sm">
             Liability Release: I understand that the released items may be disclosed to students or trainees<br/>
                <br/>
                Release is Voluntary: I understand this permission is voluntary. My decision will not affect my training and will not affect my eligibility in any future endeavors with CTEDU.<br/>
                <br/>
                <br/>
                Photographic, audio or video recordings may be used with your permission for the following purposes:<br/>
                <br/>
                Education and training purposes for course members and trainers<br/>
                Educational presentations, videos or courses on its website, blog, social media, and other public venues to promote their Coach Training Courses.<br/>
                <br/>
                I hereby grant permission to the rights of my image, likeness and sound of my voice as recorded on audio or video to be used in the mediums for internal usage (training demos, educational purposes). I understand that my image may be edited, copied, exhibited, published or distributed and waive the right to inspect or approve the finished product wherein my likeness appears. I also understand that this material may be used in diverse settings within an unrestricted geographic area.
              </div>
           <label className='block relative mb-5' htmlFor="mediaRelease"> 
             <input className="w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0" type="checkbox" onChange={formik.handleChange} id="mediaRelease" name="mediaRelease" value={formik.values.mediaRelease} checked={formik.values.mediaRelease}/>
             {formik.values.mediaRelease ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
             <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>I agree to the media release.</span> 
             <p className="text-sm">We take student privacy and confidentiality seriously, and your media permission is not necessary for you to enroll in the course.</p>
             </label>
        <div className='flex flex-row justify-between'>
        <button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
          <button ref={btnRef} type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
        </div>
      </form>
      </div>
    </div>
  );
}

export default CreateCommunity;