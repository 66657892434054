
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb, getValueFromDbUsingUid } from "../firebase";
import Header from "../Components/Header";
import FormContainer from '../Components/FormContainer'
import { useFormik } from "formik"
import SidebarAdvanced from "../Components/SidebarAdvanced";
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import { weekdaysShort } from "moment-timezone";
const moment = require('moment-timezone');
const contentful = require("contentful");

var dateFromContentful =[];
var dateFromContentful2=[];


var contentfulClient = contentful.createClient({
  accessToken: 'UlbWB8DEhyN4UDFI2W8FO3iNHUc-G3lVoGiPPn0siGw',
  space: 'ylv2wajz235x'
})

var PRODUCT_CONTENT_TYPE_ID = 'columnDateSchedule'
var PRODUCT_CONTENT_TYPE_ID_2 = 'blockDateSchedule'




 
 
function AdvancedCourse2() {
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [AdvancedCoachTraining, setAdvancedCoachTraining] = useState(false)
    const [advancedDate, setAdvancedDate] = useState("")
    const [GroupMentorCoaching, setGroupMentorCoaching] = useState(false)
    const [gmcDate, setGmcDate]= useState("")
    const [HealthCoaching, setHealthCoaching] = useState(false)
    const [healthDate, setHealthDate]= useState("")
    const [RelationshipCoaching, setRelationshipCoaching]= useState(false)
    const [relationshipDate, setRelationshipDate]= useState("")
    const [InclusionCoaching, setInclusionCoaching]= useState(false)
    const [inclusionDate, setInclusionDate]= useState("")
    const [TeamCoaching, setTeamCoaching]= useState(false)
    const [teamDate, setTeamDate]= useState("")
    const [leadershipDate, setLeadershipDate] = useState('')
    const [psyDate, setPsyDate] = useState('')
    const [errorsValidation, setErrorsValidation] = useState({})
    const [ExecutiveSelfStudy, setExecutiveSelfStudy] = useState(false)
    const [WellnessSelfStudy, setWellnessSelfStudy] = useState(false)
    const [leadership, setLeadership] = useState(false)
    const [psy, setPsy] = useState(false)

    const [datesGmcClass, setDatesGmcClass] = useState([])
    const [datesAdvancedClass, setDatesAdvancedClass] = useState([])
    const [dateInclusion, setDateInclusion] =  useState([])
    const [dateTeam, setDateTeam] = useState([])
    const [dateRelationship, setDateRelationship] = useState([])
    const [dateHealthBoard, setDateHealthBoard] = useState([])
    const [dateLeadership, setDateLeadership] = useState([])
    const [datePsy, setDatePsy] = useState([])


  function getDate(startDate, endDate){
      startDate = moment.tz(startDate, "America/Los_Angeles")
      endDate = moment.tz(endDate, "America/Los_Angeles")
      var localStart = startDate.clone().local()
      var localEnd = endDate.clone().local()
      var abvTimezone = moment.tz(moment.tz.guess()).format('z')
      let dateToDisplay = localStart.format("dddd, MMM Do h:mm a") + " - " + localEnd.format("h:mm a") + " " + abvTimezone
      return {dateToDisplay, localStart, localEnd}
  }

  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country']
    if(AdvancedCoachTraining){
      required.push('advancedDate')
    }
    if(GroupMentorCoaching){
      required.push('gmcDate')
    }
    if(HealthCoaching){
      required.push('healthDate')
    }
    if(leadership){
      required.push('leadership')
    }
    if(psy){
      required.push('psy')
    }
    if(RelationshipCoaching){
      required.push('relationshipDate')
    }
    if(InclusionCoaching){
      required.push('inclusionDate')
    }
    if(TeamCoaching){
      required.push('teamDate')
    }
    

    if(!AdvancedCoachTraining && !GroupMentorCoaching && !HealthCoaching && !RelationshipCoaching && !InclusionCoaching && !TeamCoaching && !InclusionCoaching && !ExecutiveSelfStudy && !WellnessSelfStudy && !leadership && !psy){
      required.push('NoClasses')
    }


    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setState(values.state)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setAdvancedCoachTraining(values.AdvancedCoachTraining)
      setExecutiveSelfStudy(values.ExecutiveSelfStudy)
      setWellnessSelfStudy(values.WellnessSelfStudy)
      setAdvancedDate(values.advancedDate)
      setGroupMentorCoaching(values.GroupMentorCoaching)
      setGmcDate(values.gmcDate)
      setHealthCoaching(values.HealthCoaching)
      setHealthDate(values.healthDate)
      setRelationshipCoaching(values.RelationshipCoaching)
      setRelationshipDate(values.relationshipDate)
      setInclusionCoaching(values.InclusionCoaching)
      setInclusionDate(values.inclusionDate)
      setTeamCoaching(values.TeamCoaching)
      setTeamDate(values.teamDate)
      setPsy(values.psy)
      setPsyDate(values.psyDate)
      setLeadership(values.leadership)
      setLeadershipDate(values.leadershipDate)
 
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country']
    if(AdvancedCoachTraining){
      required.push('advancedDate')
    }
    if(GroupMentorCoaching){
      required.push('gmcDate')
    }
    if(HealthCoaching){
      required.push('healthDate')
    }
    if(RelationshipCoaching){
      required.push('relationshipDate')
    }
    if(InclusionCoaching){
      required.push('inclusionDate')
    }
    if(TeamCoaching){
      required.push('teamDate')
    }
    if(leadership){
      required.push('leadership')
    }
    if(psy){
      required.push('psy')
    }
    if(!AdvancedCoachTraining && !GroupMentorCoaching && !HealthCoaching && !RelationshipCoaching && !InclusionCoaching && !TeamCoaching && ! InclusionCoaching && !ExecutiveSelfStudy && !WellnessSelfStudy && !leadership && !psy){
      required.push('NoClasses')
    }


    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      AdvancedCoachTraining : AdvancedCoachTraining,
      advancedDate : advancedDate,
      ExecutiveSelfStudy:ExecutiveSelfStudy,
      WellnessSelfStudy:WellnessSelfStudy,
      GroupMentorCoaching:GroupMentorCoaching,
      gmcDate:gmcDate,
      HealthCoaching:HealthCoaching,
      healthDate:healthDate,
      RelationshipCoaching:RelationshipCoaching,
      relationshipDate:relationshipDate,
      InclusionCoaching:InclusionCoaching,
      inclusionDate:inclusionDate,
      TeamCoaching:TeamCoaching,
      teamDate:teamDate,
      psy:psy,
      psyDate:psyDate,
      leadership:leadership,
      leadershipDate:leadershipDate
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
         if(!data.AdvancedCoachTraining){
           data.advancedDate=''
         }
         if(!data.GroupMentorCoaching){
           data.gmcDate=''
         }
         if(!data.HealthCoaching){
          data.healthDate=''
        }
        if(!data.RelationshipCoaching){
          data.relationshipDate=''
        }
        if(!data.InclusionCoaching){
          data.inclusionDate=''
        }
        if(!data.TeamCoaching){
          data.teamDate=''
        }
        if(!data.leadership){
          data.leadershipDate=''
        }
        if(!data.psy){
          data.psyDate=''
        }
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'advancedClassSubmitted'}
      console.log('data ', data)
      axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/success-advanced-course')
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })

 


  useEffect(() => 
  contentfulClient.getEntries({
    content_type: PRODUCT_CONTENT_TYPE_ID,
  })
  .then(function(entries) {
    entries.items.map((entry, i) => {
      if(entry.fields.title === "Dates Main Courses"){
        entry.fields.date.map((date, i ) =>{
          const curr = []
          if(date.fields.dateString === 'GMC'){
              date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              })              
          }
          curr.sort(function(a,b){
            return b.startDate - a.startDate;
          });
          setDatesGmcClass(curr)
        })
      }
    })
  })
  ,[])

  useEffect(() => 
  contentfulClient.getEntries({
    content_type: PRODUCT_CONTENT_TYPE_ID,
    })
    .then(function(entries) {
      entries.items.map((entry, i) => {
        if(entry.fields.title === "Advanced Classes"){
          entry.fields.date && entry.fields.date.map((date, i ) =>{
            console.log('date ', date)
          
            //Get 2.0 Dates
            if(date.fields && date.fields.dateString === '2.0 Essential'){
                const curr = []
                
                date.fields.dates && date.fields.dates.map((d, i) => {
                  contentfulClient.getEntry(d.sys.id)
                  .then(function(entry) {
                    curr.push(entry.fields)
                  })
                }) 
                curr.sort(function(a,b){
                  return b.startDate - a.startDate;
                });        
                setDatesAdvancedClass(curr)   
              
            }  
            //Get Health Board Date
            if(date.fields && date.fields.dateString === 'Health Board'){
              const curr = []
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });        
              setDateHealthBoard(curr)     
            }  
            //Get Team Date
            if(date.fields && date.fields.dateString === 'Team'){
              const curr = []
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });        
              setDateTeam(curr)     
            }  
             //Get Relationship Date
             if(date.fields && date.fields.dateString === 'Relationship'){
              const curr = []
              console.log('rel ', date.fields.dates)
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });   
              console.log('curr ', curr)     
              setDateRelationship(curr)     
            }  
             //Get Inclusion Date
             if(date.fields && date.fields.dateString === 'Inclusion'){
              const curr = []
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });        
              setDateInclusion(curr)     
            }  
            //Get Leadership Date
            if(date.fields && date.fields.dateString === 'Leadership'){
              const curr = []
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });        
              setDateLeadership(curr)     
            }  
             //Get Leadership Date
             if(date.fields && date.fields.dateString === 'Positive Psychology'){
              const curr = []
              date.fields.dates && date.fields.dates.map((d, i) => {
                contentfulClient.getEntry(d.sys.id)
                .then(function(entry) {
                  curr.push(entry.fields)
                })
              }) 
              curr.sort(function(a,b){
                return b.startDate - a.startDate;
              });        
              setDatePsy(curr)     
            }  
          })
        }        
      })
    })
  ,[])

  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>CTEDU Advanced Class Form</title>
            <meta name="description" content="Select your advanced classes" />
            <meta name="title" content="CTEDU Advanced Class Form" />
        </Helmet>
      
      <HeaderLogin/>
      <div className=" max-w-1220 mx-auto flex flex-row md:flex-col w-full">
        
        <SidebarAdvanced activeStep={0} /> 
        <div className='w-full sm:w-auto ml-5 md:ml-14 mr-5 pt-44 md:pt-10 pb-10 lg:mr-10 md:pl-5 lg:pl-10 md:ml-80 h-full bg-newBranding-beige  min-h-screen'>
        <div className="w-full ">
        <h2  className="branding-h2 text-center md:text-left font-bold mb-10 text-lg font-serif">Advanced Course Enrollment</h2>
        <form id='Advanced Course Enrollment' onSubmit={formik.handleSubmit} className=" mb-10 flex flex-col mt-5 w-full ">
        <div className='flex flex-col lg:flex-row space-between'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> First Name
          <input
              type="text"
              className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
              id="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              />
              {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>
          <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Last Name
          <input
              type="text"
              className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
              id="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              />
              {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
          </label>
        </div>
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Email
        <input
            type="email"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            //readOnly="readOnly"
            />
            {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Phone
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            />
            {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
        </label>
        </div>
        <p className=' mt-5 mb-5 text-center md:text-left font-bold'>Please confirm your shipping address: *</p>
        <label className="mb-5 w-full text-newBranding-darkGrey opacity-75"> Street Address
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="street"
            onChange={formik.handleChange}
            value={formik.values.street}
            />
            {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
        </label>
 
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> City
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            />
            {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> State
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            />
            {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
        </label>
        </div>
 
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> ZIP code
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="zip"
            name='zip'
            onChange={formik.handleChange}
            value={formik.values.zip}
            />
            {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Country
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            />
            {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
        </label>
        </div>

        <h2  className=" mt-5 mb-5 text-center md:text-left font-bold">I would like to enroll in:*</h2>


        <label className='block relative ' htmlFor="AdvancedCoachTraining"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="AdvancedCoachTraining" 
            name="AdvancedCoachTraining" 
            value={formik.values.AdvancedCoachTraining} 
            checked={formik.values.AdvancedCoachTraining}/> 
           {formik.values.AdvancedCoachTraining ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'> 2.0 Advanced Coach Training</span> 
        </label>

        {AdvancedCoachTraining &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming 2.0 Advanced Coach Training Courses</h3>
          <p className="text-sm mb-5">Please select one. If you are enrolled in the Professional Package, this class is included in your current tuition. If you have not enrolled in the Professional Package, a member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="advancedDate"
          name="advancedDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.advancedDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {datesAdvancedClass.length === 0 ? 
              <option disabled>TBD</option>
            :
            datesAdvancedClass.map((date, i) =>
                  date.dateFull ? 
                  <option disabled key={i}>{getDate(date.startDate, date.endDate).dateToDisplay} - FULL</option>:
                  <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            }
        </select>
        {errorsValidation.advancedDate ? <div className='text-red-500'>{errorsValidation.advancedDate}</div> : null}
      </div>
        </div>
        }



       <label className='block relative' htmlFor="GroupMentorCoaching"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="GroupMentorCoaching" 
            name="GroupMentorCoaching" 
            value={formik.values.GroupMentorCoaching} 
            checked={formik.values.GroupMentorCoaching}/> 
           {formik.values.GroupMentorCoaching ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Group Mentor Coaching</span> 
        </label>

        {GroupMentorCoaching &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Group Mentor Coaching Courses</h3>
          <p className="text-sm mb-5">Please select one. The dates below indicate the start date of this 8-week course. If you are enrolled in an Associate or Professional Package, Group Mentor Coaching is included in your current tuition. Mentor Coaching is required for certification. If you have not enrolled in the Associate or Professional Program, a member of our team will connect with you after your submission to discuss next steps.<br/>Pre-requisite includes completion of the first 15 weeks of any 1.0 Coaching Course prior to applying for Group Mentor Coaching class.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="gmcDate"
          name="gmcDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.gmcDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {datesGmcClass === 0 ? 
               <option disabled>TBD</option>
            :
            datesGmcClass.map((date, i) =>
              date.dateFull ? 
              <option disabled key={i}>{getDate(date.startDate, date.endDate).dateToDisplay} - FULL</option>:
               <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
            )
            }
            
        </select>
        {errorsValidation.gmcDate ? <div className='text-red-500'>{errorsValidation.gmcDate}</div> : null}
      </div>
        </div>
        }


        <label className='block relative' htmlFor="HealthCoaching"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="HealthCoaching" 
            name="HealthCoaching" 
            value={formik.values.HealthCoaching} 
            checked={formik.values.HealthCoaching}/> 
           {formik.values.HealthCoaching ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Health Board</span> 
        </label>

        {HealthCoaching &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Health and Wellness Training Courses</h3>
          <p className="text-sm mb-5">Please select one. This course offers certification and sets a coach up on a path for the NBWHC. The cost of the class is $2,700 and a member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="healthDate"
          name="healthDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.healthDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {dateHealthBoard.length === 0 ? 
            <option disabled>TBD</option>
            :
            dateHealthBoard.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
           
        </select>
        {errorsValidation.healthDate ? <div className='text-red-500'>{errorsValidation.healthDate}</div> : null}
      </div>
        </div>
        }

        <label className='block relative' htmlFor="TeamCoaching"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="TeamCoaching" 
            name="TeamCoaching" 
            value={formik.values.TeamCoaching} 
            checked={formik.values.TeamCoaching}/> 
           {formik.values.TeamCoaching ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Team Coaching</span> 
        </label>

        {TeamCoaching &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Team Courses</h3>
          <p className="text-sm mb-5">Please select one. The cost of the class as a standalone selection is $1,800. If you selected our Advanced Package, you can add this course to your package. A member of our team will connect with you after your submission to discuss next steps.</p>

      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="teamDate"
          name="teamDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.teamDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {dateTeam.length === 0 ? 
            <option disabled>TBD</option>
            :
            dateTeam.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
        </select>
        {errorsValidation.teamDate ? <div className='text-red-500'>{errorsValidation.teamDate}</div> : null}
      </div>
        </div>
        }

      <label className='block relative' htmlFor="RelationshipCoaching"> 
            <input type="checkbox" 
            className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="RelationshipCoaching" 
            name="RelationshipCoaching" 
            value={formik.values.RelationshipCoaching} 
            checked={formik.values.RelationshipCoaching}/> 
           {formik.values.RelationshipCoaching ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Relationship Coaching</span> 
        </label>

        {RelationshipCoaching &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Relationship Training Courses</h3>
          <p className="text-sm mb-5">Please select one. The cost of the class as a standalone selection is $1,800. If you selected our Advanced Package, you can add this course to your package. A member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="relationshipDate"
          name="relationshipDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.relationshipDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {dateRelationship.length === 0 ? 
            <option disabled>TBD</option>
            :
            dateRelationship.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
        </select>
        {errorsValidation.relationshipDate ? <div className='text-red-500'>{errorsValidation.relationshipDate}</div> : null}
      </div>
        </div>
        }



      <label className='block relative' htmlFor="InclusionCoaching"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="InclusionCoaching" 
            name="InclusionCoaching" 
            value={formik.values.InclusionCoaching} 
            checked={formik.values.InclusionCoaching}/> 
           {formik.values.InclusionCoaching ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Inclusion Coaching</span> 
        </label>

        {InclusionCoaching &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Inclusion Coaching Courses</h3>
          <p className="text-sm mb-5">Please select one. The cost of the class as a standalone selection is $1,800. If you selected our Advanced Package, you can add this course to your package. A member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="inclusionDate"
          name="inclusionDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.inclusionDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {dateInclusion.length === 0 ? 
            <option disabled>TBD</option>
            :
            dateInclusion.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
        </select>
        {errorsValidation.inclusionDate ? <div className='text-red-500'>{errorsValidation.inclusionDate}</div> : null}
      </div>
        </div>
        }

      <label className='block relative' htmlFor="leadership"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="leadership" 
            name="leadership" 
            value={formik.values.leadership} 
            checked={formik.values.leadership}/> 
           {formik.values.leadership ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Leadership Coaching</span> 
        </label>

        {leadership &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Leadership Coaching Courses</h3>
          <p className="text-sm mb-5">Please select one. The cost of the class as a standalone selection is $1,800. If you selected our Advanced Package, you can add this course to your package. A member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="leadershipDate"
          name="leadershipDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.leadershipDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {dateLeadership.length === 0 ? 
            <option disabled>TBD</option>
            :
            dateLeadership.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
        </select>
        {errorsValidation.leadershipDate ? <div className='text-red-500'>{errorsValidation.leadershipDate}</div> : null}
      </div>
        </div>
        }



        <label className='block relative' htmlFor="psy"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="psy" 
            name="psy" 
            value={formik.values.psy} 
            checked={formik.values.psy}/> 
           {formik.values.psy ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Positive Psychology Coaching</span> 
        </label>

        {psy &&
        <div>
          <h3 className=" mb-2 text-center md:text-left font-bold text-sm">Upcoming Positive Psychology Coaching Courses</h3>
          <p className="text-sm mb-5">Please select one. The cost of the class as a standalone selection is $1,800. If you selected our Advanced Package, you can add this course to your package. A member of our team will connect with you after your submission to discuss next steps.</p>
         
      <div className='flex max-w-400 flex-col mt-5 mb-10'>
        <select
          id="psyDate"
          name="psyDate"
          className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
          value={formik.values.psyDate}
          onChange={(e) => {
            formik.handleChange(e);
          }}>
            <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
            {datePsy.length === 0 ? 
            <option disabled>TBD</option>
            :
            datePsy.map((date, i) => {
              return(
              <option key={i}>{getDate(date.startDate, date.endDate).dateToDisplay}</option>
              )
            })
          }
        </select>
        {errorsValidation.psyDate ? <div className='text-red-500'>{errorsValidation.psyDate}</div> : null}
      </div>
        </div>
        }
  


          <label className='block relative' htmlFor="WellnessSelfStudy"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="WellnessSelfStudy" 
            name="WellnessSelfStudy" 
            value={formik.values.WellnessSelfStudy} 
            checked={formik.values.WellnessSelfStudy}/> 
           {formik.values.WellnessSelfStudy ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Wellness Self Study </span> 
        </label>

        <label className='block relative' htmlFor="ExecutiveSelfStudy"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="ExecutiveSelfStudy" 
            name="ExecutiveSelfStudy" 
            value={formik.values.ExecutiveSelfStudy} 
            checked={formik.values.ExecutiveSelfStudy}/> 
           {formik.values.ExecutiveSelfStudy ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>Executive Self Study </span> 
        </label>
      


    {errorsValidation.NoClasses ? <div className='text-red-500'>Please select at least one Advance Class.</div> : null}
        

          <button ref={btnRef} type="submit" className=' mt-10 self-start '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Send</span><span className='opacity-75 ml-2'>&#10095;</span></button>
      </form>
      </div>
    </div>
    </div>
    </div>
  );
}
export default AdvancedCourse2;