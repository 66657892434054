
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout, writeUserData, getValueFromDb, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import Header from "./Components/Header";
import CoachDirectoryDashboard from "./Components/CoachDirectoryDashboard"
import GoCoachingDashboard from "./Components/GoCoachingDashboard"
import { useNavigate } from "react-router-dom";

import Google from './images/btn_google_signin_light_normal_web.png'
import GoogleHover from './images/google-hover.png'
import {Helmet} from "react-helmet";




function AdminDashboard() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [tabOpen, setTabOpen] = useState('CoachDirectory')

    const navigate = useNavigate();

    useEffect(() => {
      if (loading) {
        return;
      }
      if (user) setIsLoggedIn(true)
      if(!user) setIsLoggedIn(false)
     

      getValueFromDb(user?.email, 'isAdmin').then(res => {
        console.log('res ', res)
        if(res) setIsAdmin(true)
      })
    }, [user, loading]);


if(isLoggedIn){
  if(isAdmin)
    return(
        <div className="flex flex-col text-newBranding-darkGrey mt-12">

        <Header logout={logout}/>
          <div className="bg-white w-full min-h-screen h-full p-10">
            <div className="w-full grid grid-cols-2 justify-center items-center border-2 max-w-1220 m-auto">
              <div className={`${tabOpen==='CoachDirectory' && `bg-newBranding-blueSelected text-white`} w-full flex items-center cursor-pointer p-2 justify-center`} onClick={() => setTabOpen('CoachDirectory')}>
              <p className='text-xl'>Coach Directory</p>
              </div>
              <div className={`${tabOpen==='GoCoaching' && `bg-newBranding-blueSelected text-white`} w-full flex items-center p-2 justify-center cursor-pointer	`} onClick={() => setTabOpen('GoCoaching')}>
              <p className="text-xl">Coach Theory</p>
              </div>
            </div>
            {tabOpen==='CoachDirectory' ?
              <CoachDirectoryDashboard/> : <GoCoachingDashboard/>
            }
          </div>
        </div>
    )
    else{
      return(
        <div className="bg-white w-full min-h-screen h-full p-10">
          <p>Access Restricted</p>
        </div>
      )
    }
}    
else{ 
  return (
     <div className="flex text-newBranding-darkGrey">
       <Helmet>
            <title>Admin Dashboard</title>
        </Helmet>

        <div className="bg-white w-full min-h-screen h-full p-10">
        <div className="max-w-300 ">
        <button className="m-auto login__google  self-center block m-auto" onClick={signInWithGoogle}>
            <img onMouseOver={e => e.target.src = GoogleHover} onMouseOut={e => e.target.src = Google} classame='border block  self-center m-auto rounded-full border-newBranding-darkGrey w-1/3' src={Google}/>
            </button>
          <input
            type="text"
            className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
          />
          <input
            type="password"
            className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          {errorMessage != '' ? <div className='text-red-500'>{errorMessage}</div> : null}
          </div>
          <button
            className="w-max-content mx-auto duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1"
            onClick={() => signInWithEmailAndPassword(email, password)
              .then(response => {
              if(response != true){
                setErrorMessage(response.message)
              }
            })}
          >
            LOGIN
          </button>
       </div>
    </div>
  );
}
}
export default AdminDashboard;