
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb, getValueFromDbUsingUid } from "../firebase";
import Header from "../Components/Header";
import FormContainer from '../Components/FormContainer'
import { useFormik } from "formik"
import SidebarAdvanced from "../Components/SidebarAdvanced";
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import AdvancedForm from "../Components/AdvancedForm"
import {useRef} from 'react';
import Image from "../images/form-certification.png"
const moment = require('moment-timezone');
const contentful = require("contentful");



//import  response  from 'express';

 
 
function CertificationApplication() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [training_program_begin_date, setTraining_program_begin_date] =  useState("");
    const [trainingCompleted, setTrainingCompleted] =  useState("");
    const [overview, setOverview] =  useState("");
    const [leadTrainer, setLeadTrainer] =  useState("");
    const [groupMentor, setGroupMentor] = useState("");
    const [practiceClientLog, setPracticeClientLog] = useState("");
    const [feedback, setFeedback] =  useState("");
    const [comments, setComments] =  useState("");
    const [absence, setAbsence] = useState("");
    const [headshot, setHeadshot] = useState("");
    const [practiceClientLogFileName, setPracticeClientLogFileName] = useState("");
    const [headshotFileName, setHeadshotFileName] = useState("");
    const [fileTooLargeLog, setFileTooLargeLog]= useState(false);
    const [fileTooLargeheadShot, setFileTooLargeHeadshot]= useState(false);

    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'overview', 'leadTrainer', 'trainingCompleted', 'training_program_begin_date', 'groupMentor', 'practiceClientLog']
   

   

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setState(values.state)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setLeadTrainer(values.leadTrainer)
      setTraining_program_begin_date(values.training_program_begin_date)
      setTrainingCompleted(values.trainingCompleted)
      setOverview(values.overview)
      setGroupMentor(values.groupMentor)
      setFeedback(values.feedback)
      setComments(values.comments)
      setAbsence(values.absence)

     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'overview', 'leadTrainer', 'trainingCompleted', 'training_program_begin_date', 'groupMentor', 'practiceClientLog']



    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      overview: overview,
      training_program_begin_date: training_program_begin_date,
      leadTrainer:leadTrainer,
      trainingCompleted:trainingCompleted,
      groupMentor:groupMentor,
      practiceClientLog:practiceClientLog,
      feedback:feedback,
      comments:comments,
      absence:absence,
      headshot:headshot
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"



       //2 files to appends after making the first one woirking

      let formData = new FormData();
      formData.append("fileClientLog", data.practiceClientLog);
      formData.append("fileHeadshot", data.headshot);
      formData.append("email_address", data.email);
      formData.append("street", data.street);
      formData.append("city", data.city + ', ' + data.state);
      formData.append("country", data.country + ', ' + data.zip);
      formData.append("name", data.firstName + ' ' + data.lastName);
      formData.append("trainingCompleted", data.trainingCompleted);
      formData.append("trainingBegins", data.training_program_begin_date);
      formData.append("lead", data.leadTrainer);
      formData.append("overview", data.overview);
      formData.append("gmc", data.groupMentor);
      formData.append("absence", data.absence);
      formData.append("comments", data.comments);
      formData.append("feedback", data.feedback);
      formData.append("type", "certification");

      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(() =>{
           navigate('/thank-you-certification')
        }).catch(err => {
          console.log('errror ', err)
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })

  const handleFileUpload = (event, fileType) => {
      if(fileType === 'practiceLog'){
        if(event.currentTarget.files[0].size > 5000000){
          setFileTooLargeLog(true)
        }else{
           setPracticeClientLog(event.currentTarget.files[0])
           setPracticeClientLogFileName(event.currentTarget.files[0].name)
           setFileTooLargeLog(false)
        }
      }
      else if(fileType === 'headshot'){
        if(event.currentTarget.files[0].size > 5000000){
          setFileTooLargeHeadshot(true)
        }else{
          setHeadshot(event.currentTarget.files[0])
          setHeadshotFileName(event.currentTarget.files[0].name)
          setFileTooLargeHeadshot(false)
        }
      }
    }


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Application for the Certification</title>
            <meta name="description" content="Request your certification" />
            <meta name="title" content="CTEDU Certification Request" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid  grid-cols-1 max-w-600 justify-content items-center  m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1  className="text-4xl sm:text-5xl mb-5">Congratulations!</h1>
              <p className="font-bold text-xl">Application for certification with CTEDU</p>
              <p>In order to obtain a CTEDU certificate a coach <span className="font-bold">must complete a 1.0 or 2.0 Coach Training Program and a Group Mentor class.</span></p>

              <p>A coach will receive a letter of completion upon graduation from the 1.0 Coach Training Program. If you have not yet completed a Group Mentor class for certification, please submit your request and 3 overview completion dates to support@coachtrainingedu.com.</p>
              <p>To earn your certification for the completion of your 1.0 or 2.0 coach training course, please submit the following form.</p>
              <p>If any makeup sessions for a class have been completed, please make a note in the attendance section below.</p>
            </div>
            <div className="order-1 m-auto lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>

      <div className="w-full bg-newBranding-beige px-10">
        <form id='certification-request' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className="mb-10 text-center lg:text-left">Please fill out your <span className="font-bold">Information*</span></p>
        <div className="grid-cols-2 max-w-500 m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 border-b border-newBranding-beigeText pb-10">
          
          <label className="col-span-2"> First Name
            <input
                type="text"
                className="rounded-md w-full  h-10"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
            {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>

          <label className="col-span-2"> Last Name
            <input
                type="text"
                className="rounded-md w-full h-10"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
            {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
        </label>

        <label className="col-span-2"> Email
          <input
              type="email"
              className="rounded-md w-full h-10"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              />
          {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
        </label>

        <label className="col-span-2"> Phone
          <input
              type="text"
              className="rounded-md w-full h-10"
              id="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              />
          {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
        </label>
       
        <label className=" w-full col-span-2 lg:col-span-4"> Street Address
          <input
              type="text"
              className="rounded-md w-full h-10"
              id="street"
              onChange={formik.handleChange}
              value={formik.values.street}
              />
          {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
        </label>
 
        <label className="col-span-2"> City
          <input
              type="text"
              className="rounded-md w-full h-10"
              id="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              />
          {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
        </label>

        <label className="col-span-1"> State
        <input
            type="text"
            className="rounded-md w-full h-10"
            id="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            />
            {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
        </label>
 
        <label className=" w-full col-span-1"> ZIP code
        <input
            type="text"
            className="rounded-md w-full h-10"
            id="zip"
            name='zip'
            onChange={formik.handleChange}
            value={formik.values.zip}
            />
            {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
        </label>
        <label className="w-full col-span-2"> Country
        <input
            type="text"
            className="rounded-md w-full h-10"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            />
            {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
        </label>
        </div>

        <p className="mt-10 text-center mb-10 lg:text-left">Please fill out your <span className="font-bold">Course Completion*</span></p>
        <div className="grid grid-cols-1 max-w-500 lg:max-w-none m-auto lg:grid-cols-3 pt-3 gap-y-14 gap-x-5">
        
        <div className="col-span-1 flex flex-col justify-between">
          <label>Please select the training program/course you completed.* </label>
              <select
              id="trainingCompleted"
              name="trainingCompleted"
              className='h-10 rounded-md w-full '
              value={formik.values.trainingCompleted}
              onChange={(e) => {
                  formik.handleChange(e);
              }}>
              <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
              <option>1.0 Wellness Life Coach Training</option>
              <option>1.0 Academic Life Coach Training</option>
              <option>1.0 Executive Life Coach Training</option>
              <option>1.0 Essential Life Coach Training</option>
              <option>2.0 Advanced Training</option>
              <option>Sport</option>
              <option>Relationship</option>
              <option>Team</option>
              <option>Inclusion</option>
              <option>Health Board</option>
              <option>Leadership</option>
              <option>Positive Psychology</option>
              <option>Business MBA</option>
              <option>Wellness Life Coach Training (Self-Study)</option>
              <option>Executive Coach Training (Self-Study)</option>
          </select>
          {errorsValidation.trainingCompleted ? <div className='text-red-500'>{errorsValidation.trainingCompleted}</div> : null}
        </div>
       
        <div className="col-span-1 flex flex-col justify-between">
          <label>When did your training program/course begin?*</label>
          <input type='training_program_begin_date'
            className="h-10 rounded-md w-full"
            value={formik.values.training_program_begin_date}
            onChange={formik.handleChange}
            name='training_program_begin_date'
            max={current}
            />
          {errorsValidation.training_program_begin_date ? <div className='text-red-500'>{errorsValidation.training_program_begin_date}</div> : null}
        </div>

        <div className="col-span-1 flex flex-col justify-between">
          <label>Who was the lead trainer of your course?*</label>
          <input
              type="text"
              className="rounded-md w-full h-10"
              id="leadTrainer"
              name='leadTrainer'
              onChange={formik.handleChange}
              value={formik.values.leadTrainer}
              />
          {errorsValidation.leadTrainer ? <div className='text-red-500'>{errorsValidation.leadTrainer}</div> : null}
        </div>

        <div className="col-span-1 flex flex-col justify-between">
          <label>Have you completed 3 one-on-one overviews?* <span className="block text-sm">Your 2nd or 3rd overview is required for completion.</span></label>
          <div>
            <label className="radio-item relative">
                <input
                id="yes"
                value="yes"
                className='opacity-0'
                checked={formik.values.overview === 'yes'}
                name="overview"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.overview === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="yes" className="ml-5 text-base">Yes</label>
            </label>

            <label className="radio-item relative ml-5">
                <input
                id="no"
                value='no'
                className='opacity-0'
                checked={formik.values.overview === 'no'}
                name="overview"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.overview === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="no" className="ml-5 text-base">No</label>
            </label>
            {errorsValidation.overview ? <div className='text-red-500'>{errorsValidation.overview}</div> : null}
          </div>
        </div>
        
        <div className="col-span-1 flex flex-col justify-between">
          <label>Have you attended at least <span className="font-bold">7 out of the 8 Group Mentor class hours?*</span></label>
          <div>
            <label className="radio-item relative">
                <input
                id="yes"
                value="yes"
                className='opacity-0'
                checked={formik.values.groupMentor === 'yes'}
                name="groupMentor"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.groupMentor === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="yes" className="ml-5 text-base">Yes</label>
            </label>

            <label className="radio-item relative ml-5">
                <input
                id="no"
                value='no'
                className='opacity-0'
                checked={formik.values.groupMentor === 'no'}
                name="groupMentor"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.groupMentor === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="no" className="ml-5 text-base">No</label>
            </label>
            {errorsValidation.groupMentor ? <div className='text-red-500'>{errorsValidation.groupMentor}</div> : null}
          </div>
        </div>
        
        <div className="col-span-1 flex flex-col justify-between">
          <label className="w-full block mb-7">Please upload your <span className="font-bold">CTEDU practice client log:</span></label>
          <div>
            <label for='practice_client_log' className="rounded-md px-2 py-1 w-max-content bg-newBranding-beigeText text-white mr-2"><i className="fa fa-upload mr-2"></i>Choose a File</label> 
            {practiceClientLogFileName === '' ? `No File Choosen` : practiceClientLogFileName}
          </div>
          <input className="hidden" id="practice_client_log" name="practice_client_log" type="file" accept="file_extension" onChange={e => {handleFileUpload(e, "practiceLog")}}/>
          {errorsValidation.practiceClientLog? <div className='text-red-500'>{errorsValidation.practiceClientLog}</div> : null}
          {fileTooLargeLog && <div className='text-red-500'>Please upload a file less than 5MB</div>}
        </div>

        <div className="col-span-1 flex flex-col justify-between">
          <label className=" w-full ">If you had a <span className="font-bold">makeup session for an absence</span>, please note the date completed below.</label>
          <textarea
              type="text"
              className="rounded-md w-full h-14 p-2"
              id="absence"
              name='absence'
              onChange={formik.handleChange}
              value={formik.values.absence}
              />
        </div>

        <div className="col-span-1 flex flex-col justify-between">
          <label className=" w-full">Additional comments or suggestions: </label>
          <textarea
              type="text"
              className="rounded-md w-full h-14 p-2"
              id="comments"
              name='comments'
              onChange={formik.handleChange}
              value={formik.values.comments}
              />
        </div>
        
        <div className="col-span-1 flex flex-col justify-between">
          <label className="w-full block mb-5">We would love to <span className="font-bold">feature you on our social media!</span><br/><span className="text-sm">Update your headshot below.</span></label>
          <div>
            <label for='headshot' className="rounded-md px-2 py-1 w-max-content bg-newBranding-beigeText text-white mr-2"><i className="fa fa-upload mr-2"></i>Choose a File</label> 
            {headshotFileName === '' ? `No File Choosen` : headshotFileName}
          </div>
          <input className="hidden" id="headshot" name="headshot" type="file" accept="file_extension" onChange={e => {handleFileUpload(e, "headshot")}}/>
          {fileTooLargeheadShot && <div className='text-red-500'>Please upload a file less than 5MB</div>}
        </div>


        <label className='w-full'>May Coach Training EDU <span className="font-bold">use your name and feedback as testimonial on our website and/or marketing materials?</span>
        <div className='mt-5'>
        <label className="radio-item relative">
            <input
            id="yes"
            value="yes"
            className='opacity-0'
            checked={formik.values.feedback === 'yes'}
            name="feedback"
            type="radio"
            onChange={e => {
                formik.handleChange(e)
            }}
            />
            {formik.values.feedback === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
            <label htmlFor="yes" className="ml-5 text-base">Yes</label>
        </label>

        <label className="radio-item relative ml-5">
            <input
            id="no"
            value='no'
            className='opacity-0'
            checked={formik.values.feedback === 'no'}
            name="feedback"
            type="radio"
            onChange={e => {
                formik.handleChange(e)
            }}
            />
            {formik.values.feedback === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
            <label htmlFor="no" className="ml-5 text-base">No</label>
        </label>

        <label className="radio-item relative mt-5 sm:mt-0 sm:ml-5 block sm:inline">
            <input
            id="noName"
            value='noName'
            className='opacity-0'
            checked={formik.values.feedback === 'noName'}
            name="feedback"
            type="radio"
            onChange={e => {
                formik.handleChange(e)
            }}
            />
            {formik.values.feedback === 'noName' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
            <label htmlFor="noName" className="ml-5 text-base">Yes, but please do not include my name</label>
        </label>
        </div>
        </label>


        </div>
          <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default CertificationApplication;