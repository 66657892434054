
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import {Timezone} from '../timezone'
import SignaturePad from "react-signature-canvas";
import Image from "../images/form-coaching-pods.png"



function CoachingPod() {

    const timezones = Timezone.timezone

    const navigate = useNavigate();

    let btnRef = useRef();
    const [currentlyEnrolled, setCurrentlyEnrolled] = useState("");
    const [completedCteduProgram, setCompletedCteduProgram] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [timezone_coaching_pads, setTimezone_coaching_pads] = useState("");
    const [phone, setPhone] = useState("");
    const [courseList, setCourseList] = useState([]);
    const [important, setImportant] = useState("");
    const [consent, setConsent] = useState("");
    const [consent2, setConsent2] = useState("");
    const [signature, setSignature] = useState(null);

    const [academic, setAcademic] = useState(false);
    const [wellness, setWellness] = useState(false);
    const [executive, setExecutive] = useState(false);
    const [essential, setEssential] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [other, setOther] = useState(false);

    const [errorsValidation, setErrorsValidation] = useState({})

    
    let mySignature1 = useRef({})
 
    const transformSignatureToImage = () => {
        setSignature(mySignature1.current.toDataURL()) 
    }

    const clearSignaturePad = () => {
        mySignature1.current.clear();
        setSignature(null)
    }
    
 
  const validate = (values) => {
    const errors = {};
    const coursesAdded =[]
    const required = ['currentlyEnrolled']
    if(currentlyEnrolled === 'no'){
        required.push('completedCteduProgram')
    }
    if(currentlyEnrolled === 'yes' || completedCteduProgram === 'yes'){
        required.push('firstName', 'lastName', 'email', 'timezone_coaching_pads', 'consent', 'consent2')
        if(mySignature1.current.isEmpty()){
            required.push('signature')
        }
       
    
        if(academic) coursesAdded.push('academic')
        if(executive) coursesAdded.push('executive')
        if(wellness) coursesAdded.push('wellness')
        if(essential) coursesAdded.push('essential')
        if(advanced) coursesAdded.push('advanced')
        if(other) coursesAdded.push('other')

        
        if(coursesAdded.length === 0) required.push('courseList')
        
    }
    
    

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
      if(coursesAdded.length === 0) {
        errors['courseList'] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setCurrentlyEnrolled(values.currentlyEnrolled)
      setCompletedCteduProgram(values.completedCteduProgram)
      setFirstName(values.firstName)
      setLastName(values.lastName)
      setEmail(values.email)
      setTimezone_coaching_pads(values.timezone_coaching_pads)
      setPhone(values.phone)
      setImportant(values.important)
      setConsent(values.consent)
      setConsent2(values.consent2)
      setAcademic(values.academic)
      setWellness(values.wellness)
      setAdvanced(values.advanced)
      setOther(values.other)
      setEssential(values.essential)
      setExecutive(values.executive)
      setCourseList(coursesAdded)
  };

  const validateOnSubmit = (values) => {
    const errors = {};
    const coursesAdded = [];
    const required = ['currentlyEnrolled']
    if(currentlyEnrolled === 'no'){
        required.push('completedCteduProgram')
    }
    if(currentlyEnrolled === 'yes' || completedCteduProgram === 'yes'){
        required.push('firstName', 'lastName', 'email',  'timezone_coaching_pads', 'consent', 'consent2')
        if(mySignature1.current.isEmpty()){
            required.push('signature')
        }
        if(academic) coursesAdded.push('academic')
        if(executive) coursesAdded.push('executive')
        if(wellness) coursesAdded.push('wellness')
        if(essential) coursesAdded.push('essential')
        if(advanced) coursesAdded.push('advanced')
        if(other) coursesAdded.push('other')

        
        if(coursesAdded.length === 0) required.push('courseList')
        
    }

    

  console.log('req ', required)
    required.map((value) => {
        console.log('value ', values)
      if(!values[value]) {
        console.log('inside ', value)
        errors[value] = 'This field is required.';
      }
      if(coursesAdded.length === 0) {
        errors['courseList'] = 'This field is required.';
      }
    
    });
    
    console.log('errors ', errors)
    return errors;

  };



  const formik = useFormik({
    initialValues: {
     currentlyEnrolled:currentlyEnrolled,
     completedCteduProgram:completedCteduProgram,
     firstName:firstName,
     lastName:lastName,
     email:email,
     timezone_coaching_pads:timezone_coaching_pads,
     phone:phone,
     courseList:courseList,
     academic:academic,
     wellness:wellness,
     essential:essential,
     executive:executive,
     other:other,
     important:important,
     consent:consent,
     consent2:consent2,
     signature:signature
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'coaching-pods'}
      console.log('data', data)

      axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/thank-you')
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12  min-h-screen bg-newBranding-beige">
      <Helmet>
            <title>Coaching Pod Registration</title>
            <meta name="description" content="Coaching Pod Registration" />
            <meta name="title" content="CTEDU Coaching Pod Registration" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
      <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1 className="text-4xl sm:text-5xl mb-5">Coaching Pod Registration</h1>
              <p className="font-bold text-xl">Coaching Pods provide an opportunity for CTEDU coaches to partner for practice on your own schedule.</p>
              <p>These could be coaches in your class, coaches enrolled in another CTEDU course, or even a CTEDU alum. If you are utilizing a Coaching Pod session to make up a missed class, you may not count that time as bartered hours because that time would be considered training hours under the ICF policy.</p>
              <p>To learn more about Coaching Pods, <a target='_blank' className="underline" href='https://drive.google.com/file/d/1D8UhyeIQ4ErJg-IHI1JBrlsvlePIezN_/view'>review this guide</a>.</p>
              <p>Before registering for a coaching pod, we encourage you to consider the commitment. Each round of pods runs for three months and we suggest meeting on a weekly basis. To register as a participant in a future coaching pod, please complete this form and our team will be in touch with additional details.</p>
            </div>
            <div className="order-1 m-auto lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='coaching-pod' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className=" text-center lg:text-left font-bold pb-5">Basic Information</p>
        <div className="grid-cols-2 max-w-500 m-auto lg:max-w-none lg:grid-cols-3 grid gap-y-5 gap-x-5 border-newBranding-beigeText pb-10 w-full border-t pt-10">

        <div className="col-span-1 flex flex-col ">
            <label>Are you currently enrolled in a course?*</label>
            <div className="mt-2">
                <label className="radio-item relative">
                    <input
                    id="yes"
                    value="yes"
                    className=''
                    checked={formik.values.currentlyEnrolled === 'yes'}
                    name="currentlyEnrolled"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.currentlyEnrolled  === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                    id="no"
                    value='no'
                    className=''
                    checked={formik.values.currentlyEnrolled  === 'no'}
                    name="currentlyEnrolled"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.currentlyEnrolled  === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.currentlyEnrolled  ? <div className='text-red-500'>{errorsValidation.currentlyEnrolled}</div> : null}
            </div>
          </div>

          {currentlyEnrolled === 'no' && 

           <div className="col-span-1 flex flex-col ">
            <label>Have you completed a CTEDU program?*</label>
            <div className="lg:mt-2">
                <label className="radio-item relative">
                    <input
                    id="yes"
                    value="yes"
                    className=''
                    checked={formik.values.completedCteduProgram === 'yes'}
                    name="completedCteduProgram"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.completedCteduProgram  === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="yes" className="ml-5 text-base">Yes</label>
                </label>
                <label className="radio-item relative ml-5">
                    <input
                    id="no"
                    value='no'
                    className=''
                    checked={formik.values.completedCteduProgram  === 'no'}
                    name="completedCteduProgram"
                    type="radio"
                    onChange={e => {
                        formik.handleChange(e)
                    }}
                    />
                    {formik.values.completedCteduProgram  === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                    <label htmlFor="no" className="ml-5 text-base">No</label>
                </label>
                {errorsValidation.completedCteduProgram  ? <div className='text-red-500'>{errorsValidation.completedCteduProgram}</div> : null}
            </div>
          </div>
        }
        </div>
         {((currentlyEnrolled === 'yes') || (completedCteduProgram === 'yes'))  && 
            <>
                <p className=" text-center lg:text-left font-bold pb-5">New coaching pods are created once a quarter. Sharing your information below will add you to the interest list for next round of pod creation.</p>
                <div className="grid-cols-1 max-w-500 m-auto lg:max-w-none lg:grid-cols-3 grid gap-y-14 gap-x-5 border-newBranding-beigeText pb-10 w-full border-t pt-10">

                    <div className="col-span-1 flex flex-col justify-between">
                        <label>First Name*</label>
                        <input
                            type="text"
                            className="rounded-md w-full  h-10 p-2"
                            id="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                        />
                        {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
                    </div>

                    <div className="col-span-1 flex flex-col justify-between">
                        <label>Last Name*</label>
                        <input
                            type="text"
                            className="rounded-md w-full  h-10 p-2"
                            id="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                        />
                        {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
                    </div>

                    <div className="col-span-1 flex flex-col justify-between">
                        <label>Email*</label>
                        <input
                            type="email"
                            className="rounded-md w-full h-10 p-2"
                            id="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            />
                        {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
                    </div>

                    <div className="col-span-1 flex flex-col">
                        <label>Time Zone*</label>
                            <select
                                id="timezone_coaching_pads"
                                name="timezone_coaching_pads"
                                className='h-10 rounded-md w-full lg:mt-5'
                                value={formik.values.timezone_coaching_pads}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                }}>
                                <option selected disabled value="">Please select a timezone</option>
                            {timezones.map((timezone, i) => {
                                    return(
                                        <option key={i}>{timezone}</option>
                                    )
                            })}
                        </select>
                        {errorsValidation.timezone_coaching_pads ? <div className='text-red-500'>{errorsValidation.timezone_coaching_pads}</div> : null}
                    </div>

                    <div className="col-span-1 flex flex-col">
                        <label>Phone</label>
                        <input
                            type="phone"
                            className="rounded-md w-full h-10 lg:mt-5 p-2"
                            id="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            />
                    </div>


                <div className="col-span-1 flex flex-col relative">
                    <label htmlFor="courseList">Which course are you currently enrolled in or have you completed?*</label>
                    <div>
                        <label className='block relative ' htmlFor="academic"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="academic" name="academic" value={formik.values.academic} checked={formik.values.academic}/>
                            {formik.values.academic ? <span className="bg-white absolute inline-flex w-6 h-6  font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">1.0 Academic Life Coaching</span>
                        </label>
                    </div>
                    <div>
                        <label className='block relative ' htmlFor="wellness"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="wellness" name="wellness" value={formik.values.wellness} checked={formik.values.wellness}/>
                            {formik.values.wellness ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">1.0 Wellness Life Coaching</span>
                        </label>
                    </div>
                    <div>
                        <label className='block relative ' htmlFor="executive"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="executive" name="executive" value={formik.values.executive} checked={formik.values.executive}/>
                            {formik.values.executive ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">1.0 Executive Coaching</span>
                        </label>
                    </div>
                    <div>
                        <label className='block relative ' htmlFor="essential"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="essential" name="essential" value={formik.values.essential} checked={formik.values.essential}/>
                            {formik.values.essential ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">1.0 Essential Coaching</span>
                        </label>
                    </div>
                    <div>
                        <label className='block relative ' htmlFor="advanced"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="advanced" name="advanced" value={formik.values.advanced} checked={formik.values.advanced}/>
                            {formik.values.advanced ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">2.0 Advanced Training</span>
                        </label>
                    </div>
                    <div>
                        <label className='block relative ' htmlFor="other"> 
                            <input className="w-6 h-6 cursor-pointer mr-3 opacity-0 relative" type="checkbox" onChange={formik.handleChange} id="other" name="other" value={formik.values.other} checked={formik.values.other}/>
                            {formik.values.other ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">Other</span>
                        </label>
                    </div>
                    {errorsValidation.courseList ? <div className='text-red-500'>{errorsValidation.courseList}</div> : null}
                </div>

                <div className="col-span-1 flex flex-col ">
                    <label>What else is important for us to know?</label>
                    <textarea
                        type="important"
                        className="rounded-md w-full h-32  lg:mt-5 p-2"
                        id="important"
                        onChange={formik.handleChange}
                        value={formik.values.important}
                        />
                </div>

                <div className="col-span-1 flex flex-col relative">
                    <label htmlFor="consent">Consent*<br/><span className="text-sm">I give permission for Coach Training EDU to share my information with coaches in training for the purpose of giving and/or receiving coaching services.</span></label>
                    <div>
                        <label className='block relative ' htmlFor="consent"> 
                            <input className="w-6 h-6 cursor-pointer opacity-0 mr-3 " type="checkbox" onChange={formik.handleChange} id="consent" name="consent" value={formik.values.consent} checked={formik.values.consent}/>
                            {formik.values.consent ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">Yes, I give you permission.</span>
                        </label>
                    </div>
                    {errorsValidation.consent ? <div className='text-red-500'>{errorsValidation.consent}</div> : null}
                </div>

                <div className="col-span-1 flex flex-col relative">
                    <label htmlFor="consent2">Consent*<br/><span className="text-sm">I understand that I am registering to participate in coaching practice with other CTEDU coaches who are counting on me to fully participate in this program. I am committed to full participation so that everyone may benefit from this experience. This includes promptly communicating with my podmates, making time to meet with my podmates, and committing to the sessions once they are scheduled.</span></label>
                    <div>
                        <label className="block relative" htmlFor="consent2">
                            <input className="w-6 h-6 cursor-pointer opacity-0 mr-3 " type="checkbox" onChange={formik.handleChange} id="consent2" name="consent2" value={formik.values.consent2} checked={formik.values.consent2}/>
                            {formik.values.consent2 ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                            <span className="absolute">Yes, I understand.</span>
                        </label>
                    </div>
                    {errorsValidation.consent2 ? <div className='text-red-500'>{errorsValidation.consent2}</div> : null}
                </div>
                
                <div className="col-span-1 flex flex-col relative">
                    <label htmlFor="consent2">Signature*</label>
                    <div className='h-36 max-w-400 w-full  bg-white rounded-md relative'>
                        <SignaturePad canvasProps={{className:'w-full h-full'} } onEnd={transformSignatureToImage} ref={mySignature1}/>
                        <div className='cursor-pointer absolute ' onClick={clearSignaturePad}>Clear</div>
                    </div>
                    {errorsValidation.signature ? <div className='text-red-500 ml-10'>{errorsValidation.signature}</div> : null}
                </div>
            </div>
            </>
        
        }

        {completedCteduProgram === 'no' && 
        <p>We're so glad you found us! Coaching pods are available to coaches who are currently enrolled in or have completed our program. Please check back once you've selected your course.</p>
        }
       

            
        
        <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default CoachingPod;