import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "./firebase";
import ResetImg from './images/reset1.png'
import ResetSmall from './images/reset-small.png'
import HeaderLogin from "./Components/HeaderLogin";
import {Helmet} from "react-helmet";



function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [linkSent, setLinkSent] = useState(false);
  const [errorReset, setErrorReset] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/application-form");
  }, [user, loading]);
  return (
    <div className='relative text-newBranding-darkGrey'>
        <Helmet>
            <title>CTEDU Application Reset</title>
            <meta name="description" content="Reset your password for your application." />
            <meta name="title" content="CTEDU Application Reset" />
        </Helmet>
    <div className="h-screen bg-newBranding-jet flex items-center justify-center">
      <HeaderLogin />
      <div className='flex flex-col mt-10 sm:max-h-80/100 sm:flex-row sm:w-4/5 text-newBranding-jet sm:m-16 w-330 lg:w-720 bg-newBranding-almostWhite sm:h-600 lg:h-420 rounded-xl shadow-cal'>
        <div className='bg-newBranding-beige rounded-l-lg hidden sm:flex flex-row w-3/5 relative'>
          <img src={ResetImg} alt="Reset Image" className="p-10 h-auto self-center"/>
        </div>
        <div className='bg-newBranding-beige sm:hidden w-330 flex flex-row justify-center rounded-t-lg '>
          <img src={ResetSmall} alt="Logo CTEDU" className="w-1/2 p-2 self-center"/>
        </div>
        {!linkSent ? (
        <div className="rounded-lg flex flex-col justify-evenly p-3	 bg-newBranding-almostWhite w-full space-y-2 sm:space-y-0 px-10  sm:w-5/6">
          
            <h1 className='text-3xl text-center text-bold'>Forgot your password?</h1>
            <h2 className='text-newBranding-greyText text-lg'>Enter your e-mail address and we’ll send you a link to reset your password.</h2>
            <div>   
            <input
              type="text"
              className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
            {errorReset != '' ? <div className='text-red-500'>{errorReset}</div> : null}
            </div>
            <div className='flex flex-row'>
            <Link to="/" className='w-max-content mx-auto duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Cancel</Link>
            <button
              className="w-max-content mx-auto duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base"
              onClick={() => sendPasswordResetEmail(email).then(response => {
                  if(response === true){
                    setLinkSent(true)
                  }else{
                    setErrorReset(response.message)
                  }
              })}
            >
              Reset
            </button>
            </div>
            <p className='text-xs text-center'>
              <span className="opacity-50">New User?</span> <Link to="/register" className='text-newBranding-blueLighter'>Register Now</Link> 
            </p> 
        </div>
        ) : ( 
        <div className="rounded-lg flex flex-col justify-evenly	 px-5  w-full sm:w-1/2">
          A link to reset your password has been sent to your email address. <Link className='text-newBranding-blueLighter' to="/">Login</Link>
        </div>
        ) }
      </div>
    </div>
    </div>
  );
}
export default Reset;

