import React, { useEffect, useState } from "react";

import { useFormik } from "formik"
import {useRef} from 'react';


function ShortQuestionsAnswers(props) {
 
  let btnRef = useRef();

  const [fit, setFit] = useState("");
  const [industry, setIndustry] = useState("");
  const [outcomes, setOutcomes] = useState("");
  const [errorsValidation, setErrorsValidation] = useState({})
  const [initialFit, setInitialFit]=useState('');
  const [initialIndustry, setInitialIndustry]=useState('');
  const [initialOutcomes, setInitialOutcomes]=useState('');

  useEffect(() => {
    props.getValue('fit').then((result) => {
      setFit(result)
      setInitialFit(result)
    })
    props.getValue('industry').then((result) => {
      setIndustry(result)
      setInitialIndustry(result)
    })
    props.getValue('outcomes').then((result) => {
      setOutcomes(result)
      setInitialOutcomes(result)
    })
   
  }, [props.user]);


  const validate = (values) => {
    const errors = {};
    const required = ['fit', 'industry', 'outcomes']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });

  
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      fit: fit,
      industry: industry, 
      outcomes: outcomes,
      step:4
    },    

    enableReinitialize: true,  
    onSubmit: (values, initialValues) => {
      
     validate(values);
     const errors = validate(values);
      if(Object.keys(errors).length === 0){
        btnRef.current.setAttribute("disabled", "disabled");
        // const url =
          // "https://us-central1-ctedu-home-static.cloudfunctions.net/forms-forms"
        let dataChanged = false;
        if((values.fit !== initialFit)
        || (values.industry !== initialIndustry)
        || (values.outcomes !== initialOutcomes)){
          dataChanged = true
        }
       
        let data = values
         props.onSubmit(data, dataChanged)
      }
      setErrorsValidation(errors)
      return errorsValidation
      //data["form"] = "contact-dev"
      //axios.post(url, values).then(() => navigate('/contact-next-steps',  { state: { name: data.name, powerful_paragraph:data.powerful_paragraph, syllabus:data.syllabus } }));
      // alert(JSON.stringify(values, null, 2));
    },
  })

  
  return (
    <div>
      <div className=" ">
      <h2 className='text-center md:text-left mb-5 font-bold'>Short Answer Questions</h2>
      <p className="text-sm mb-5">Coach Training EDU is a mission-driven organization aimed at making a positive different in the world by educating coaches with a deep-held desire to help others flourish. Our courses are intentionally designed with small cohort sizes, highly interactive sessions, and the best practices in education to optimize learning and maximize skill acquisition.</p>
      <form onSubmit={formik.handleSubmit} className="mb-10 flex flex-col justify-center ">
      <label className="mb-5 mt-5 text-newBranding-darkGrey opacity-75">What makes you a good fit for CTEDU?
        <textarea
            type="text"
            className="block border border-newBranding-blue border-opacity-50 w-full mt-2 h-28 mb-5"
            id="fit"
            onChange={formik.handleChange}
            value={formik.values.fit}
            />
            {errorsValidation.fit ? <div className='text-red-500'>{errorsValidation.fit}</div> : null}
        </label>
        <label className="mb-5 text-newBranding-darkGrey opacity-75">What are your long-term goals for your coaching career?
        <textarea
            type="text"
            className="block border border-newBranding-blue border-opacity-50 w-full mt-2 h-20 mb-5"
            id="industry"
            onChange={formik.handleChange}
            value={formik.values.industry}
            />
            {errorsValidation.industry ? <div className='text-red-500'>{errorsValidation.industry}</div> : null}
        </label>
        <label className="mb-5 text-newBranding-darkGrey opacity-75">What do you hope to get -personally and professionally- out of your coach training experience?
        <textarea
            type="text"
            className="block border border-newBranding-blue border-opacity-50 w-full mt-2 h-20"
            id="outcomes"
            onChange={formik.handleChange}
            value={formik.values.outcomes}
            />
            {errorsValidation.outcomes ? <div className='text-red-500'>{errorsValidation.outcomes}</div> : null}
        </label>
       
        <div className='flex flex-row justify-between'>
        <button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
          <button ref={btnRef} type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
        </div>
      </form>
      </div>
    </div>
  );
}
export default ShortQuestionsAnswers;