import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const axios = require('axios');
const md5 = require("md5");


const firebaseConfig = {
    apiKey: "AIzaSyDbqfqw1hU9CLNpH0yVzvJW6Xm1cqZ8gZE",
    authDomain: "ctedu-home-static.firebaseapp.com",
    databaseURL: "https://ctedu-home-static-default-rtdb.firebaseio.com",
    projectId: "ctedu-home-static",
    storageBucket: "ctedu-home-static.appspot.com",
    messagingSenderId: "361428229594",
    appId: "1:361428229594:web:e409f194e143b56496b4e3",
    measurementId: "G-9EC7VHJRNV"
  };

const app = firebase.initializeApp(firebaseConfig, 'secondary');
const db = app.firestore();


const getUserToApproveDirectory = async () =>{
    let users = [] 
    const snapshot = await db.collection('coach').where("coachApplicationSubmitted", "==", true).where("profileApproved", "==", false).get()
    snapshot.docs.map(doc => {
       users.push(doc.data())
    });
    return users
}

const getAllApprovedProfile = async () =>{
    let users = [] 
    const snapshot = await db.collection('coach').where("coachApplicationSubmitted", "==", true).where("profileApproved", "==", true).get()
    snapshot.docs.map(doc => {
       users.push(doc.data())
    });
    return users
}

const approveProfile = async (email) => {
    let data = db.collection("coach").where("email", "==", email).limit(1).get().then(query => {
        const thing = query.docs[0];

        let tmp = thing.data();
        tmp['profileApproved'] = true;
        tmp['notApprovedReason'] = '';
        tmp['editSubmitted'] = false

        thing.ref.update(tmp);
        return tmp
        });
        return data
}

const notApproveProfileDb = async (email, reason) => {
    let data = db.collection("coach").where("email", "==", email).limit(1).get().then(query => {
        const thing = query.docs[0];

        let tmp = thing.data();
        tmp['coachApplicationSubmitted'] = false;
        tmp['notApprovedReason'] = reason

        thing.ref.update(tmp);
        return tmp
        });
        return data
}

const removeEditedProfile = async (email) => {
    let data = db.collection("coach").where("email", "==", email).limit(1).get().then(query => {
        const thing = query.docs[0];

        let tmp = thing.data();
        tmp['profileApproved'] = false
        tmp['editSubmitted'] = false

        thing.ref.update(tmp);
        return tmp
        });
        return data
}

const getRecentlyEditedProfile = async () => {
    let users = [] 
    const snapshot = await db.collection('coach').where("coachApplicationSubmitted", "==", true).where("profileApproved", "==", true).where("editSubmitted", "==", true).get()
    snapshot.docs.map(doc => {
       users.push(doc.data())
    });
    return users
}

export{
    getUserToApproveDirectory,
    getRecentlyEditedProfile,
    approveProfile,
    getAllApprovedProfile,
    notApproveProfileDb,
    removeEditedProfile
} 