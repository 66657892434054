
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb } from "./firebase";
import Header from "./Components/Header";
import SuccessImg from './images/success-gif.gif'
import Sidebar from "./Components/Sidebar";
import {Helmet} from "react-helmet";



function Success(props, location) {
    
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const navigate = useNavigate();

    if (typeof window !== 'undefined'){
      if(window.history.state === null){
          navigate("/")
      }
    }

   // const date = window.history.state.usr.date
  
    const fetchUserName = async () => {
      try {
        const query = await db
          .collection("users")
          .where("uid", "==", user?.uid)
          .get();
        const data = await query.docs[0].data();
        setName(data.name);
      } catch (err) {
        console.error(err); 
      }
    };
  
    useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/");
      fetchUserName();
    }, [user, loading]);

   let reformatedName;
   reformatedName = name.split(' ')[0]
   reformatedName = reformatedName.charAt(0).toUpperCase() + reformatedName.slice(1);
    
  return (
     <div className="flex text-newBranding-darkGrey mt-12">
       <Helmet>
            <title>Application completed</title>
            <meta name="description" content="CTEDU application is completed." />
            <meta name="title" content="Application completed" />
        </Helmet>
        <Header logout={logout}/>

        <div className=" max-w-1220 mx-auto flex flex-row md:flex-col w-full">

        <Sidebar activeStep={7} />
        <div className="ml-5 text-center md:text-left md:ml-14 mr-5 pt-28 md:pt-0 lg:mr-10 md:pl-5 lg:pl-10 pt-5 md:ml-80 h-full bg-newBranding-beige  min-h-screen">
        
            <h2 className='text-3xl font-bold mt-26 md:mt-10 mb-10 text-center lg:text-left'>Thank you, {reformatedName}!</h2>
            <img src={SuccessImg} alt='Thank you image' className='w-3/5 m-auto md:ml-0 md:w-1/3 max-w-400 mb-10'/>
            <p className="font-bold mb-2">Thank you for sending your application!</p>
            <p className='mb-10'> Our enrollment team will be in touch in the next 48 hours with your application status. In the meantime, please feel free to reach out to your enrollment advisor with any questions about your application or our program.</p>
            <div className="flex items-center md:justify-around lg:justify-start md:items-start flex-col md:flex-row">
              <a href='tel:+15039940914' className='lg:mr-10 mb-5 w-max-content duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1'>(1) 503-360-6700</a>
              <a href="https://meetings.hubspot.com/andrew-hock/andrew-hock" target="_blank" className="lg:mr-10 w-max-content duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1">Schedule a 20 Minute Call</a>
         {/* <div className='flex flex-row'>
            <p className='mt-10 mr-5 text-xl'>FOLLOW US </p>
            <div className='mt-8'>
              <a href='https://www.facebook.com/coachtrainingedu' target='_blank'><i className="fab fa-facebook-square text-newBranding-blue m-2 fa-2x"></i></a>
              <a href='https://www.instagram.com/coachtrainingedu/' target='_blank'><i className="fab fa-instagram-square text-newBranding-blue mr-2 fa-2x"></i></a>
              <a href='https://twitter.com/coachtrainedu?s=20' target='_blank'><i className="fab fa-twitter-square text-newBranding-blue mr-2 fa-2x"></i></a>
              <a href='https://www.youtube.com/channel/UC_LOLtWJToDntdbC_he5cXA' target='_blank'><i className="fab fa-youtube-square text-newBranding-blue mr-2 fa-2x"></i></a>
              <a href='https://www.pinterest.com/coachtrainingedu/' target='_blank'><i className="fab fa-pinterest-square text-newBranding-blue mr-2 fa-2x"></i></a>
              <a href='https://www.linkedin.com/school/coach-train-edu/' target='_blank'><i className="fab fa-linkedin-square text-newBranding-blue mr-2 fa-2x"></i></a>
            </div>
          </div> */}
       </div>
       </div>
       </div>
    </div>
  );
}
export default Success;