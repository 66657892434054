import React from "react";
import { Link } from "react-router-dom";
import Logo from '../images/logo-texture-hor.png'
import Phone from '../images/Phone.svg'
import Globe from '../images/globe.svg'
import ScheduleCall from '../images/scheduleCall.svg'




/*function HeaderLogin() {

  return (
    <div className="bg-white fixed top-0 w-full p-2">
      <div className="m-auto justify-between flex flex-row space-between sm:px-10 ">
        <a href="https://www.coachtrainingedu.com/" className='my-auto' target="_blank"><img src={Logo} className='max-w-120 sm:max-w-150' alt="Logo CTEDU"/></a>
          <div className="hidden sm:flex flex-row justify-end space-x-5">
               <a href="https://www.coachtrainingedu.com/" target="_blank" className="m-auto">Back to website</a>
              <div className="h-full mr-3 border-l-2 border-newBranding-jet"></div>
              <a href='tel:+15033606700' className='m-auto flex font-extrabold'> <img src={Phone} className='w-6 hidden lg:block mr-2' alt="Phone Link"/> (1) 503-360-6700</a>
              <Link to='/' className="px-5 py-0 m-1 lg:text-lg duration-300 transition no-underline rounded-3xl hover:border-newBranding-blue border border-newBranding-blue bg-newBranding-blue text-white hover:text-newBranding-blue hover:bg-white button-text">Login</Link>
          </div>
          <div className="flex sm:hidden flex-row">
            <a href="https://www.coachtrainingedu.com/" target="_blank" className=" text-sm m-auto mr-1">Back to website</a>
            <div className="h-full mr-3 border-l-2 border-newBranding-jet"></div>
            <a className="m-auto" href='tel:+15033606700'><img src={Phone} className='w-6  mr-2' alt="Phone Link"/> </a>
            <Link to='/' className="px-5 py-0 m-1 lg:text-lg duration-300 transition no-underline rounded-3xl hover:border-newBranding-blue border border-newBranding-blue bg-newBranding-blue text-white hover:text-newBranding-blue hover:bg-white button-text">Login</Link>
          </div>
      </div>
    </div>
  );
}
export default HeaderLogin;*/


import { useState } from "react"; // import state

export default function HeaderLogin(props) {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

  return (
    <div className="bg-white z-50 fixed top-0 w-full  p-3">
    <div className="flex max-w-1220 m-auto items-center justify-between sm:px-10 ">
      <a href="https://www.coachtrainingedu.com/" target="_blank" className="my-auto">
      <img src={Logo} className='max-w-120 sm:max-w-150' alt="Logo CTEDU"/>
      </a>
      <nav>
        <section className="MOBILE-MENU lg:relative flex">
          <div
            className="HAMBURGER-ICON cursor-pointer space-y-2 "
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className="block h-0.5 w-8  bg-newBranding-jet"></span>
            <span className="block h-0.5 w-8 bg-newBranding-jet"></span>
            <span className="block h-0.5 w-8 bg-newBranding-jet"></span>
          </div>

         {/* Menu for phone and tablet */}
          <div className="lg:hidden">
          <div className={isNavOpen ? "showMenuNav divide-y-2 divide-newBranding-beige" : "hideMenuNav"}>
            <div className="p-4 px-6 bg-white">
            <a href="https://www.coachtrainingedu.com/" className='my-auto' target="_blank"><img src={Logo} className='max-w-150' alt="Logo CTEDU"/></a>
            <div
              className="CROSS-ICON cursor-pointer absolute top-0 right-0 px-6 py-4"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-newBranding-jet"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="13" y1="6" x2="6" y2="13" />
                <line x1="6" y1="6" x2="13" y2="13" />
              </svg>
            </div>
            </div>
            <ul className="flex flex-col divide-y-2 divide-newBranding-beige border-b-2 border-newBranding-beige justify-between min-h-[250px]">
              <li className="p-5 bg-white">
                <a href="https://www.coachtrainingedu.com/" className="m-auto flex" target="_blank"><img src={Globe} className='w-6 block mr-4' alt="Back to main website Link"/> Back to Main Website</a>
              </li>
              <li className="p-5 bg-newBranding-menuBlue">
              <a href='tel:+15033606700' className='m-auto flex'> <img src={Phone} className='w-6 block mr-4' alt="Phone Link"/>Contact Us (1) 503-360-6700</a>
              </li>
              <li className="p-5 bg-white">
              <a  href="https://meetings.hubspot.com/andrew-hock/andrew-hock" target="_blank" className='m-auto flex'> <img src={ScheduleCall} className='w-6 block mr-4' alt="Schedule a 20 minute call Link"/>Schedule a 20 minute call</a>
              </li>
              <li className="bg-white p-5 border-b-2 border-newBranding-beige">
                <Link to='/' className="px-5 py-0.5 m-1 lg:text-lg font-normal duration-300 transition w-max-content no-underline rounded-3xl hover:border-newBranding-blue border border-newBranding-blue bg-newBranding-blue text-white hover:text-newBranding-blue hover:bg-white button-text">Login</Link>
              </li>
            </ul>
          </div>
          </div>

           {/* Menu for laptop */}
           <div className="hidden lg:block">
          <div className={isNavOpen ? "showMenuNavLargeScreen" : "hideMenuNav"}>
            <div className="p-nav bg-white">
            <div
              className="CROSS-ICON cursor-pointer absolute top-0 right-0 pr-0 py-4"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-newBranding-jet"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="13" y1="6" x2="6" y2="13" />
                <line x1="6" y1="6" x2="13" y2="13" />
              </svg>
            </div>
            </div>
            <div className="flex flex-col py-2 bg-white border-t border-newBranding-menuBorder shadow-menu w-max-content justify-between min-h-[250px]">
              <p className=" border-b mx-8 border-newBranding-menuBorder font-serif text-sm py-4">Help & Feedback</p>
              <ul className="pt-2 ">
              <li className="px-8 p-2 hover:bg-newBranding-beige mt-2">
                <a href="https://www.coachtrainingedu.com/" className="text-sm m-auto flex " target="_blank"><img src={Globe} className='w-5 block mr-4' alt="Back to main website Link"/> Back to Main Website</a>
              </li>
              <li className="p-2 px-8 hover:bg-newBranding-beige">
              <a href='tel:+15033606700' className='text-sm m-auto flex '> <img src={Phone} className='w-5 block mr-4' alt="Phone Link"/>Contact Us (1) 503-360-6700</a>
              </li>
              <li className="p-2 px-8 hover:bg-newBranding-beige">
              <a  href="https://meetings.hubspot.com/andrew-hock/andrew-hock" target="_blank" className=' m-auto text-sm flex'> <img src={ScheduleCall} className='w-5 block mr-4' alt="Schedule a 20 minute call Link"/>Schedule a 20 minute call</a>
              </li>
              <div className="border-b border-newBranding-menuBorder mt-4 mx-8"></div>
              <li className="px-8 pt-2">
                <Link to='/' className="px-5 py-0.5 m-1 text-sm font-normal duration-300 transition w-max-content no-underline rounded-3xl hover:border-newBranding-blue border border-newBranding-blue bg-white text-newBranding-blue hover:text-white hover:bg-newBranding-blue button-text">Login</Link>
              </li>
              </ul>
            </div>
          </div>
          </div>

        </section>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        font-weight:bolder;
        left: 0;
        font-size:20px;
        background: #EEEEEE;
        z-index: 10;
        display: flex;
        flex-direction: column;
      }
      .showMenuNavLargeScreen {
        display: block;
        position: absolute;
        width: fit-content;
        height: 100%;
        top: -15px;
        font-weight:bolder;
        right: -8px;
        font-size:20px;
        background: #EEEEEE;
        z-index: 10;
        display: flex;
        flex-direction: column;
      }
    `}</style>
    </div>
    </div>
  );
}
