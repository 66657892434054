
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb, getValueFromDbUsingUid } from "./firebase";
import Header from "./Components/Header";
import FormContainer from './Components/FormContainer'
import Sidebar from "./Components/Sidebar";
import axios from 'axios';
import {Helmet} from "react-helmet";
 
//import  response  from 'express';
 
 
function ApplicationForm() {
  const [user, loading, error] = useAuthState(auth);
  const [data, setData] = useState({})
  const [alreadyStarted, setAlreadyStarted] = useState(false)
 
  const navigate = useNavigate();
 
  const fetchUser = async (response) => {
    try {
      const data = await db.collection("users").where("uid", "==", user?.uid).get().then((query) => {
        return getValueFromDbUsingUid(user?.uid)
      })
 
      return data
    } catch (err) {
      console.log(err)
      return err
    }
  };
 
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUser().then((response) => {
      setData(response)
      if((response.firstLogin === true) && !alreadyStarted){
        setAlreadyStarted(true)
        const url =
        "https://us-central1-ctedu-lms.cloudfunctions.net/application"
        const body = {"email": response.email, "name":response.name, 'type':'newRegistration'}
        axios.post(url, body, {
          headers: {
            'Content-Type': 'application/json'
          }})
      }
      return response.email
    }).then((response) => {
      writeUserData(response, {firstLogin: false})
    })
 
   
  }, [user, loading]);
 
  const [activeStep, setActiveStep] = useState(0);
 
      function handleChange(values, dataChanged){
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0)
        writeUserData(user.email, values, dataChanged).then(response => {
          if(response.dataChanged){
            const url =
            "https://us-central1-ctedu-lms.cloudfunctions.net/application"
            const body = {'type':'newData', 'data': response}
            axios.post(url, body, {
              headers: {
                'Content-Type': 'application/json'
              }})
              return response.email
          }

          return response.email
        })
        return
      }
 
      function handleBack(values) {
          setActiveStep(activeStep - 1);
          writeUserData(user.email, values)
        }
 
      const getValueDb = async(key) => {
        if(user != null){
            return getValueFromDb(user.email, key)
        }
      }
 
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>CTEDU Application</title>
            <meta name="description" content="Complete your CTEDU Application" />
            <meta name="title" content="CTEDU Application" />
        </Helmet>
      
      <Header logout={logout}/>
      <div className=" max-w-1220 mx-auto flex flex-row md:flex-col w-full">
        <Sidebar activeStep={activeStep} />
        <FormContainer user={user} fetchedData={data} email={user?.email}  activeStep={activeStep} handleChange={handleChange} handleBack={handleBack} getValueDb={getValueDb} />
      </div>
    </div>
  );
}
export default ApplicationForm;