import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyAfqMpRTGr6ALY9yT-ogCQQ3kMcRg1swOY",
    authDomain: "gocoaching-c475c.firebaseapp.com",
    projectId: "gocoaching-c475c",
    storageBucket: "gocoaching-c475c.appspot.com",
    messagingSenderId: "444544886321",
    appId: "1:444544886321:web:c39867dd0e45ce078ad710"
  };

const app = firebase.initializeApp(firebaseConfig, 'third');
const db = app.firestore();


const getAllCoachesToApproveApp = async () =>{
  let users = [] 
  const snapshot = await db.collection('coaches').where("profileCompleted", "==", true).where("profileApproved", "==", false).get()
  snapshot.docs.map(doc => {
     users.push(doc.data())
  });
  return users
}

const getCompletedOverviewSession = async () =>{
  let users = [] 
  const snapshot = await db.collection('sessions').where("sessionType", "==", "overview").where("status", "==", "completed").get()
  snapshot.docs.map(doc => {
     users.push(doc.data())
  });
  return users
}

const getSuccessfulSession = async () =>{
  let users = [] 
  const snapshot = await db.collection('sessions').where("sessionStatus.successfull", "==", true).get()
  snapshot.docs.map(doc => {
     users.push(doc.data())
  });
  return users
}

const getUnsuccessfulSession = async () =>{
  let users = [] 
  const snapshot = await db.collection('sessions').where("sessionStatus.matched", "==", false).get()
  snapshot.docs.map(doc => {
     users.push(doc.data())
  });
  return users
}

const approveProfile = async (email) => {
  let data = db.collection("coaches").where("email", "==", email).limit(1).get().then(query => {
      const thing = query.docs[0];

      let tmp = thing.data();
      tmp['profileApproved'] = true;

      thing.ref.update(tmp);
      return tmp
      });
      return data
}

const notApproveProfileDb = async (email, reason) => {
  let data = db.collection("coaches").where("email", "==", email).limit(1).get().then(query => {
      const thing = query.docs[0];

      let tmp = thing.data();
      tmp['profileCompleted'] = false;
      tmp['notApprovedReason'] = reason;

      thing.ref.update(tmp);
      return tmp
      });
      return data
}


export {
  getAllCoachesToApproveApp,
  approveProfile,
  notApproveProfileDb,
  getCompletedOverviewSession,
  getSuccessfulSession,
  getUnsuccessfulSession
}