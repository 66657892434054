import React, { useEffect, useState } from "react";
import {getAllCoachesToApproveApp, approveProfile, notApproveProfileDb, getCompletedOverviewSession, getSuccessfulSession, getUnsuccessfulSession} from '../firebaseGoCoaching'

function GoCoachingDashboard(){
    const [profileToApprove, setProfileToApprove] = useState([])
    const [notApprovedReason, setNotApprovedReason] = useState('')
    const [open, setOpen] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(undefined);
    const [completedOverviewSessions, setCompletedOverviewSessions] = useState([])
    const [successfulSession, setSuccessfulSession] = useState([])
    const [unsuccessfulSession, setUnsuccessfulSession] = useState([])

    useEffect(() => {
        getAllCoachesToApproveApp().then(res => {
            setProfileToApprove(res)
        })
        getCompletedOverviewSession().then(res => {
            setCompletedOverviewSessions(res)
        })
        getSuccessfulSession().then(res => {
            setSuccessfulSession(res)
        })
        getUnsuccessfulSession().then(res => {
            setUnsuccessfulSession(res)
        })
    }, [1])

    const showInputToDenyProfile = (i) => {
        setSelectedProfile(i);
        setOpen(true);
    }

    const setApproveProfile = (profile) => {
        approveProfile(profile.email).then(res => {
            let data = {}
            data.title = 'Coach Theory approved your profile'
            data.body = 'You can now start coaching!'
            const tokens = [res.notificationToken];
            data.tokens = tokens

           fetch(`https://us-central1-gocoaching-c475c.cloudfunctions.net/pushRemoteNotification`, {
              method: 'POST',
              mode: 'no-cors',
              body:JSON.stringify(data),
              headers: {
                'Content-Type': 'application/json',
              },
            });
        }).then(res => console.log('RES ', res))
        setProfileToApprove(profileToApprove.filter(item => item.email !== profile.email))
    }

    const setNotApproveProfile = (profile, reason) => {
        notApproveProfileDb(profile.email, reason).then(res => {
            let data = {}
            data.title = "Coach Theory didn't approved your profile"
            data.body = 'Please update your profile before resubmitting!'
            const tokens = [res.notificationToken];
            data.tokens = tokens

           fetch(`https://us-central1-gocoaching-c475c.cloudfunctions.net/pushRemoteNotification`, {
              method: 'POST',
              mode: 'no-cors',
              body:JSON.stringify(data),
              headers: {
                'Content-Type': 'application/json',
              },
            });
        }).then(res => console.log('RES ', res))
        setProfileToApprove(profileToApprove.filter(item => item.email !== profile.email))
    }


    return(
        <div className="max-w-1220 m-auto grid grid-cols-2 gap-5">
            <div className="bg-newBranding-beige w-full p-5 mt-5 rounded-lg">
                <h1 className="mb-10 text-3xl mt-5">Coaches to approve</h1>
                {profileToApprove.map((coach, i) => {
                    return(
                        <div className={`mb-5 ${i!==0 && `border-t-2 border-white pt-5`}`} key={i}>
                            <p>Name: {coach.firstName} {coach.lastName}</p>
                            <p>Email: {coach.email}</p>
                            <button onClick={() => setApproveProfile(coach)}>Approve</button>
                            <button className={`${(open === true && selectedProfile === i) && `border-red-500 text-red-500`} py-2 ml-2 text-red-500`} onClick={() => showInputToDenyProfile(i)}>Not Approve</button>
                            {(open === true && selectedProfile === i) && 
                        
                            <div>
                                <textarea
                                    type="text"
                                    className="block w-full border h-20 p-2"
                                    placeholder="Reasons the profile is not approved)"
                                    id="state"
                                    onChange={(e) => setNotApprovedReason(e.target.value)}
                                    value={notApprovedReason}/>
                                <button className="bg-newBranding-darkGrey py-2 w-full text-center text-white hover:text-red-500" onClick={() => setNotApproveProfile(coach, notApprovedReason)}>Submit</button>
                            </div>  
                    }
                        </div>

                    )
                })}
            </div>
            <div className="bg-newBranding-beige w-full p-5 mt-5 rounded-lg">
                <h2 className="mb-10 text-3xl mt-5">Sessions</h2>
                <div className="grid grid-cols-3 gap-5">
                    <div className="bg-yellow-600 text-white p-2 rounded-lg">
                        <p>Completed Overview Sessions</p>
                        <p className="text-4xl">{completedOverviewSessions.length}</p>
                    </div>
                    <div className="bg-green-600 text-white p-2 rounded-lg">
                        <p>Successfully Matched Sessions</p>
                        <p className="text-4xl">{successfulSession.length}</p>
                    </div>
                    <div className="bg-orange-700 text-white p-2 rounded-lg flex flex-col justify-between">
                        <p>Sessions not matched</p>
                        <p className="text-4xl">{unsuccessfulSession.length}</p>
                    </div>
                </div>
            </div> 
            <div>
            <h2 className="mb-10 text-3xl mt-5">3 Top Rated coaches:</h2>
            </div> 
        </div>
    )
}

export default GoCoachingDashboard