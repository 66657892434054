import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Header from "./Components/Header";
import Logo from './images/LOGO-CTEDU-200px-01.svg'
import Login1 from './images/login.png'
import LoginSmall from './images/login-small.png'
import Google from './images/btn_google_signin_light_normal_web.png'
import GoogleHover from './images/google-hover.png'
import HeaderLogin from "./Components/HeaderLogin";
import {Helmet} from "react-helmet";



function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState("")

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) navigate("/application-form")  
  }, [user, loading]);
  return (
 
    <div className='relative text-newBranding-darkGrey'>
       <Helmet>
            <title>CTEDU Application Login</title>
            <meta name="description" content="Login to your application for Coach Training Edu." />
            <meta name="title" content="CTEDU Application Login" />
        </Helmet>
    <div className="h-screen bg-newBranding-jet flex items-center justify-center ">
  
        <HeaderLogin/>

      <div className='flex flex-col mt-10 sm:max-h-80/100 sm:flex-row sm:w-4/5 text-newBranding-jet sm:m-16 w-330 lg:w-720 bg-newBranding-almostWhite sm:h-600 lg:h-420 rounded-xl shadow-cal'>
        <div className='bg-newBranding-beige hidden w-330 sm:flex flex-row justify-center rounded-l-lg '>
          <img src={Login1} alt="Logo CTEDU" className="-4/5 lg:w-2/3 self-center"/>
        </div>
        <div className='bg-newBranding-beige sm:hidden w-330 flex flex-row justify-center rounded-t-lg '>
          <img src={LoginSmall} alt="Logo CTEDU" className="w-1/2 p-2 self-center"/>
        </div>
        <div className="rounded-lg flex flex-col justify-between p-3	 bg-newBranding-almostWhite w-full space-y-2 sm:space-y-0 px-10  sm:w-5/6">
          <h1 className='text-3xl text-center font-bold'>Login</h1>
          <h2 className='text-newBranding-greyText text-lg '>Welcome back! Please login to your account.</h2>
          <div>
         
            <button className="m-auto login__google  self-center block m-auto" onClick={signInWithGoogle}>
            <img onMouseOver={e => e.target.src = GoogleHover} onMouseOut={e => e.target.src = Google} classame='border block  self-center m-auto rounded-full border-newBranding-darkGrey w-1/3' src={Google}/>
            </button>
            
            <p className='opacity-50 text-center text-xs'>or use your email for registration</p>
            </div>
            <div>
            
          <input
            type="text"
            className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
          />
          <input
            type="password"
            className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          {errorMessage != '' ? <div className='text-red-500'>{errorMessage}</div> : null}
          <p className='text-right opacity-50 text-xs'> <Link to="/reset">Forgot Password?</Link></p>
          </div>
          <button
            className="w-max-content mx-auto duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1"
            onClick={() => signInWithEmailAndPassword(email, password)
              .then(response => {
              if(response != true){
                setErrorMessage(response.message)
              }
            })}
          >
            LOGIN
          </button>
          
          <p className='text-xs text-center'>
            <span className="opacity-50">New User?</span> <Link to="/register" className='text-newBranding-blueLighter'>Register Now</Link> 
          </p>
        </div>
      </div>
    </div>
    </div>
    
  );
}
export default Login;