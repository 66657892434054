
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb, getValueFromDbUsingUid } from "../firebase";
import Header from "../Components/Header";
import FormContainer from '../Components/FormContainer'
import { useFormik } from "formik"
import SidebarAdvanced from "../Components/SidebarAdvanced";
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import AdvancedForm from "../Components/AdvancedForm"
import {useRef} from 'react';
import Image from "../images/graduate-form.png"
const moment = require('moment-timezone');
const contentful = require("contentful");



//import  response  from 'express';

 
 
function GraduateStoryForm() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [websiteGraduate, setWebsiteGraduate] =  useState("");
    const [socialMediaGraduate, setSocialMediaGraduate] = useState("");
    const [notesGraduate, setNotesGraduate] = useState("");
    const [bioGraduate, setBioGraduate] = useState("");
    const [headshotGraduate, setHeadshotGraduate] = useState("");
    const [headshotFileName, setHeadshotFileName] = useState("");
    const [fileTooLargeheadShot, setFileTooLargeHeadshot]= useState(false);

    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'country', 'headshotGraduate', 'bioGraduate']


    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setCountry(values.country)
      setWebsiteGraduate(values.websiteGraduate)
      setBioGraduate(values.bioGraduate)
      setSocialMediaGraduate(values.socialMediaGraduate)
      setNotesGraduate(values.notesGraduate)
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'country', 'headshotGraduate', 'bioGraduate']



    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      country: country,
      websiteGraduate: websiteGraduate,
      headshotGraduate:headshotGraduate,
      socialMediaGraduate: socialMediaGraduate,
      notesGraduate: notesGraduate,
      bioGraduate:bioGraduate
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"



       //2 files to appends after making the first one woirking

      let formData = new FormData();
      formData.append("fileHeadshot", data.headshotGraduate);
      formData.append("email_address", data.email);
      formData.append("country", data.country);
      formData.append("phone", data.phone);
      formData.append("name", data.firstName + ' ' + data.lastName);
      formData.append("website", data.websiteGraduate);
      formData.append("socialMedia", data.socialMediaGraduate);
      formData.append("notes", data.notesGraduate);
      formData.append("bioGraduate", data.bioGraduate);
      formData.append("type", "graduate-story");

      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(() =>{
           navigate('/thank-you')
        }).catch(err => {
          console.log('errror ', err)
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })

  const handleFileUpload = (event, fileType) => {
        if(event.currentTarget.files[0].size > 10000000){
          setFileTooLargeHeadshot(true)
        }else{
          setHeadshotGraduate(event.currentTarget.files[0])
          setHeadshotFileName(event.currentTarget.files[0].name)
          setFileTooLargeHeadshot(false)
        }
    }


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Submit your graduate story</title>
            <meta name="description" content="Submit your graduate story" />
            <meta name="title" content="CTEDU Graduate Story Submission" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid  grid-cols-1 max-w-600 justify-content items-center  m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1  className="text-4xl sm:text-5xl mb-5">Submit Your Graduate Story!</h1>
              <p className='branding-subheader'>We're Thrilled to Feature You!</p>
              <p>We're blown away by our community and are grateful to offer many amazing webinars, workshops, graduate stories and features because of people like you! Thank you for being you, for doing the incredible work you do in the world, and for being a part of CTEDU!</p>
            </div>
            <div className="order-1 w-1/2 lg:w-4/5 m-auto lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>

      <div className="w-full min-h-screen bg-newBranding-beige px-10">
        <form id='graduate-story' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className="mb-10 text-center lg:text-left">Please fill out your <span className="font-bold">Information*</span></p>
        <div className="grid-cols-2 max-w-500 m-auto w-full lg:max-w-none lg:grid-cols-3 grid gap-y-10 gap-x-5 border-b border-newBranding-beigeText pb-10">
          
          <label> First Name
            <input
                type="text"
                className="rounded-md w-full  h-10 px-2"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
            {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>

          <label> Last Name
            <input
                type="text"
                className="rounded-md w-full h-10 px-2"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
            {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
        </label>

        <label> Email
          <input
              type="email"
              className="rounded-md w-full h-10 px-2"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              />
          {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
        </label>

        <label> Phone
          <input
              type="text"
              className="rounded-md w-full h-10 px-2"
              id="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              />
          {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
        </label>
 
        <label className="w-full"> Country
        <input
            type="text"
            className="rounded-md w-full h-10 px-2"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            />
            {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
        </label>
        </div>

        <p className="mt-10 text-center mb-10 lg:text-left font-medium">Please tell us more about you.</p>
        <div className="grid grid-cols-1 max-w-500 w-full lg:max-w-none m-auto lg:grid-cols-3 pt-3 gap-y-14 gap-x-5">
            <div className="col-span-1 flex flex-col justify-between">
                <label>Professional Website </label>
                <input type='websiteGraduate'
                    className="h-10 rounded-md w-full px-2"
                    value={formik.values.websiteGraduate}
                    onChange={formik.handleChange}
                    name='websiteGraduate'
                    max={current}
                    />
            </div>
            <div className="col-span-1 flex flex-col justify-between">
                <label>Social Media Handles</label>
                <textarea
                    type="text"
                    className="rounded-md w-full px-2 h-14"
                    id="socialMediaGraduate"
                    name='socialMediaGraduate'
                    onChange={formik.handleChange}
                    value={formik.values.socialMediaGraduate}
                    />
            </div>
            <div className="col-span-1 flex flex-col justify-between">
                <label className=" w-full">notes </label>
                <textarea
                    type="text"
                    className="rounded-md w-full h-14 px-2"
                    id="notesGraduate"
                    name='notesGraduate'
                    onChange={formik.handleChange}
                    value={formik.values.notesGraduate}
                    />
            </div>
            <div className="col-span-2 flex flex-col justify-between">
                <label className=" w-full px-2">Professional Bio* </label>
                <textarea
                    type="text"
                    className="rounded-md w-full h-20 p-2"
                    id="bioGraduate"
                    name='bioGraduate'
                    onChange={formik.handleChange}
                    value={formik.values.bioGraduate}
                    />
            </div>
            <div className="col-span-1 flex flex-col justify-between">
                <label className="w-full block mb-5">Upload a Professional Headshot*</label>
                <div>
                    <label for='headshotGraduate' className="rounded-md px-2 py-1 w-max-content bg-newBranding-beigeText text-white mr-2"><i className="fa fa-upload mr-2"></i>Choose a File</label> 
                    {headshotFileName === '' ? `No File Choosen` : headshotFileName}
                </div>
                <input className="hidden" id="headshotGraduate" name="headshotGraduate" type="file" accept="file_extension" onChange={e => {handleFileUpload(e, "headshotGraduate")}}/>
                {errorsValidation.headshotGraduate? <div className='text-red-500'>{errorsValidation.headshotGraduate}</div> : null}
                {fileTooLargeheadShot && <div className='text-red-500'>Please upload a file less than 10MB</div>}
            </div>
        </div>
          <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default GraduateStoryForm;