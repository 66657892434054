import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
import RegisterImage from './images/register1.png'
import RegisterSmall from './images/register-small.png'
import Logo from './images/LOGO-CTEDU-200px-01.svg'
import Google from './images/btn_google_signin_light_normal_web.png'
import GoogleHover from './images/google-hover.png'
import HeaderLogin from "./Components/HeaderLogin";
import {Helmet} from "react-helmet";


function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorAccountExist, setErrorAccountExist] = useState("")

  const register = () => {
    if(!name) {
      setErrorMessage(true)
    } else{
      registerWithEmailAndPassword(name, email, password).then(response => {
        if(response != true){
          setErrorAccountExist(response.message)
        }
      });
    }

  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/application-form");
  }, [user, loading]);

  return (
     
    <div className='relative text-newBranding-darkGrey'>
          <Helmet>
            <title>CTEDU Application Register</title>
            <meta name="description" content="Register to your application for Coach Training Edu." />
            <meta name="title" content="CTEDU Application Register" />
        </Helmet>
    <div className="h-screen bg-newBranding-jet flex items-center justify-center ">
      <HeaderLogin />
      <div className='flex mt-10 sm:max-h-80/100 flex-col sm:flex-row sm:w-4/5 text-newBranding-jet sm:m-16 w-330 lg:w-720 bg-newBranding-almostWhite sm:h-600 lg:h-420 rounded-xl shadow-cal'>
        <div className='bg-newBranding-beige sm:hidden w-330 flex flex-row justify-center rounded-t-lg '>
          <img src={RegisterSmall} alt="Logo CTEDU" className="w-2/3 p-2 lg:w-2/3 self-center"/>
        </div>
        <div className="rounded-lg p-3 flex flex-col justify-between	 space-y-2 sm:space-y-0 px-10  sm:w-5/6 ">
          <h1 className='text-3xl text-center font-bold'>Create Account</h1>
          <p className='text-md font-bold leading-5  sm:leading-6'>Thank you so much for applying to CTEDU’s coach training programs. It’s a big step! </p>
          <p className='text-newBranding-greyText text-sm'>The whole application takes about 10 to 30 minutes to complete, and registering gives you the opportunity to save your progress and come back to it if needed. </p>
          <div className="flex flex-col lg:flex-row">
            <button className="m-auto sm:ml-0 lg:m-auto login__google " onClick={signInWithGoogle}>
               <img onMouseOver={e => e.target.src = GoogleHover} onMouseOut={e => e.target.src = Google} classame='border block  self-center m-auto rounded-full border-newBranding-darkGrey w-1/3' src={Google}/>
            </button>
            
            <p className='opacity-50  text-newBranding-jet flex sm:hidden lg:flex m-auto text-center text-xs'>or use your email for registration</p>
            </div>
            <p className='opacity-50 text-newBranding-jet sm:flex hidden lg:hidden  text-left text-xs'>or use your email for registration</p>
            <div>
            
            <input
              type="text"
              className="bg-newBranding-beige w-full text-sm placeholder-newBranding-beigeText rounded h-7  pl-2 mb-2 "
              value={name}
              onChange={(e) => {setName(e.target.value)
                                setErrorMessage(false)}}
              placeholder="Full Name"
            />
             {errorMessage ? <div className='text-red-500'>Please enter your name.</div> : null}
            <input
              type="text"
              className="bg-newBranding-beige w-full placeholder-newBranding-beigeText text-sm rounded h-7  pl-2 mb-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <input
              type="password"
              className="bg-newBranding-beige w-full text-sm rounded h-7  pl-2 mb-2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          {errorAccountExist != '' ? <div className='text-red-500'>{errorAccountExist}</div> : null}
          <button className="w-max-content mx-auto duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1" onClick={register}>
          Register
        </button>
        <p className="text-center text-sm">Have already an account? <Link to='/' className="underline text-newBranding-blueLighter">Login here</Link></p>
        </div>
        <div className='bg-newBranding-beige hidden w-330 sm:flex flex-row justify-center rounded-r-lg '>
          <img src={RegisterImage} alt="Logo CTEDU" className="w-4/5 lg:w-2/3 self-center"/>
        </div>
      </div>
    </div>
    </div>

  
  );
}
export default Register;