import {useStripe, useElements, PaymentElement, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import {  writeUserData } from "../firebase"
import Money from '../images/Money.png'


 
import { useNavigate } from 'react-router-dom';


 
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
 
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [payment, setPayment] = useState(props.payment);
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod) 
  const [errorPaymentMethod, setErrorPaymentMethod] = useState(false)

  const navigate = useNavigate();

 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if(paymentMethod === ''){
      setErrorPaymentMethod(true)
      return
    }
 
    setIsLoading(true);
    setPaymentLoading(true)
   
   
    const v = await stripe.confirmSetup({
      elements,
      /*confirmParams: {
        // Make sure to change this to your payment completion page
       
        return_url: "http://localhost:3000/success",
      }*/
      redirect: 'if_required'
     
    })
    .then(function(result) {
      if (result.error){
        setPaymentLoading(false)
        setMessage(result.error.type +" : "+result.error.code)
      }else{
        const url =
        "https://us-central1-ctedu-lms.cloudfunctions.net/application"
        const data = {"email": props.email, 'type':'applicationSubmitted', "payment": paymentMethod}
        axios.post(url,  data, {
          headers: {
            'Content-Type': 'application/json'
          }})
          .then(() => {
            return writeUserData(props.email, {paymentMethod:paymentMethod, applicationSubmitted: true})
          })
          .then(() =>{
             navigate('/success', {
               state : {
                  date: props.date
               }
              })
            })
      }
       
    });

    setIsLoading(false);
  };



  const selectedPayment = 'border-2 border-newBranding-blueSelected text-newBranding-blueSelected'

  return (
    <div className='w-full sm:w-2/3'>
     <form onSubmit={handleSubmit}>
     <label className="mb-5">PAYMENT METHOD*
        <div className='flex flex-col sm:flex-row mt-5 mb-5 sm:items-center'>
           <img src={Money} className='w-40 mr-10 h-auto self-center mb-10'/>
           <div className='content-evenly flex flex-col '>
              <label className={`radio-item pl-2 mb-2 w-56 font-extrabold cursor-pointer bg-white py-1 rounded-full relative h-fit-content ${paymentMethod === payment[0] ? selectedPayment : 'border border-newBranding-blueSelected border-opacity-50'}`}>
                <input
                  id={payment[0]}
                  value={payment[0]}
                  checked={paymentMethod === payment[0]}
                  name="paymentMethod"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={(e) => {
                    setPaymentMethod(payment[0])
                    setErrorPaymentMethod(false)
                  }}
                />
                <p>{payment[0]}</p>
              </label>
              <label className={`radio-item pl-2 mt-2 font-extrabold cursor-pointer bg-white w-56 py-1 rounded-full relative ${paymentMethod === payment[1] ? selectedPayment : 'border border-newBranding-blueSelected border-opacity-50'}`}>
                <input
                  id={payment[1]}
                  value={payment[1]}
                  checked={paymentMethod === payment[1]}
                  name="paymentMethod"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={(e) => {
                    setPaymentMethod(payment[1])
                    setErrorPaymentMethod(false)
                  }}
                />
                 <p>{payment[1]}</p>
              </label>
              </div>
            </div>
     {/* <select
        id="paymentMethod"
        name="paymentMethod"
        className="block"
        value={formik.values.paymentMethod}
        onChange={(e) => {
          formik.handleChange(e);
          showNextField('coachContactQuestion')  
        }}>
          <option value="" disabled defaultValue>Please Select</option>
          {payment.map((method, i) => {
                 return(
                <option value={method} key={i}>{method}</option>
                 )
            }
            )}
          </select>*/}
      {errorPaymentMethod ? <div className='text-red-500'>This field is required.</div> : null}
      </label>
      <p className='mb-5'>Please use the credit card that you would like to use to pay your tuition, either in one payment or in 12 monthly installments. Nothing will be charged to your card until your application has been reviewed and a decision made. </p>

      <PaymentElement />
      {paymentLoading ?
      <div class=" flex justify-center items-center">
        Processing
      <div class=" ml-5 animate-spin rounded-full h-10 w-10 border-b-4 border-l-4 border-t-4 border-newBranding-blue"></div>
    </div> : ''  
      }
      <div className='flex flex-row justify-between mb-10'>
        <button className='mt-10 text-sm' onClick={() => props.handleBack('')}><span className='mr-2'>&#10094;</span>PREVIOUS STEP</button>
        <button className=' mt-10' disabled={!stripe && paymentLoading}><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Submit</span><span className='opacity-75 ml-2'>&#10095;</span></button>
      </div>
  </form>
  {(message !== "") ? <div className='text-red-500'>{message}</div> : null}
</div>
  )
};
 
export default CheckoutForm;
