
import React, { useEffect, useState } from "react";

import {Helmet} from "react-helmet";
import HeaderLogin from "./Components/HeaderLogin";
import Image from "./images/thank-you-gif.gif"
 
function ThankYou() {
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12 min-h-screen bg-newBranding-beige">
      <Helmet>
            <title>Thank You!</title>
            <meta name="description" content="Thank you" />
            <meta name="title" content="Thank You for submitting a form" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10 ">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-10 order-2 lg:order-1">
              <h1 className="text-4xl sm:text-5xl mb-5 pt-10">Thanks! We got it!</h1>
              <p className="mb-10">We will review/process your submission within two business days. If you have additional questions or need additional assistance, please don’t hesitate to reach out to your <a className="underline" href='https://www.coachtrainingedu.com/enrollment-team/'>enrollment advisor</a> or email us at <a href="mailto: support@coachtrainingedu.com">support@coachtrainingedu.com</a>.</p>
            </div>
            <div className="lg:w-4/5 m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
    </div>
  );
}
export default ThankYou;