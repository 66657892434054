import React, { useEffect, useState } from "react";
 
import { useFormik } from "formik"
import {useRef} from 'react';
import Cactus from "../images/cactus.png"
import Plane from "../images/plane.png"
import Paper from "../images/paper.png"
 
function ContactInformation(props) {
 
  let btnRef = useRef();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [coachContact, setCoachContact] = useState("");
  const [contactedCoachName, setContactedCoachName] = useState("");
  const [initialFirstname, setInitialFirstname] = useState("");
  const [initialLastname, setIitialLastname] = useState("");
  const [initialPhone, setInitialPhone] = useState("");
  const [initialStreet, setInitialStreet] = useState("");
  const [initialCity, setInitialCity] = useState("");
  const [initialState, setInitialState] = useState("");
  const [initialZip, setInitialZip] = useState("");
  const [initialCountry, setInitialCountry] = useState("");
  const [initialCoachContact, setInitialCoachContact] = useState("");
  const [initialContactedCoachName, setInitialContactedCoachName] = useState("");
  const [errorsValidation, setErrorsValidation] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  console.log('props ', props)
 
  useEffect(() => {
    props.getValue('firstName').then((result) => {
      if(result === ''){
        if(props.fetchedData.name && props.fetchedData.name != ''){
         setFirstname(props.fetchedData.name.split(' ')[0])
         setInitialFirstname(props.fetchedData.name.split(' ')[0])
        }
      } else {
           setFirstname(result)
           setInitialFirstname(result)
      }
    })
    props.getValue('lastName').then((result) => {
      if(result === ''){
        if(props.fetchedData.name && props.fetchedData.name != ''){
          if(props.fetchedData.name.split(' ').length <= 1){
            setLastname('')
            setIitialLastname('')
          }else{
            let tempName = props.fetchedData.name.split(' ').slice(1).join(' ')
            setLastname(tempName)
            setIitialLastname(tempName)
          }
        }
      } else {
        setLastname(result)
        setIitialLastname(result)
      }
    })
    props.getValue('phone').then((result) => {
      setPhone(result)
      setInitialPhone(result)
    })
    props.getValue('street').then((result) => {
      setStreet(result)
      setInitialStreet(result)
    })
    props.getValue('city').then((result) => {
      setCity(result)
      setInitialCity(result)
    })
    props.getValue('state').then((result) => {
      setState(result)
      setInitialState(result)
    })
    props.getValue('zip').then((result) => {
      setZip(result)
      setInitialZip(result)
    })
    props.getValue('country').then((result) => {
      setCountry(result)
      setInitialCountry(result)
    })
    props.getValue('email').then((result) => {
      if(result === ''){
        if(props.fetchedData.email && props.fetchedData.email != ''){
          setEmail(props.fetchedData.email)
        }
      } else {
      setEmail(result)
      }
    })
    props.getValue('coachContact').then((result) => {
      setCoachContact(result)
      setInitialCoachContact(result)
    })
    props.getValue('contactedCoachName').then((result) => {
      setContactedCoachName(result)
      setInitialContactedCoachName(result)
    })
  }, [props.fetchedData]);
 

  console.log('here')

  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'coachContact']
    if(coachContact === 'yes'){
      required.push('contactedCoachName')
    }
    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setCoachContact(values.coachContact)
      setContactedCoachName(values.contactedCoachName)
 
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'coachContact']
    if(coachContact === 'yes'){
      required.push('contactedCoachName')
    }

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };

  console.log('is loading', isLoading)



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      coachContact:coachContact,
      contactedCoachName:contactedCoachName,
      step: 1
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
         if (data.contactedCoachName === undefined) {
          data.contactedCoachName = ''
        }

        let dataChanged = false;
        if((data.firstName !== initialFirstname) 
        || (data.lastName !==  initialLastname) 
        || (data.phone !== initialPhone) 
        || (data.street !== initialStreet) 
        || (data.zip !== initialZip)
        || (data.state !== initialState)
        || (data.city != initialCity) 
        || (data.country !== initialCountry)
        || (data.coachContact !== initialCoachContact)
        || (data.contactedCoachName !== initialContactedCoachName)){
          dataChanged = true
        }
        props.onSubmit(data, dataChanged)
       }
       setErrorsValidation(errors)
       return errorsValidation
    },
  })

  return (
    <div className="flex justify-center items-center h-screen w-full  bg-newBranding-beige">
      hello
    </div> 

    )

  if(isLoading){
    return (
    <div className="flex justify-center items-center h-screen w-full  bg-newBranding-beige">
       <div className="animate-spin rounded-full h-10 w-10 md:ml-44 border-b-4 border-l-4 border-t-4 border-newBranding-blue"></div>
    </div> 

    )
  }else{
 
 
  return (
    <div >
      <div className="w-full ">
        <h2  className="text-center md:text-left font-bold">Where would you like to receive your training notifications?</h2>
        <form onSubmit={formik.handleSubmit} className=" mb-10 flex flex-col mt-5 w-full ">
        <div className='flex flex-col lg:flex-row space-between'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> First Name
          <input
              type="text"
              className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
              id="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              />
              {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>
          <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Last Name
          <input
              type="text"
              className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
              id="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              />
              {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
          </label>
        </div>
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Email
        <input
            type="email"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            readOnly="readOnly"
            />
            {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Phone
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            />
            {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
        </label>
        </div>
        <p className=' mt-5 mb-5 text-center md:text-left font-bold'>Where would you like to receive your textbooks?</p>
        <label className="mb-5 w-full text-newBranding-darkGrey opacity-75"> Street Address
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="street"
            onChange={formik.handleChange}
            value={formik.values.street}
            />
            {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
        </label>
 
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> City
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            />
            {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> State
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            />
            {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
        </label>
        </div>
 
        <div className='flex flex-col lg:flex-row'>
        <label className="mb-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> ZIP code
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="zip"
            onChange={formik.handleChange}
            value={formik.values.zip}
            />
            {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
        </label>
        <label className="mb-5 ml-0 lg:ml-5 w-full lg:w-1/2 text-newBranding-darkGrey opacity-75"> Country
        <input
            type="text"
            className="block border border-newBranding-blue w-full rounded-md h-9 mt-2 pl-2 border-opacity-50"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            />
            {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
        </label>
        </div>

        <label className='block mb-5 mt-5 text-center md:text-left'>Have you spoken with someone at Coach Training Edu?
      <div className='mt-5'>
      <label className="radio-item relative">
        <input
          id="yes"
          value="yes"
          className='opacity-0'
          checked={formik.values.coachContact === 'yes'}
          name="coachContact"
          type="radio"
          onChange={e => {
            formik.handleChange(e)
          }}
        />
         {formik.values.coachContact === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
        <label htmlFor="yes" className="ml-5 text-base">Yes</label>
      </label>

      <label className="radio-item relative ml-5">
        <input
          id="no"
          value='no'
          className='opacity-0'
          checked={formik.values.coachContact === 'no'}
          name="coachContact"
          type="radio"
          onChange={e => {
            formik.handleChange(e)
          }}
        />
        {formik.values.coachContact === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
        <label htmlFor="no" className='ml-5 text-base'>No, I still need to speak with someone.</label>
      </label>
      {errorsValidation.coachContact ? <div className='text-red-500'>{errorsValidation.coachContact}</div> : null}
      </div>
      </label>
    { formik.values.coachContact === "yes" && <label className='text-center md:text-left'> Who have you spoken with?*
      <input
        type="text"
        className="block  border-opacity-50 border border-newBranding-blue rounded-md h-9 mt-2 pl-2 w-full "
        id="contactedCoachName"
        onChange={formik.handleChange}
        value={formik.values.contactedCoachName}
        />
        {errorsValidation.contactedCoachName ? <div className='text-red-500'>{errorsValidation.contactedCoachName}</div> : null}
      </label>}
      {formik.values.coachContact === "no" && <div className='flex flex-col  items-start '>
        <p className='text-sm lg:ml-20'>No problem. You're welcome to fill out an application and someone from our team will reach out soon.</p>
        <a href="https://meetings.hubspot.com/andrew-hock/andrew-hock" target="_blank" className="h-fit-content lg:ml-20 m-auto md:ml-0 w-max-content duration-300 mt-3 transition z-10 no-underline bg-white hover:bg-newBranding-jet hover:text-white text-newBranding-jet border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1">Schedule a 20 Minute Call</a>
       </div>
      }
       
          <button ref={btnRef} type="submit" className=' mt-10 self-end'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
      </form>
      </div>
    </div>
  );
}
}
export default ContactInformation;
