import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const axios = require('axios');
const md5 = require("md5");


const firebaseConfig = {
    apiKey: "AIzaSyBde25mglyvBfgAnETo_LpowchGWftOIUs",
    authDomain: "ctedu-lms.firebaseapp.com",
    projectId: "ctedu-lms",
    storageBucket: "ctedu-lms.appspot.com",
    messagingSenderId: "963691398198",
    appId: "1:963691398198:web:1b62c198c38aace0184497",
    measurementId: "G-RB8K578258"
  };

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();


const signInWithGoogle = async () => {
    try {
      const res = await auth.signInWithPopup(googleProvider);
      const user = res.user;
      const query = await db
        .collection("users")
        .where("uid", "==", user.uid)
        .get();
      if (query.docs.length === 0) {
        await db.collection("users").add({
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          firstLogin: true,
          applicationSubmitted: false,
          applicationStarted:false
        });
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const signInWithEmailAndPassword = async (email, password) => {
    try {
      email = email.toLowerCase()
      await auth.signInWithEmailAndPassword(email, password);
      return true
    } catch (err) {
      console.error(err);
      return err
    }
    return
  };

  const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      email = email.toLowerCase()
      const res = await auth.createUserWithEmailAndPassword(email, password);
      const user = res.user;
      await db.collection("users").add({
        uid: user.uid,
        name,
        authProvider: "local",
        email,
        firstLogin: true,
        applicationSubmitted: false,
        applicationStarted:false
      });
      return true
    } catch (err) {
      console.error(err);
      return err
    }
  };


  const sendPasswordResetEmail = async (email) => {

    try {
       await auth.sendPasswordResetEmail(email);
      return true
    } catch (err) {
      console.error(err);
      return err
    }
    return
  };

  const writeUserData = async (email, values, dataChanged) => {
     let data = db.collection("users").where("email", "==", email).limit(1).get().then(query => {
        const thing = query.docs[0];

        let tmp = thing.data();
        for (const key in values) {
          tmp[key] = values[key];
        }

        thing.ref.update(tmp);
        tmp.dataChanged = dataChanged
        return tmp
      });
      return data
  }

  const getValueFromDb = async(email, key) =>{

    const value = await db.collection("users").where("email", "==", email).limit(1).get().then(query => {
      const thing = query.docs[0];
      if(thing === undefined){
        return
      }
      let tmp = thing.data();
     
      if(tmp[key] === undefined){
        return ''
      }
      else{
        return tmp[key]
      }
     // thing.ref.update(tmp);
    });
    return value
  }

  const getValueFromDbUsingUid = async (uid) =>{

    const value = await db.collection("users").where("uid", "==", uid).limit(1).get().then(query => {
      const thing = query.docs[0];
      if(thing === undefined){
        return
      }
      let tmp = thing.data();
      return tmp
 });
    return value
  }

  const logout = () => {
    auth.signOut();
  };


  function AddTagToMember( email, url, tagType) {
    const api_key = '441185165ce2b267dbe319a29b078a9a-us1';

    const subscriberHash = md5(email.toLowerCase());
    url = url +"/"+ subscriberHash + "/tags"

        var data = {}
        data["tags"] = []

        for (var x = 0; x < tagType.length; x++) {
            var tagObject = {}
            tagObject["name"] = tagType[x]
            tagObject["status"] = "active"
            data["tags"].push(tagObject)
        }

        var config = {
        method: 'post',
        url: url,
        headers: { 
            'Authorization': 'Basic '+api_key, 
            'Content-Type': 'text/plain', 
        },
        data : data
        };

        axios(config)
        .then(function (response) {
        console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
           console.log(error);
        });
 
}


/*function handleMailchimpEnrollment(email, type){
  console.log('email', email)
    const url = "https://us1.api.mailchimp.com/3.0/lists/35f346079f/members"
    const api_key = '441185165ce2b267dbe319a29b078a9a-us1';
    var data = { "email" : email,    "status":"subscribed",  };
  console.log('data', data)
    var config = {
        method: 'post',
        url: url,
        headers: { 
            'Authorization': 'Basic '+ api_key, 
            'Content-Type': 'text/plain', 
        },
        data : data
    };
    axios(config)
        .then(function (response) {
            AddTagToMember(data.email, url, type)
        })
        .catch(function (error) {
          console.log('error ', error)
            if(error.response.data.title === "Member Exists"){
                return AddTagToMember( data.email, url, type)
            }else{
                console.log(error)
            }
    });


}*/
  

  export {
    auth,
    db,
    signInWithGoogle,
    signInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    logout,
    writeUserData,
    getValueFromDb,
    getValueFromDbUsingUid
       // handleMailchimpEnrollment,
  };