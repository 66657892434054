
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout, writeUserData, getValueFromDb } from "./firebase";
import HeaderLogin from "./Components/HeaderLogin";
import SuccessImg from './images/success-gif.gif'
import Sidebar from "./Components/Sidebar";
import {Helmet} from "react-helmet";
import SidebarAdvanced from "./Components/SidebarAdvanced";



function SuccessAdvanced() {
    
  return (
     <div className="flex text-newBranding-darkGrey mt-12">
       <Helmet>
            <title>Enrollment completed</title>
            <meta name="description" content="CTEDU application is completed." />
            <meta name="title" content="Application completed" />
        </Helmet>
        <HeaderLogin/>

        <div className=" max-w-1220 mx-auto flex flex-row md:flex-col w-full">

        <SidebarAdvanced activeStep={2} />
        <div className="ml-5 text-center md:text-left md:ml-14 mr-5 pt-28 md:pt-0 lg:mr-10 md:pl-5 lg:pl-10 pt-5 md:ml-80 h-full bg-newBranding-beige  min-h-screen">
        
            <h2 className='text-3xl font-bold mt-26 md:mt-10 mb-10 text-center lg:text-left'>Thank you!</h2>
            <img src={SuccessImg} alt='Thank you image' className='w-3/5 m-auto md:ml-0 md:w-1/3 max-w-400 mb-10'/>
            <p className="font-bold mb-2">Thank you for submitting your enrollment form!</p>
            <p className='mb-10'> Our enrollment team will be in touch in the next 48 hours with next steps for beginning your course. If you have any additional questions about the course for which you enrolled, please don't hesitate to reach out to your enrollment advisor.</p>
            <div className="flex items-center md:justify-around lg:justify-start md:items-start flex-col md:flex-row">
              <a href='tel:+15039940914' className='lg:mr-10 mb-5 w-max-content duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1'>(1) 503-360-6700</a>
              <a href="https://meetings.hubspot.com/andrew-hock/andrew-hock" target="_blank" className="lg:mr-10 w-max-content duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-4 lg:px-11 py-1 lg:py-1.5 m-1">Schedule a 20 Minute Call</a>
       </div>
       </div>
       </div>
    </div>
  );
}
export default SuccessAdvanced;