
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image from "../images/form-letter.png"


 
function LetterOfCompletion() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [training_program_begin_date_completion, setTraining_program_begin_date_completion] =  useState("");
    const [trainingCompleted_completion, setTrainingCompleted_completion] =  useState("");
    const [leadTrainer_completion, setLeadTrainer_completion] =  useState("");
    const [feedback_completion, setFeedback_completion] =  useState("");
    const [comments_completion, setComments_completion] =  useState("");
    const [absence_completion, setAbsence_completion] = useState("");
    const [headshot, setHeadshot] = useState("");
    const [headshotFileName, setHeadshotFileName] = useState("");
    

    
    const [errorsValidation, setErrorsValidation] = useState({})



  
  

 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country',  'leadTrainer_completion', 'trainingCompleted_completion', 'training_program_begin_date_completion']

   

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setState(values.state)
      setStreet(values.street)
      setCity(values.city)
      setZip(values.zip)
      setCountry(values.country)
      setLeadTrainer_completion(values.leadTrainer_completion)
      setTraining_program_begin_date_completion(values.training_program_begin_date_completion)
      setTrainingCompleted_completion(values.trainingCompleted_completion)
      setFeedback_completion(values.feedback_completion)
      setComments_completion(values.comments_completion)
      setAbsence_completion(values.absence_completion)

     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'street', 'city', 'zip', 'country', 'leadTrainer_completion', 'trainingCompleted_completion', 'training_program_begin_date_completion']



    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      training_program_begin_date_completion: training_program_begin_date_completion,
      leadTrainer_completion:leadTrainer_completion,
      trainingCompleted_completion:trainingCompleted_completion,
      feedback_completion:feedback_completion,
      comments_completion:comments_completion,
      absence_completion:absence_completion,
      headshot:headshot
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values 
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      let formData = new FormData();

      formData.append("fileHeadshot", data.headshot);
      formData.append("email_address", data.email);
      formData.append("type", "letter-of-completion");
      formData.append("name", data.firstName + ' ' + data.lastName);
      formData.append("trainingCompleted", data.trainingCompleted_completion);
      formData.append("trainingBegins", data.training_program_begin_date_completion);
      formData.append("lead", data.leadTrainer_completion);
      formData.append("absence", data.absence_completion);
      formData.append("comments", data.comments_completion);
      formData.append("feedback", data.feedback_completion);

      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(() =>{
           navigate('/thank-you')
        })

       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })

  const handleFileUpload = (event, fileType) => {

      if(fileType === 'headshot'){
        setHeadshot(event.currentTarget.files[0])
        setHeadshotFileName(event.currentTarget.files[0].name)
      }
    }


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Letter of Completion</title>
            <meta name="description" content="Request your letter of completion" />
            <meta name="title" content="CTEDU Letter of Completion Request" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1  className="text-4xl sm:text-5xl mb-5">Congratulations!</h1>
              <p className="font-bold text-xl">Letter of Completion with CTEDU</p>
              <p>A coach will receive a letter of completion upon graduation from the 1.0 Coach Training Program.</p>
              <p>To earn your letter of completion for the completion of your 1.0 or 2.0 coach training course, please submit the following form.</p>
              <p>If any makeup sessions for a class have been completed, please make a note in the attendance section below.</p>
              <p>If you have any questions about the requirements or the application process, please email support@coachtrainingedu.com.</p>
            </div>
            <div className=" m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='letter-of-completion' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col  w-full ">
        <p className="mb-10 text-center lg:text-left">Please fill out your <span className="font-bold">Information*</span></p>
        <div className="grid-cols-2 max-w-500 m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 border-b border-newBranding-beigeText pb-10">
        <label className="col-span-2"> First Name
          <input
              type="text"
              className="rounded-md w-full  h-10 p-2"
              id="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              />
              {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>
          <label className="col-span-2"> Last Name
          <input
              type="text"
              className="rounded-md w-full h-10 p-2"
              id="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              />
              {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
          </label>
        <label className="col-span-2"> Email
        <input
            type="email"
            className="rounded-md w-full h-10 p-2"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            />
            {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
        </label>
        <label className="col-span-2"> Phone
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            />
            {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
        </label>
       
        <label className=" w-full col-span-2 lg:col-span-4"> Street Address
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="street"
            onChange={formik.handleChange}
            value={formik.values.street}
            />
            {errorsValidation.street ? <div className='text-red-500'>{errorsValidation.street}</div> : null}
        </label>
 
        <label className="col-span-2"> City
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            />
            {errorsValidation.city ? <div className='text-red-500'>{errorsValidation.city}</div> : null}
        </label>
        <label className="col-span-1"> State
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            />
            {errorsValidation.state ? <div className='text-red-500'>{errorsValidation.state}</div> : null}
        </label>
 
        <label className=" w-full col-span-1"> ZIP code
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="zip"
            name='zip'
            onChange={formik.handleChange}
            value={formik.values.zip}
            />
            {errorsValidation.zip ? <div className='text-red-500'>{errorsValidation.zip}</div> : null}
        </label>
        <label className="w-full col-span-2"> Country
        <input
            type="text"
            className="rounded-md w-full h-10 p-2"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
            />
            {errorsValidation.country ? <div className='text-red-500'>{errorsValidation.country}</div> : null}
        </label>
        </div>
        <p className="mt-10 text-center mb-5 lg:text-left">Please fill out your <span className="font-bold">Course Completion*</span></p>
        <div className="grid grid-cols-1 max-w-500 lg:max-w-none m-auto lg:grid-cols-3 pt-3 gap-y-14 gap-x-5">

          <div className="col-span-1 flex flex-col justify-between">
            <label>Please select the training program you completed.*</label>
                <select
                  id="trainingCompleted_completion"
                  name="trainingCompleted_completion"
                  className='h-10 rounded-md w-full '
                  value={formik.values.trainingCompleted_completion}
                  onChange={(e) => {
                      formik.handleChange(e);
                  }}>
                    <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
                    <option>1.0 Wellness Life Coach Training</option>
                    <option>1.0 Academic Life Coach Training</option>
                    <option>1.0 Executive Life Coach Training</option>
                    <option>1.0 Essential Life Coach Training</option>
            </select>
            {errorsValidation.trainingCompleted_completion ? <div className='text-red-500'>{errorsValidation.trainingCompleted_completion}</div> : null}
          </div>

          <div className="col-span-1 flex flex-col justify-between">
            <label>When did your training program begin?*</label>
            <input type='training_program_begin_date_completion'
              className="h-10 rounded-md w-full p-2"
              value={formik.values.training_program_begin_date_completion}
              onChange={formik.handleChange}
              name='training_program_begin_date_completion'
              max={current}
            />
            {errorsValidation.training_program_begin_date_completion ? <div className='text-red-500'>{errorsValidation.training_program_begin_date_completion}</div> : null}
          </div>

          <div className="col-span-1 flex flex-col justify-between">
            <label>Who was the lead trainer of your course?* </label>
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="leadTrainer_completion"
                name='leadTrainer_completion'
                onChange={formik.handleChange}
                value={formik.values.leadTrainer}
                />
                {errorsValidation.leadTrainer_completion ? <div className='text-red-500'>{errorsValidation.leadTrainer_completion}</div> : null}
          </div>

          <div className="col-span-1 flex flex-col justify-between">
            <label>If you had a <span className="font-bold">makeup session for an absence</span>, please note the date completed below.</label>
            <textarea
                type="text"
                className="rounded-md w-full h-14 p-2"
                id="absence_completion"
                name='absence_completion'
                onChange={formik.handleChange}
                value={formik.values.absence_completion}
                />
          </div>
          
          <div className="col-span-1 flex flex-col justify-between">
            <label>Additional comments or suggestions:</label>
            <textarea
                type="text"
                className="rounded-md w-full h-14 p-2"
                id="comments_completion"
                name='comments_completion'
                onChange={formik.handleChange}
                value={formik.values.comments}
                />
          </div>
          
          <div className="col-span-1 flex flex-col justify-between">
            <label className="w-full block mb-5">We would love to <span className="font-bold">feature you on our social media!</span><br/><span className="text-sm">Update your headshot below.</span></label>
            <div>
              <label for='headshot' className="lg:mt-5 rounded-md px-2 py-1 w-max-content bg-newBranding-beigeText text-white mr-2"><i className="fa fa-upload mr-2"></i>Choose a File</label> 
              {headshotFileName === '' ? `No File Choosen` : headshotFileName}
            </div>
            <input className="lg:mt-5 hidden" id="headshot" name="headshot" type="file" accept="file_extension" onChange={e => {handleFileUpload(e, "headshot")}}/>
          </div>


          <label className='w-full'>May Coach Training EDU <span className="font-bold">use your name and feedback as testimonial on our website and/or marketing materials?</span>
            <div className='mt-5'>
              <label className="radio-item relative">
                  <input
                  id="yes"
                  value="yes"
                  className='opacity-0'
                  checked={formik.values.feedback === 'yes'}
                  name="feedback_completion"
                  type="radio"
                  onChange={e => {
                      formik.handleChange(e)
                  }}
                  />
                  {formik.values.feedback_completion === 'yes' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                  <label htmlFor="yes" className="ml-5 text-base">Yes</label>
              </label>
              <label className="radio-item relative ml-5">
                  <input
                  id="no"
                  value='no'
                  className='opacity-0'
                  checked={formik.values.feedback === 'no'}
                  name="feedback_completion"
                  type="radio"
                  onChange={e => {
                      formik.handleChange(e)
                  }}
                  />
                  {formik.values.feedback_completion === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                  <label htmlFor="no" className="ml-5 text-base">No</label>
              </label>

              <label className="radio-item relative mt-5 sm:mt-0 sm:ml-5 block sm:inline">
                  <input
                  id="noName"
                  value='noName'
                  className='opacity-0'
                  checked={formik.values.feedback === 'noName'}
                  name="feedback_completion"
                  type="radio"
                  onChange={e => {
                      formik.handleChange(e)
                  }}
                  />
                  {formik.values.feedback_completion === 'noName' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                  <label htmlFor="noName" className="ml-5 text-base">Yes, but please do not include my name</label>
              </label>
            </div>
          </label>
          
        </div>
        <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default LetterOfCompletion;