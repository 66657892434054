
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image from "../images/form-feedback.png"




//import  response  from 'express';

 
 
function FeedbackProgrammaticTrainers() {
    const current = new Date().toISOString().split("T")[0]
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [for_which_program_are_you_offering_feedback_, setFor_which_program_are_you_offering_feedback_] = useState("");
    const [feedback_about_the_live_training_sessions, setFeedback_about_the_live_training_sessions] = useState("");
    const [feedback_about_the_coach_portal, setFeedback_about_the_coach_portal] = useState("");
    const [feedback_about_the_course_materials, setFeedback_about_the_course_materials] = useState("");
    const [trainers_names, setTrainers_names] = useState("");
    const [feedback_about_the_trainers, setFeedback_about_the_trainers] = useState("");
    const [feedback_about_the_overview_process, setFeedback_about_the_overview_process] = useState("");
    const [coach_mentor_name, setCoach_mentor_name] = useState("");
    const [feedback_for_the_coach_mentor, setFeedback_for_the_coach_mentor] = useState("");
    const [anything_else_to_share, setAnything_else_to_share] = useState("");
    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['trainers_names', 'coach_mentor_name']
   

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });

      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setFeedback_about_the_live_training_sessions(values.feedback_about_the_live_training_sessions)
      setFor_which_program_are_you_offering_feedback_(values.for_which_program_are_you_offering_feedback_)
      setAnything_else_to_share(values.anything_else_to_share)
      setFeedback_about_the_coach_portal(values.feedback_about_the_coach_portal)
      setFeedback_about_the_course_materials(values.feedback_about_the_course_materials)
      setTrainers_names(values.trainers_names)
      setFeedback_about_the_trainers(values.feedback_about_the_trainers)
      setFeedback_about_the_overview_process(values.feedback_about_the_overview_process)
      setCoach_mentor_name(values.coach_mentor_name)
      setFeedback_for_the_coach_mentor(values.feedback_for_the_coach_mentor)
     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['trainers_names', 'coach_mentor_name']


    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      feedback_about_the_live_training_sessions:feedback_about_the_live_training_sessions,
      for_which_program_are_you_offering_feedback_:for_which_program_are_you_offering_feedback_,
      feedback_about_the_coach_portal:feedback_about_the_coach_portal,
      feedback_about_the_course_materials:feedback_about_the_course_materials,
      trainers_names:trainers_names,
      feedback_about_the_trainers:feedback_about_the_trainers,
      feedback_about_the_overview_process:feedback_about_the_overview_process,
      coach_mentor_name:coach_mentor_name,
      feedback_for_the_coach_mentor:feedback_for_the_coach_mentor,
      anything_else_to_share:anything_else_to_share
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'feedback-programmatic-trainers'}
      if(data.email === ''){
        data.email = 'anonymous'+Date.now()+'@gmail.com'
      }
      console.log('data email ', data.email)
      axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/thank-you')
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12 min-h-screen bg-newBranding-beige">
      <Helmet>
            <title>Feedback Programmatic Trainers</title>
            <meta name="description" content="Feedback Programmatic Trainers" />
            <meta name="title" content="CTEDU Feedback Programmatic Trainers" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10 ">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1  className="text-4xl sm:text-5xl mb-5">We want to learn about your experience!</h1>
              <p>We know that seeking and being open to feedback is a cornerstone to Hope Theory, which is one of the foundational theories of our program. </p>
              <p>To that end, we are eager to learn about your personal experience with our program. We invite you to share in an open and honest way to the degree that you are comfortable and we are deeply grateful for the time you are offering to this process.</p>
            </div>
            <div className="lg:w-4/5 m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='feedback-programmatic-trainers' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col  w-full ">
        <label for="cc-num" class="hidden">credit card HubspotCollectedFormsWorkaround https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102</label>
        <input name="cc-num" class="hidden" required="" value="HubspotCollectedFormsWorkaround" id="cc-num" />
        <div className="grid-cols-1 w-full max-w-500 m-auto lg:max-w-none lg:grid-cols-3 grid gap-y-10 gap-x-5 border-b border-newBranding-beigeText pb-10">

        <div className="col-span-1 flex flex-col justify-between">
          <label>First Name <br/><span className="text-xs">If you would like to remain anonymous, please leave this field blank.</span></label>
            <input
                type="text"
                className="rounded-md w-full  h-10 p-2"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
          </div>

          <div className="col-span-1 flex flex-col justify-between">
            <label>Last Name<br/><span className="text-xs">If you would like to remain anonymous, please leave this field blank.</span></label>
            <input
                type="text"
                className="rounded-md w-full h-10 p-2"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
          </div>

        <div className="col-span-1 flex flex-col justify-between">
          <label> Email<br/><span className="text-xs">If you would like a copy of your responses or are open to discussing your responses further, please enter your email. If you would like to remain anonymous, please leave this field blank.</span></label>
          <input
              type="email"
              className={`rounded-md w-full h-10 p-2`}
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              />
        </div>
      

        <label className='col-span-1 lg:col-span-3'>For which <span className="font-bold">program are you offering feedback?*</span><br/>
          <div className='mt-5 flex flex-col lg:flex-row'>
            <label className="radio-item relative">
                <input
                id="academic"
                value="academic"
                className='opacity-0'
                checked={formik.values.for_which_program_are_you_offering_feedback_ === 'academic'}
                name="for_which_program_are_you_offering_feedback_"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.for_which_program_are_you_offering_feedback_ === 'academic' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="academic" className="ml-5 text-base ">1.0 Academic Life Coach Training</label>
            </label>

            <label className="radio-item relative lg:ml-5 mt-2 lg:mt-0">
                <input
                id="executive"
                value='executive'
                className='opacity-0'
                checked={formik.values.for_which_program_are_you_offering_feedback_ === 'executive'}
                name="for_which_program_are_you_offering_feedback_"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.for_which_program_are_you_offering_feedback_ === 'executive' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="executive" className="ml-5 text-base">1.0 Executive Coach Training</label>
            </label>

            <label className="radio-item relative lg:ml-5 mt-2 lg:mt-0">
                <input
                id="wellness"
                value='wellness'
                className='opacity-0'
                checked={formik.values.pfor_which_program_are_you_offering_feedback_ === 'wellness'}
                name="for_which_program_are_you_offering_feedback_"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.for_which_program_are_you_offering_feedback_ === 'wellness' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="wellness" className="ml-5 text-base ">1.0 Wellness Life Coach Training</label>
            </label>

            <label className="radio-item relative lg:ml-5 mt-2 lg:mt-0">
                <input
                id="advanced"
                value='advanced'
                className='opacity-0'
                checked={formik.values.for_which_program_are_you_offering_feedback_ === 'advanced'}
                name="for_which_program_are_you_offering_feedback_"
                type="radio"
                onChange={e => {
                    formik.handleChange(e)
                }}
                />
                {formik.values.for_which_program_are_you_offering_feedback_ === 'advanced' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                <label htmlFor="advanced" className="ml-5 text-base ">2.0 Advanced Life Coach Training</label>
            </label>

          </div>
        </label>

        </div>
        <p className="mt-10 mb-5 lg:text-left text-center font-bold text-lg">Feedback</p>

        
        <div className="grid grid-cols-1 max-w-500 lg:max-w-none m-auto lg:grid-cols-3 pt-3 gap-y-20 gap-x-5">
            
            <div className="col-span-1 flex flex-col justify-between">
              <label>What feedback do you have about the <span className="font-bold">COACH PORTAL?</span><br/><span className="text-xs">This is the site where you access your course materials, schedule your overviews, and engage with the larger community.</span></label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_about_the_coach_portall"
                  name='feedback_about_the_coach_portal'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_about_the_coach_portal}
                  />
            </div>

            <div className="col-span-1 flex flex-col justify-between">
              <label>What feedback do you have about the <span className="font-bold">COURSE MATERIALS?</span><br/><span className="text-xs">This includes the text, workbook, audio files, videos, and digital bonuses.</span></label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_about_the_course_materials"
                  name='feedback_about_the_course_materials'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_about_the_course_materials}
                  />
            </div>

            <div className="col-span-1 flex flex-col justify-between">
              <label>What feedback do you have about the <span className="font-bold">LIVE TRAINING SESSIONS?</span><br/><span className="text-xs">This is the two hour window you spend with your classmates and trainer discussing and learning new material.</span></label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_about_the_live_training_sessions"
                  name='feedback_about_the_live_training_sessions'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_about_the_live_training_sessions}
                  />
            </div>

            <div className="col-span-1 flex flex-col justify-between lg:pb-16">
              <label>Please list both of <span className="font-bold">YOUR TRAINERS' NAMES.*</span></label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-16 p-2"
                  id="trainers_names"
                  name='trainers_names'
                  onChange={formik.handleChange}
                  value={formik.values.trainers_names}
                  />
               {errorsValidation.trainers_names ? <div className='text-red-500'>{errorsValidation.trainers_names}</div> : null}
            </div>
            
            <div className="col-span-1 flex flex-col justify-between">
              <label className=" w-full">What feedback do you have for your <span className="font-bold"> TRAINERS?</span><br/><span className="text-xs">Please be specific and identify each trainer you are offering feedback for.</span> </label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_about_the_trainers"
                  name='feedback_about_the_trainers'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_about_the_trainers}
                  />
            </div>
           
            <div className="col-span-1 flex flex-col justify-between">
              <label className=" w-full">What feedback do you have about the <span className="font-bold">  OVERVIEW PROCESS?</span><br/><span className="text-xs">This is for the process itself. You will have an opportunity to provide feedback about your specific Coach Mentor later in this survey.</span> </label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_about_the_overview_process"
                  name='feedback_about_the_overview_process'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_about_the_overview_process}
                  />
            </div>

            <div className="col-span-1 flex flex-col justify-between lg:pb-16">
              <label className=" w-full">Please list your <span className="font-bold"> COACH MENTOR'S NAME.*</span></label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-16 p-2"
                  id="coach_mentor_name"
                  name='coach_mentor_name'
                  onChange={formik.handleChange}
                  value={formik.values.coach_mentor_name}
                  />
              {errorsValidation.coach_mentor_name ? <div className='text-red-500'>{errorsValidation.coach_mentor_name}</div> : null}
            </div>
            
            <div className="col-span-1 flex flex-col justify-between">
              <label className=" w-full">What feedback do you have for your <span className="font-bold">COACH MENTOR?</span> </label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="feedback_for_the_coach_mentor"
                  name='feedback_for_the_coach_mentor'
                  onChange={formik.handleChange}
                  value={formik.values.feedback_for_the_coach_mentor}
                  />
            </div>
           
            <div className="col-span-1 flex flex-col justify-between">
              <label className=" w-full">What else would you like to share? </label>
              <textarea
                  type="text"
                  className="rounded-md w-full h-32 p-2"
                  id="anything_else_to_share"
                  name='anything_else_to_share'
                  onChange={formik.handleChange}
                  value={formik.values.anything_else_to_share}
                  />
            </div>
        </div>
          <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default FeedbackProgrammaticTrainers;