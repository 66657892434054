
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image1 from "../images/help-ticket-1.png"
import Image2 from "../images/help-ticket-2.png"
 
 
function CoachTheoryFeedback() {
    const navigate = useNavigate();

    let btnRef = useRef();
    const [email, setEmail] = useState("");
    const [feedback, setFeedback] = useState("");  
    const [formSubmitted, setFormSubmitted] = useState(false)
    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['email', 'feedback']
   

   

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setEmail(values.email)
      setFeedback(values.feedback)
     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['email', 'feedback']



    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      email: email,
      feedback:feedback
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){
        let data = values

         btnRef.current.setAttribute("disabled", "disabled");
         const url =
         "https://us-central1-ctedu-lms.cloudfunctions.net/application"
         const body = {"data": data, 'type':'coachTheoryFeedback'}
         axios.post(url, body, {
           headers: {
             'Content-Type': 'application/json'
           }}).then(() =>{
              setFormSubmitted(true)
           })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Coach Theory Feedback</title>
            <meta name="description" content="Coach Theory Feedbac" />
            <meta name="title" content="Coach Theory Feedbac" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full pt-10  bg-newBranding-jet px-10">
            <h1  className="text-4xl sm:text-5xl mb-5 text-white text-center m-auto order-2 sm:order-1">Coach Theory  Beta Testing<br/>Feedback</h1>
      </div>
      <div className="w-full bg-newBranding-beige px-10 min-h-screen">
        {!formSubmitted ? 
        <form id='support-ticket' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <div className="grid-cols-2 max-w-500 w-full m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 pb-10">
                   
          <label className="col-span-2"> Email*
            <input
                type="email"
                className="rounded-md w-full h-10 p-2"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
            {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
          </label>
        </div>
            
            <label className="col-span-2"> Feedback*
                <textarea
                type="text"
                className="rounded-md p-2 w-full h-36"
                id="feedback"
                name='feedback'
                onChange={formik.handleChange}
                value={formik.values.feedback}
                />
            {errorsValidation.feedback ? <div className='text-red-500'>{errorsValidation.feedback}</div> : null}
          </label>
          <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      :
      <p className="pt-10 text-center">Thank you for your feedback.</p>}
      </div>
    </div>
  );
}
export default CoachTheoryFeedback;