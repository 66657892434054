
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image from "../images/form-early-alert.png"

 
 
function EarlyAlert() {

    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [nameCoachConcern, setNameCoachConcern] = useState("");
    const [relation, setRelation] = useState("");
    const [please_share_all_details, setPlease_share_all_details] = useState("");
    const [actions, setActions] = useState("");
    const [actionsRecommended, setActionsRecommended] = useState("");
    const [withdrawal, setWithdrawal] = useState(false);
    const [high_alert, setHigh_alert] = useState("");
    
    
    const [errorsValidation, setErrorsValidation] = useState({})
 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'nameCoachConcern', 'relation', 'please_share_all_details']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setNameCoachConcern(values.nameCoachConcern)
      setRelation(values.relation)
      setPlease_share_all_details(values.please_share_all_details)
      setActions(values.actions)
      setActionsRecommended(values.actionsRecommended)
      setWithdrawal(values.withdrawal)
      setHigh_alert(values.high_alert)

  };

  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'nameCoachConcern', 'relation', 'please_share_all_details']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email:email,
      nameCoachConcern:nameCoachConcern,
      relation:relation,
      please_share_all_details:please_share_all_details,
      actions:actions,
      actionsRecommended:actionsRecommended,
      withdrawal:withdrawal,
      high_alert:high_alert
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const body = {"data": data, 'type':'early-alert'}

      axios.post(url, body, {
        headers: {
            'Content-Type': 'application/json'
        }}).then(() =>{
           navigate('/thank-you')
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })
  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Early Alert</title>
            <meta name="description" content="Early Alert" />
            <meta name="title" content="CTEDU Early Alert" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
      <div className="grid grid-cols-1 max-w-600 justify-content items-center m-auto lg:grid-cols-2 py-10 lg:max-w-1220 mx-auto">
            <div className="text-white space-y-4 order-2 lg:order-1">
              <h1 className="text-4xl sm:text-5xl mb-5">Early Alert</h1>
              <p className="font-bold text-xl">At Coach Training EDU, we have a very high persistence rate and supporting coaches of concern early in the program is a big part of our success. </p>
              <p>There are several signs that a coach may be at risk of either withdrawing from the program or not successfully completing a course and, with wrap-around, coach-centered support, we can ensure that every coach receives the individual attention they need and deserve throughout their coach training journey.</p>
              <p>Please use this form to share with our Early Alert Coordinator the details of a coach of concern, so we may offer the necessary support for their success.</p>
            </div>
            <div className="lg:w-4/5 m-auto order-1 lg:order-2">
              <img src={Image} />
            </div>
        </div>
      </div>
      <div className="w-full bg-newBranding-beige px-10">
        <form id='early-alert' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className=" text-center lg:text-left font-bold pb-5">Basic Information</p>
        <div className="grid-cols-1 max-w-500 m-auto lg:max-w-none lg:grid-cols-3 grid gap-y-14 gap-x-5 border-newBranding-beigeText pb-10 w-full border-t pt-10">

            <div className="col-span-1 flex flex-col justify-between">
                <label>First Name*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10 p-2"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
                {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>Last Name*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10 p-2"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
                {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
            </div>

            <div className="col-span-1 flex flex-col justify-between">
              <label> Email*</label>
              <input
                  type="email"
                  className={`rounded-md w-full h-10 p-2`}
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  />
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>Name of the Coach of Concern*</label>
                <input
                type="text"
                className="rounded-md w-full  h-10 p-2"
                id="nameCoachConcern"
                onChange={formik.handleChange}
                value={formik.values.nameCoachConcern}
                />
                {errorsValidation.nameCoachConcern ? <div className='text-red-500'>{errorsValidation.nameCoachConcern}</div> : null}
            </div>

            <div className="hidden lg:grid lg:col-span-2"></div>

            <div className="col-span-1 flex flex-col relative">
                <label  htmlFor="withdrawal">Withdrawal<br/><span className="text-sm">This coach has indicated to me that they would like to withdraw from the program.</span>
                <div>
                  <input className="w-6 h-6 cursor-pointer mr-3 opacity-0" type="checkbox" onChange={formik.handleChange} id="withdrawal" name="withdrawal" value={formik.values.withdrawal} checked={formik.values.withdrawal}/>
                  {formik.values.withdrawal ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                </div>
                </label>
            </div>

            <div className="col-span-1 flex flex-col ">
                <label>In relation to the coach of concern, I am their…*</label>
                <div className="grid grid-cols-2 mt-5">
                    <label className="radio-item relative">
                        <input
                        id="trainer"
                        value="trainer"
                        className='opacity-0'
                        checked={formik.values.relation === 'trainer'}
                        name="relation"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                        />
                        {formik.values.relation  === 'trainer' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                        <label htmlFor="trainer" className="ml-5 text-base">Trainer</label>
                    </label>
                    <label className="radio-item relative">
                        <input
                        id="assessor"
                        value='assessor'
                        className='opacity-0'
                        checked={formik.values.relation  === 'assessor'}
                        name="relation"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                        />
                        {formik.values.relation  === 'assessor' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                        <label htmlFor="assessor" className="ml-5 text-base">Assessor</label>
                    </label>
                    <label className="radio-item relative mt-2">
                        <input
                        id="classmate"
                        value='classmate'
                        className='opacity-0'
                        checked={formik.values.relation  === 'no'}
                        name="relation"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                        />
                        {formik.values.relation  === 'classmate' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                        <label htmlFor="classmate" className="ml-5 text-base">Classmate</label>
                    </label>
                    <label className="radio-item relative mt-2">
                        <input
                        id="leadershipTeam"
                        value='leadershipTeam'
                        className='opacity-0'
                        checked={formik.values.relation  === 'leadershipTeam'}
                        name="relation"
                        type="radio"
                        onChange={e => {
                            formik.handleChange(e)
                        }}
                        />
                        {formik.values.relation  === 'leadershipTeam' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                        <label htmlFor="leadershipTeam" className="ml-5 text-base">Leadership Team</label>
                    </label>
                    {errorsValidation.relation  ? <div className='text-red-500'>{errorsValidation.relation}</div> : null}
                </div>
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>Please share all details you would like our team to know about why this coach is of concern and any support you've offered or communication you've had with this coach.*</label>
                <textarea
                type="text"
                className="rounded-md w-full h-32 p-2"
                id="please_share_all_details"
                name="please_share_all_details"
                onChange={formik.handleChange}
                value={formik.values.please_share_all_details}
                />
                {errorsValidation.please_share_all_details ? <div className='text-red-500'>{errorsValidation.please_share_all_details}</div> : null}
            </div>

            <div className="col-span-1 flex flex-col justify-between">
                <label>What actions have been taken so far?</label>
                <textarea
                type="text"
                className="rounded-md w-full  h-32 p-2"
                id="actions"
                onChange={formik.handleChange}
                value={formik.values.actions}
                />
                {errorsValidation.actions ? <div className='text-red-500'>{errorsValidation.actions}</div> : null}
            </div>        

            <div className="col-span-1 flex flex-col justify-between">
                <label>What course of action do you recommend?</label>
                <textarea
                type="text"
                className="rounded-md w-full  h-32 p-2"
                id="actionsRecommended"
                onChange={formik.handleChange}
                value={formik.values.actionsRecommended}
                />
                {errorsValidation.actionsRecommended ? <div className='text-red-500'>{errorsValidation.actionsRecommended}</div> : null}
            </div>   

            <div className="col-span-1 flex flex-col relative">
                <label htmlFor="high_alert">High Alert<br/><span className="text-sm">This is an alert that needs to be addressed immediately, rather than in the usual 24 hour response period.</span>
                <div>
                  <input className="w-6 h-6 cursor-pointer mr-3 opacity-0" type="checkbox" onChange={formik.handleChange} id="high_alert" name="high_alert" value={formik.values.high_alert} checked={formik.values.high_alert}/>
                  {formik.values.high_alert ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-jet rounded-md flex justify-center items-center border-2 border-newBranding-jet  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-md border border-newBranding-jet left-0"></span>}
                </div>
                </label>
            </div>               

        </div>
        <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default EarlyAlert;