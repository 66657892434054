import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useFormik } from "formik"

import {useRef} from 'react';
import Shoe from '../images/shoe.png'
import Bike from '../images/bike1.png'
import Bus from '../images/bus.png'
import Airplane from '../images/airplane.png'



function ClassSelectionForm(props) {
  const [path, setPath] = useState("");
  const [initialPath, setInitialPath] = useState("")
  const [errorsValidation, setErrorsValidation] = useState({})
  


  let btnRef = useRef();

  useEffect(() => {
    props.getValue('path').then((result) => {
      setPath(result)
      setInitialPath(result)
    })
  }, [props.user]);

 
  const validate = (values) => {
   const errors = {};
    const required = ['path']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });

    setPath(values.path)
  };

  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['path']

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      path: path,
      step:3
    },
    enableReinitialize: true,
    validate,

    onSubmit: values => {
     const errors = validateOnSubmit(values);
      if(Object.keys(errors).length === 0){
        btnRef.current.setAttribute("disabled", "disabled");

        let data = values
        let dataChanged = false
        if(data.path !== initialPath) dataChanged = true
         props.onSubmit(data, dataChanged)
      }
      setErrorsValidation(errors)
      return errorsValidation
    
  
    },
  })
  


const selected = 'border-2 border-newBranding-blueSelected '

    
  return (
    <div>
      <div className="">
      <form onSubmit={formik.handleSubmit} className=" mb-10 flex flex-col justify-center w-full ">
        <label className="text-center md:text-left" ><span className='font-bold'>Training package*</span>
        <div className="grid grid-cols-1 mt-5 mb-10 max-w-max h-auto lg:grid-cols-2 lg:grid-cols-2 gap-5">

        <label>
              <div className={`radio-item lg:max-w-340 w-full h-full items-start lg:items-center flex flex-col cursor-pointer bg-white p-5 px-5 md:px-10 lg:px-5 rounded-2xl relative ${formik.values.path === 'foundation' ? selected : null}`}>
               {formik.values.path === 'foundation' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
                <input
                  id="foundation"
                  value='foundation'
                  checked={formik.values.path ==='foundation'}
                  name="path"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                />
                <div className="flex flex-row m-auto md:pb-3 mb-1 lg:mb-4 sm:pb-0 mb-0">
                  <img src={Shoe} className='w-8 lg:w-12 h-auto self-center m-auto' alt='Foundation Path'/>
                  <p className='p-3 md:p-5  font-extrabold m-auto text-lg text-center'>Foundation Package</p>
                </div>
                <p className="w-full border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Includes:</span></p>
                <p className="block lg:hidden font-bold text-sm mt-4">Includes</p>
                <div className='ml-1 mt-1 lg:mt-5 flex-grow'>
                   <ul className='list-disc text-smx text-left'>
                     <li className='ml-4'>Hardcopy of 1.0 Essential Coach Training Textbook</li>
                     <li className='ml-4'>1 One-to-One Coach Mentor Session</li>
                  </ul>
                  </div>
                  <p className="w-full mt-4 lg:mt-8 border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Prices:</span></p>
                  
                  <div className="grid grid-cols-3 lg:grid-cols-2 w-full items-center mt-5 justify-between">
                  <p className="block lg:hidden font-bold text-sm text-left">Prices:</p>
                    <div className="flex  justify-center w-full flex-col m-auto">
                      <p className="text-center font-bold font-serif">$1620.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">One-time payment</p>
                    </div>
                    <div className="flex justify-center w-full flex-col m-auto border-l border-newBranding-beigeText">
                      <p className="text-center font-bold font-serif">$150.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">12 Monthly Payment</p>
                    </div>
                  </div>
                </div>
              </label>  

    
        <label>
        <div className={`radio-item lg:max-w-340 w-full h-full items-start lg:items-center flex flex-col cursor-pointer bg-white p-5 px-5 md:px-10 lg:px-5 rounded-2xl relative ${formik.values.path === 'associate' ? selected : null}`}>
          {formik.values.path === 'associate' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
                <input
                  id="associate"
                  value='associate'
                  checked={path ==='associate'}
                  name="path"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                />
                <div className="flex flex-row m-auto pb-0 md:pb-3 mb-1 lg:mb-4 sm:pb-0 mb-0">
                  <img src={Bike} className='w-8 lg:w-12 h-auto self-center m-auto' alt='Associate Path'/>
                  <p className='p-3 md:p-5 font-extrabold m-auto text-lg text-center '>Associate Package</p>
                </div>
                <p className=" w-full border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Includes:</span></p>
                <p className="block lg:hidden font-bold text-sm mt-4">Includes</p>
                <div className='ml-1 mt-1 lg:mt-5 flex-grow'>
                    <ul className='list-disc text-smx text-left'>
                      <li className='ml-4'>1.0 Training Course (26-week course)</li>
                      <li className='ml-4'>Group Mentor Coaching (8-week course)</li>
                      <li className='ml-4'>Hardcopy materials </li>
                      <li className='ml-4'>Lifetime access to all materials, updated electronically</li>
                      <li className='ml-4'>3 one-on-one Mentor hours</li>
                      <li className='ml-4'>8 Group Mentor Hours</li>
                    </ul>
                  </div>
                  <p className="text-smx mt-5 text-left">*This package prepares a coach for the <span className="font-extrabold">ICF ACSTH</span> path and fulfills the training required for an Associate Certification.</p>
                  <p className="w-full mt-4 lg:mt-8 border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Prices:</span></p>
                  <div className="grid grid-cols-3 lg:grid-cols-2 w-full items-center mt-5 justify-between">
                  <p className="block text-left lg:hidden font-bold text-sm ">Prices:</p>
                    <div className="flex  justify-center w-full flex-col m-auto">
                      <p className="text-center font-bold font-serif">$4050.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">One-time payment</p>
                    </div>
                    <div className="flex justify-center w-full flex-col m-auto border-l border-newBranding-beigeText">
                      <p className="text-center font-bold font-serif">$375.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">12 Monthly Payment</p>
                    </div>
                  </div>
              </div>
              </label>
              


            <label>
              <div className={`radio-item lg:max-w-340 h-full w-full items-start lg:items-center flex flex-col cursor-pointer bg-white  p-5 px-5 md:px-10 lg:px-5 rounded-2xl relative ${formik.values.path === 'professional' ? selected : null}`}>
               {formik.values.path === 'professional' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
                <input
                  id="professional"
                  value='professional'
                  checked={formik.values.path ==='professional'}
                  name="path"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                />
                <div className="flex flex-row m-auto md:pb-3 mb-1 lg:mb-4 sm:pb-0 mb-0"> 
                  <img src={Bus} className='w-8 lg:w-12 h-auto self-center m-auto' alt='Professional Path'/>
                  <p className='p-3 md:p-5  font-extrabold m-auto text-lg text-center'>Professional Package</p>
                </div>
                <p className="w-full border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Includes:</span></p>
                <p className="block lg:hidden font-bold text-sm mt-4">Includes</p>
                <div className='ml-1 mt-1 lg:mt-5 flex-grow'>
                   <ul className='list-disc text-smx text-left'>
                     <li className='ml-4'>1.0 Training Course (26-week course)</li>
                     <li className='ml-4'>2.0 Advanced Training Course (26-week course)</li>
                     <li className='ml-4'>Group Mentor Coaching (8-week course)</li>
                     <li className='ml-4'>Hardcopy materials</li>
                     <li className='ml-4'>Lifetime access to all materials, updated electronically</li>
                     <li className='ml-4'>6 one-on-one Mentor hours</li>
                     <li className='ml-4'>8 Group Mentor Hours</li>
                  </ul>
                  </div>
                  <p className="text-smx mt-5 text-left">*This package prepares a coach for the <span className="font-extrabold">ICF ACTP</span> path and fulfills the training required for an Professional Certification.</p>
                  <p className="w-full mt-4 lg:mt-8 border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Prices:</span></p>
                  <div className="grid grid-cols-3 lg:grid-cols-2 w-full items-center mt-5 justify-between">
                  <p className="block lg:hidden font-bold text-sm text-left">Prices:</p>
                    <div className="flex  justify-center w-full flex-col m-auto">
                      <p className="text-center font-bold font-serif">$6480.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">One-time payment</p>
                    </div>
                    <div className="flex justify-center w-full flex-col m-auto border-l border-newBranding-beigeText">
                      <p className="text-center font-bold font-serif">$600.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">12 Monthly Payment</p>
                    </div>
                  </div>
              </div>
              </label>     

              <label>
              <div className={`radio-item lg:max-w-340 w-full h-full items-start lg:items-center flex flex-col cursor-pointer bg-white p-5 px-5 md:px-10 lg:px-5 rounded-2xl relative ${formik.values.path === 'master' ? selected : null}`}>
               {formik.values.path === 'master' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
                <input
                  id="master"
                  value='master'
                  checked={formik.values.path ==='master'}
                  name="path"
                  type="radio"
                  className='opacity-0 absolute'
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                />
                <div className="flex m-auto flex-row md:pb-3 mb-1 lg:mb-4 sm:pb-0 mb-0">
                  <img src={Airplane} className='w-8 lg:w-12 h-auto self-center m-auto' alt='Master Path'/>
                  <p className='pl-5 font-extrabold m-auto text-lg text-center'>Master Package</p>
                </div>
                <p className="w-full border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Includes:</span></p>
                <p className="block lg:hidden font-bold text-sm mt-4">Includes</p>
                <div className='ml-1 mt-1 lg:mt-5 '>
                   <ul className='list-disc text-smx text-left'>
                     <li className='ml-4'>1.0 Training Course (26-week course)</li>
                     <li className='ml-4'>2.0 Advanced Training Course (26-week course)</li>
                     <li className='ml-4'>Group Mentor Coaching (8-week course)</li>
                     <li className='ml-4'>Enrollment in <span className="font-bold">every</span> advanced course</li>
                     <li className='ml-4'>Hardcopy materials</li>
                     <li className='ml-4'>Lifetime access to all materials, updated electronically</li>
                     <li className='ml-4'>6 one-on-one Mentor hours</li>
                     <li className='ml-4'>8 Group Mentor Hours</li>
                  </ul>
                  </div>
                  <p className="text-smx mt-5 flex-grow text-left">*This package prepares a coach for the <span className="font-extrabold">ICF ACTP</span> path and fulfills the training required for a Master Certification.</p>
                  <p className="w-full mt-4 lg:mt-8 border-b border-newBranding-beigeText font-bold text-sm  relative px-0"><span className="hidden lg:block absolute bg-white pr-2 -top-3 px-1">Prices:</span></p>
                  <div className="grid grid-cols-3 lg:grid-cols-2 w-full items-center mt-5 justify-between">
                  <p className="block lg:hidden font-bold text-sm text-left">Prices:</p>
                    <div className="flex  justify-center w-full flex-col m-auto">
                      <p className="text-center font-bold font-serif">$12150.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">One-time payment</p>
                    </div>
                    <div className="flex justify-center w-full flex-col m-auto border-l border-newBranding-beigeText">
                      <p className="text-center font-bold font-serif">$1125.00</p>
                      <p className="my-auto lg:text-xs whitespace-nowrap text-xxs text-center text-newBranding-jet opacity-75">12 Monthly Payment</p>
                    </div>
                  </div>
                </div>
              </label>        
              </div>

      {errorsValidation.path ? <div className='text-red-500'>{errorsValidation.path}</div> : null}
      {formik.values.path === `advanced` && <p className="text-sm my-10">Coaches selecting the Advanced Path will have the option to select their Advanced Class in their student dashboard after their registration is complete and their student portal has been created.</p>}
      {formik.values.path === `master` && <p className="my-10 text-sm">Coaches selecting the Master Package will have all Advanced Courses added automatically.</p>}
      </label> 
      
      <div className='flex flex-row-reverse	 justify-between'>
          <button ref={btnRef} type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
          <button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
        </div>
      </form>
      </div>
    </div>
  );
}
export default ClassSelectionForm;
