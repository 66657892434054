import React, { useEffect, useState } from "react";


import { useFormik } from "formik"

import {useRef} from 'react';


function DeptOfEducation(props) {
 
  const current = new Date().toISOString().split("T")[0]
  let btnRef = useRef();

  const [degree, setDegree] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [previousCoachTraining, setPreviousCoachTraining] = useState("");
  const [namePreviousTrainingProgram, setNamePreviousTrainingProgram] = useState("");
  const [useOfTheProgram, setUseOfTheProgram] = useState("");
  const [organizationUniversityName, setOrganizationUniversityName] = useState("");
  const [independantPractice, setIndependantPractice] = useState("");
  const [errorsValidation, setErrorsValidation] =  useState({});

  const [initialDegree, setInitialDegree] = useState("")
  const [initialBirthday, setInitialBirthday] = useState("")
  const [initialPreviousCoachTraining, setInitialPreviousCoachTraining] = useState("")
  const [initialNamePreviousTrainingProgram, setInitialNamePreviousTrainingProgram] = useState("")
  const [initialUseOfTheProgram, setInitialUseOfTheProgram] = useState("")
  const [initialIdependantPractice, setInitialIndependantPractice] = useState("")
  const [initialOrganizationUniversityName, setInitialOrganizationUniversityName] = useState("")

  const [showNamePreviousProgramTraining, setShowNamePreviousProgramTraining] = useState('hidden ');
  const [showIndependentPractice, setShowIndepenentPractice] = useState('hidden ');
  const [showOrganizationUniversityName, setShowOrganizationUniversityName] = useState('hidden ');
   
  

  useEffect(() => {
    props.getValue('degree').then((result) => {
      setDegree(result)
      setInitialDegree(result)
    })
    props.getValue('birthdate').then((result) => {
      setBirthdate(result)
      setInitialBirthday(result)
    })
    props.getValue('previousCoachTraining').then((result) => {
      setPreviousCoachTraining(result)
      setInitialPreviousCoachTraining(result)
      if(result === 'yes'){
        setShowNamePreviousProgramTraining('flex flex-col lg:flex-row mb-5')
      }
    })
    props.getValue('namePreviousTrainingProgram').then((result) => {
      setNamePreviousTrainingProgram(result)
      setInitialNamePreviousTrainingProgram(result)
    })
    props.getValue('useOfTheProgram').then((result) => {
      setUseOfTheProgram(result)
      setInitialUseOfTheProgram(result)
      if(result === 'organization'){
        setShowOrganizationUniversityName('block mb-5')
      }else if(result === 'independentPractice'){
        setShowIndepenentPractice('block mb-5')
      }
    })
    props.getValue('organizationUniversityName').then((result) => {
      setOrganizationUniversityName(result)
      setInitialOrganizationUniversityName(result)
    })
    props.getValue('independantPractice').then((result) => {
      setIndependantPractice(result)
      setInitialIndependantPractice(result)
    })
  }, [props.user]);


  const showNextField = (field) => {
    if(field === "NamePreviousTrainingProgram"){
      setShowNamePreviousProgramTraining('flex  flex-col lg:flex-row mb-10')
    }
    if(field === 'NoTrainingProgram'){
      setShowNamePreviousProgramTraining('hidden')
      setNamePreviousTrainingProgram('')
    }
    if(field === 'organizationUniversityName'){
      setShowOrganizationUniversityName('block mb-5')
      setShowIndepenentPractice('hidden')
      setIndependantPractice('')
    }
    if(field === 'independantPractice'){
      setShowIndepenentPractice('block mb-5')
      setShowOrganizationUniversityName('hidden')
      setOrganizationUniversityName('')
    }
    if(field === 'hobby'){
      setShowIndepenentPractice('hidden')
      setShowOrganizationUniversityName('hidden')
      setOrganizationUniversityName('')
      setIndependantPractice('')
    }
  }

  const validate = (values) => {
    const errors = {};
    const required = ['degree', 'birthdate', 'useOfTheProgram', 'previousCoachTraining',]
    if(values.useOfTheProgram === 'organization'){
      required.push('organizationUniversityName')
    }else if(values.useOfTheProgram === 'independentPractice'){
      required.push('independantPractice')
    }

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
  
    setDegree(values.degree)
    setBirthdate(values.birthdate)
    setPreviousCoachTraining(values.previousCoachTraining)
    setNamePreviousTrainingProgram(values.namePreviousTrainingProgram)
    setUseOfTheProgram(values.useOfTheProgram)
    setOrganizationUniversityName(values.organizationUniversityName)
    setIndependantPractice(values.independantPractice)
    
  };


  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['degree', 'birthdate', 'useOfTheProgram', 'previousCoachTraining']
    if(values.useOfTheProgram === 'organization'){
      required.push('organizationUniversityName')
    }else if(values.useOfTheProgram === 'independentPractice'){
      required.push('independantPractice')
    }

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      degree: degree,
      birthdate: birthdate, 
      previousCoachTraining: previousCoachTraining,
      namePreviousTrainingProgram: namePreviousTrainingProgram,
      useOfTheProgram: useOfTheProgram,
      organizationUniversityName: organizationUniversityName,
      independantPractice: independantPractice,
      step:6
    },
    enableReinitialize: true,
  
    validate,

    onSubmit: values => {
     const errors = validateOnSubmit(values);
      if(Object.keys(errors).length === 0){
        btnRef.current.setAttribute("disabled", "disabled");

        let data = values
        if (data.namePreviousTrainingProgram === undefined) {
          data.namePreviousTrainingProgram = ''
        }
        if(data.organizationUniversityName === undefined){
          data.organizationUniversityName = ''
        }
        if(data.independantPractice === ''){
          data.independantPractice = ''
        }

        let dataChanged = false
        if((data.degree !== initialDegree)
        || (data.birthdate !== initialBirthday)
        || (data.previousCoachTraining !== initialPreviousCoachTraining)
        || (data.namePreviousTrainingProgram !== initialNamePreviousTrainingProgram)
        || (data.useOfTheProgram !== initialUseOfTheProgram)
        || (data.organizationUniversityName !== initialOrganizationUniversityName)
        || (data.independantPractice !== initialIdependantPractice)){
          dataChanged = true
        }

        props.onSubmit(data, dataChanged)
      }
      setErrorsValidation(errors)
      return errorsValidation
    
  
    },
  })

  
  return (
    <div>
      <div>
      <h2 className='mb-5 text-center md:text-left font-bold'>Questions to Meet the US Department of Education Standards</h2>
      <p className="text-sm">Coach Training EDU is currently in the process of applying to be a certified vocational school with the US Department of Education. The answers to these questions satisfy various requirements for the application but will remain anonymous. Your answers will not have any impact on your application status. </p>
      <form onSubmit={formik.handleSubmit} className="mb-10 flex flex-col justify-center mt-5">
        <div className='flex flex-col md:flex-row '>
      <label className="mb-0 lg:mb-5 opacity-75"> <span className="whitespace-nowrap">Highest Education Degree Attained*</span><br/> <span className='text-xs'>Degree Level, Institution, and Date</span></label>
        <input
            type="text"
            className="border-2 border-gray-200 w-full h-8 mt-2 pl-2 border border-newBranding-blue border-opacity-50 rounded-md ml-0 md:ml-5 lg:mb-0 mb-5"
            id="degree"
            onChange={formik.handleChange}
            value={formik.values.degree}
            />
             {errorsValidation.degree ? <div className='text-red-500'>{errorsValidation.degree}</div> : null}
        </div>
        <label className="mb-0 lg:mb-10 opacity-75"> Birthdate* 
        <input type='date'
          placeholder='Enter BirthDate'
          className="w-full sm:w-40 border-2 bg-white border-gray-200 h-8 mt-2 ml-0 sm:ml-2 pl-2 border border-opacity-50 border-newBranding-blue rounded-md ml-0 lg:ml-5 lg:mb-0 mb-5"
          value={formik.values.birthdate}
          onChange={formik.handleChange}
          name='birthdate'
          max={current}
        />
         {errorsValidation.birthdate ? <div className='text-red-500'>{errorsValidation.birthdate}</div> : null}
        </label>
        <label className="mb-10">Have you previously completed a coach training?*
        <div className='mt-2'>
        <label className="radio-item relative mr-5">
          <input
            id="yes"
            value="yes"
            className='opacity-0'
            checked={formik.values.previousCoachTraining ==='yes'}
            name="previousCoachTraining"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
              showNextField('NamePreviousTrainingProgram')
            }}
          />
          {formik.values.previousCoachTraining === 'yes' ? <span className="absolute bg-white inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label className='ml-5 opacity-75' htmlFor="Yes">Yes</label>
        </label >

        <label className="radio-item relative">
          <input
            id="no"
            value='no'
            className='opacity-0'
            checked={formik.values.previousCoachTraining ==='no'}
            name="previousCoachTraining"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
              showNextField('NoTrainingProgram')
            }}
          />
          {formik.values.previousCoachTraining === 'no' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label htmlFor="no" className='ml-5 opacity-75'>No</label>
      </label>
      {errorsValidation.previousCoachTraining ? <div className='text-red-500'>{errorsValidation.previousCoachTraining}</div> : null}
      </div>
      </label> 

      <label className={`${showNamePreviousProgramTraining}`}> 
        <span>If yes, what was the name of the training program?</span>
        <input
        
            type="text"
            className="border-2 border-gray-200 flex-grow h-8  pl-2 border border-newBranding-blue border-opacity-50 mt-2 lg:mt-0 rounded-md ml-0 lg:ml-5 lg:mb-0 mb-5"
            id="namePreviousTrainingProgram"
            onChange={formik.handleChange}
            value={formik.values.namePreviousTrainingProgram}
            />
        </label>

        <label className="mb-5 opacity-100">How do you plan on using your coach training?*
        <div>
        <label className="radio-item relative mt-3 block mb-2">
          <input
            id="organization"
            value="organization"
            className='opacity-0'
            checked={formik.values.useOfTheProgram ==='organization'}
            name="useOfTheProgram"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
              showNextField('organizationUniversityName')
            }}
          />
          {formik.values.useOfTheProgram === 'organization' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label htmlFor="organization" className='ml-5 opacity-75'>I am bringing coaching to my current position in an organization or university.</label>
        </label>

        <label className="radio-item relative block mb-2">
          <input
            id="independentPractice"
            value='independentPractice'
            className='opacity-0'
            checked={formik.values.useOfTheProgram ==='independentPractice'}
            name="useOfTheProgram"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
              showNextField('independantPractice')
            }}
          />
          {formik.values.useOfTheProgram === 'independentPractice' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label className='ml-5 opacity-75' htmlFor="independentPractice">I want to use coaching in my independent practice.</label>
      </label>
      <label className="radio-item relative block">
        <input
          id="hobby"
          value='hobby'
          className='opacity-0'
          checked={formik.values.useOfTheProgram ==='hobby'}
          name="useOfTheProgram"
          type="radio"
          onChange={e => {
            formik.handleChange(e)
            showNextField('hobby')
          }}
        />
        {formik.values.useOfTheProgram === 'hobby' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
        <label className='ml-5 opacity-75' htmlFor="hobby">I do not plan to earn income related to this training.</label>
      </label>
      </div>
      {errorsValidation.useOfTheProgram ? <div className='text-red-500'>{errorsValidation.useOfTheProgram}</div> : null}

      </label> 


      <label className={showOrganizationUniversityName}>What is the name of the university or organization at which you are currently employed?*
         <input
            type="text"
            className="border-2 border-gray-200 w-full h-8 mt-2 w-full  pl-2 border-opacity-50 border border-newBranding-blue  rounded-md ml-0 ml-0  lg:ml-0 lg:mb-0 mb-5"
            id="organizationUniversityName"
            onChange={formik.handleChange}
            value={formik.values.organizationUniversityName}
            />
             {errorsValidation.organizationUniversityName ? <div className='text-red-500'>{errorsValidation.organizationUniversityName}</div> : null}
      </label>



      <label className={`mb-10 ${showIndependentPractice}`}>Independent practice*
        <div className='mt-2 flex flex-col'>
        <label className="radio-item relative mr-5 mb-2">
          <input
            id="currentlyRunPractice"
            value="currentlyRunPractice"
            className='opacity-0'
            checked={formik.values.independantPractice ==='currentlyRunPractice'}
            name="independantPractice"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
            }}
          />
          {formik.values.independantPractice === 'currentlyRunPractice' ? <span className=" bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label className='ml-5 opacity-75' htmlFor="currentlyRunPractice">I currently run an independent practice with paying clients.</label>
        </label >

        <label className="radio-item relative">
          <input
            id="wantToStartPractice"
            value='wantToStartPractice'
            className='opacity-0'
            checked={formik.values.independantPractice ==='wantToStartPractice'}
            name="independantPractice"
            type="radio"
            onChange={e => {
              formik.handleChange(e)
            }}
          />
          {formik.values.independantPractice === 'wantToStartPractice' ? <span className="bg-white absolute inline-flex w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue  left-0">&#10003;</span> : <span className="bg-white absolute h-6 w-6 rounded-full border border-newBranding-blue left-0"></span>}
          <label htmlFor="wantToStartPractice" className='ml-5 opacity-75'>I plan to start an independent practice during/after my training.</label>
      </label>
      {errorsValidation.independantPractice ? <div className='text-red-500'>{errorsValidation.independantPractice}</div> : null}
      </div>
      </label> 


      <div className='flex flex-row justify-between'>
          <button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
          <button ref={btnRef} type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
      </div>
      </form>
      </div>
    </div>
  );
}
export default DeptOfEducation;