
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik"
import axios from 'axios';
import {Helmet} from "react-helmet";
import HeaderLogin from "../Components/HeaderLogin";
import {useRef} from 'react';
import Image1 from "../images/help-ticket-1.png"
import Image2 from "../images/help-ticket-2.png"
 
 
function HelpTicket() {
    const navigate = useNavigate();

    let btnRef = useRef();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");  
    const [description_ticket, setDescription_ticket] = useState("");  
    const [helpTicketAttachment, setHelpTicketAttachment] = useState("");
  
    const [helpTicketAttachmentFileName, setHelpTicketAttachmentFileName] = useState("");
    const [fileTooLarge, setFileTooLarge]= useState(false);

    
    const [errorsValidation, setErrorsValidation] = useState({})

 
  const validate = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'description_ticket']
   

   

    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      }
    });
 
    if (!values.email) {
        errors.email = 'This field is required.';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address.';
      }
 
      setFirstname(values.firstName)
      setLastname(values.lastName)
      setEmail(values.email)
      setPhone(values.phone)
      setDescription_ticket(values.description_ticket)
     
  };



  const validateOnSubmit = (values) => {
    const errors = {};
    const required = ['firstName', 'lastName', 'email', 'phone', 'description_ticket']



    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
        
      }
    });
    

    return errors;
  };



  const formik = useFormik({
    initialValues: {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone:phone,
      description_ticket:description_ticket,
      helpTicketAttachment:helpTicketAttachment
    
    },
    enableReinitialize: true,
    validate,
 
    onSubmit: values => {
      const errors = validateOnSubmit(values);
       if(Object.keys(errors).length === 0){

         btnRef.current.setAttribute("disabled", "disabled");
         let data = values
            
        const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"



       //2 files to appends after making the first one woirking

      let formData = new FormData();
      formData.append("name", data.firstName + ' ' + data.lastName);
      formData.append("email_address", data.email);
      formData.append("phone", data.phone);
      formData.append("description", data.description_ticket);
      formData.append("helpTicketAttachment", data.helpTicketAttachment);
      formData.append("type", "help-ticket");

      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }}).then(() =>{
           navigate('/thank-you-help-ticket')
        }).catch(err => {
          console.log('error ', err)
        })
       }
       
       setErrorsValidation(errors)
       return errorsValidation
    }
    
  })

  const handleFileUpload = (event, fileType) => {
        if(event.currentTarget.files[0].size > 10000000){
          setFileTooLarge(true)
        }else{
           setHelpTicketAttachment(event.currentTarget.files[0])
           setHelpTicketAttachmentFileName(event.currentTarget.files[0].name)
           setFileTooLarge(false)
        }
    }


  
  return (
    <div className="flex flex-col text-newBranding-darkGrey mt-12">
      <Helmet>
            <title>Support Ticket</title>
            <meta name="description" content="Support Ticket" />
            <meta name="title" content="CTEDU Support Ticket" />
        </Helmet>
      
      <HeaderLogin/>
      <div className="w-full  bg-newBranding-jet px-10">
        <div className="grid grid-cols-1 max-w-600 justify-content items-center  m-auto sm:grid-cols-4 py-10 lg:max-w-1220 mx-auto">
            <img src={Image1} className='hidden sm:block '/>
            <h1  className="text-4xl sm:text-5xl col-span-2 mb-5 text-white text-center m-auto order-2 sm:order-1">Coach Training EDU<br/>Help Request</h1>
            <img src={Image2} className='order-1 sm:order-2 w-2/3 lg:w-full m-auto'/>
        </div>
      </div>

      <div className="w-full bg-newBranding-beige px-10 min-h-screen">
        <form id='support-ticket' onSubmit={formik.handleSubmit} className="max-w-1220 mx-auto my-10 flex flex-col w-full ">
        <p className="mb-10 text-center lg:text-left">Submit a <span className="font-bold">Help Request</span></p>
        <div className="grid-cols-2 max-w-500 w-full m-auto lg:max-w-none lg:grid-cols-6 grid gap-y-10 gap-x-5 pb-10">
          
          <label className="col-span-2"> First Name*
            <input
                type="text"
                className="rounded-md w-full  h-10"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                />
            {errorsValidation.firstName ? <div className='text-red-500'>{errorsValidation.firstName}</div> : null}
          </label>

          <label className="col-span-2"> Last Name*
            <input
                type="text"
                className="rounded-md w-full h-10"
                id="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                />
            {errorsValidation.lastName ? <div className='text-red-500'>{errorsValidation.lastName}</div> : null}
          </label>

          <label className="col-span-2"> Email*
            <input
                type="email"
                className="rounded-md w-full h-10"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
            {errorsValidation.email ? <div className='text-red-500'>{errorsValidation.email}</div> : null}
          </label>
        </div>
        <div className="grid-cols-1 max-w-500 w-full m-auto lg:max-w-none lg:grid-cols-3 grid gap-y-10 gap-x-5 pb-10">
            <label> Phone*
              <input
                type="text"
                className="rounded-md w-full h-10"
                id="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                />
            {errorsValidation.phone ? <div className='text-red-500'>{errorsValidation.phone}</div> : null}
            </label>
            <label className="col-span-2"> Description*
                <textarea
                type="text"
                className="rounded-md w-full h-36"
                id="description_ticket"
                name='description_ticket'
                onChange={formik.handleChange}
                value={formik.values.description_ticket}
                />
            {errorsValidation.description_ticket ? <div className='text-red-500'>{errorsValidation.description_ticket}</div> : null}
          </label>
        </div>
        <div className="col-span-1 mt-5 flex flex-col justify-between">
          <label className="w-full block mb-7">Attachments (optional)<br/><span className="text-xs">Please include any screenshots or additional files relevant to your query or issue</span></label>
          <div>
            <label for='helpTicketAttachment' className="rounded-md px-2 py-1 w-max-content bg-newBranding-beigeText text-white mr-2"><i className="fa fa-upload mr-2"></i>Choose a File</label> 
            {helpTicketAttachmentFileName === '' ? `No File Choosen` : helpTicketAttachmentFileName}
          </div>
          <input className="hidden" id="helpTicketAttachment" name="helpTicketAttachment" type="file" accept="file_extension" onChange={e => {handleFileUpload(e, "helpTicketAttachment")}}/>
          {errorsValidation.helpTicketAttachment? <div className='text-red-500'>{errorsValidation.helpTicketAttachment}</div> : null}
          {fileTooLarge && <div className='text-red-500'>Please upload a file less than 5MB</div>}
        </div>
       
      
          <button ref={btnRef} type="submit" className=' mt-10 '><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-16 py-0 m-1 text-base'>Submit</span></button>
      </form>
      </div>
    </div>
  );
}
export default HelpTicket;