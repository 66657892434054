import React, {  useState, useEffect } from "react";

import ClassSelectionForm from "./ClassSelectionForm";
import Package from "./Package";
import ContactInformation from "./ContactInformation"
import ShortQuestionsAnswers from "./ShortQuestionsAnswers";
import CreateCommunity from "./CreateCommunity";
import DeptOfEducation from "./DeptOfEducation";
import TermsConditions from "./TermsConditions";
import Payment from "./Payment";
import Sidebar from "./Sidebar";

import { useNavigate } from 'react-router-dom';


const steps = ['ClassSelectionForm', 'ContactInformation', 'ShortQuestionsAnswers', 'CreateCommunity', 'DeptOfEducation', 'TermsConditions'];

function _renderStepContent(step, fetchedData, handleChange, getValueDb, user, handleBack) {

    switch (step) {
      case 0:
        return <ContactInformation onSubmit={handleChange} fetchedData={fetchedData} getValue={getValueDb} user={user}/>;
      case 1:
        return <ClassSelectionForm handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 2:
        return <Package handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 3:
        return <ShortQuestionsAnswers handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 4:
        return <CreateCommunity handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 5:
        return <DeptOfEducation handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 6:
        return <TermsConditions handleBack={handleBack} onSubmit={handleChange} getValue={getValueDb} user={user}/>;
      case 7:
      return  <Payment handleBack={handleBack} user={user} getValue={getValueDb}/>
      default:
        return <div>Not Found</div>;
    }
  }


function FormContainer(props) {
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate();

  
  useEffect(() => {
      if(props.fetchedData.applicationSubmitted === true){
         navigate('/success', {
          state : {
             date: props.fetchedData.dateLoc
          }
         })
      }else{
        if(Object.keys(props.fetchedData).length === 0){
          setIsLoading(true)
        }else{
          setIsLoading(false)
        }
        
      }
  }, [props.fetchedData])

  if(isLoading){
    return (
    <div className="flex justify-center items-center h-screen w-full  bg-newBranding-beige">
       <div className="animate-spin rounded-full h-10 w-10 md:ml-44 border-b-4 border-l-4 border-t-4 border-newBranding-blue"></div>
    </div> 

    )
  }else{
    return (
      <div className='ml-5 md:ml-14 mr-5 pt-44 md:pt-10 pb-10 lg:mr-10 md:pl-5 lg:pl-10 md:ml-80 h-full bg-newBranding-beige  min-h-screen'>
        {_renderStepContent(props.activeStep, props.fetchedData, props.handleChange, props.getValueDb, props.user, props.handleBack)}
      </div>
  );
  }
  
}
export default FormContainer;