import React, { useEffect, useState } from "react";
import { useFormik } from "formik"
import {useRef} from 'react';
import Signature from '../images/signature.png'
import SignaturePad from "react-signature-canvas";
import axios from 'axios';
import {  writeUserData } from "../firebase"

 
import { useNavigate } from 'react-router-dom';

function TermsConditions(props) {
 
  const [isLoading, setIsLoading] = useState(false)
  let btnRef = useRef();
  const navigate = useNavigate();


  const [termsConditions, setTermsConditions] = useState(false);
  const [signature, setSignature] = useState(null);
  const [errorsValidation, setErrorsValidation] = useState({})

  useEffect(() => {

    props.getValue('termsConditions').then((result) => {
      setTermsConditions(result)
    })
   
  }, [props.user])

  let mySignature1 = useRef({})
 
const transformSignatureToImage = () => {
  setSignature(mySignature1.current.toDataURL()) 
  setTermsConditions(formik.values.termsConditions)
}


const handleSubmit = async (e) => {
    setIsLoading(true)
      const url =
      "https://us-central1-ctedu-lms.cloudfunctions.net/application"
      const data = {"email": props.user.email, 'type':'applicationSubmitted'}
      axios.post(url,  data, {
        headers: {
          'Content-Type': 'application/json'
        }})
        .then(() => {
          return writeUserData(props.user.email, {applicationSubmitted: true})
        })
        .then(() =>{
           navigate('/success')
        })
};





const clearSignaturePad = () => {
  mySignature1.current.clear();
  setSignature(null)
}

  const validate = (values) => {
    const errors = {};
    const required = ['termsConditions']

    if(mySignature1.current.isEmpty()){
     required.push('signature')
    }
    required.map((value) => {
      if(!values[value]) {
        errors[value] = 'This field is required.';
      } 
     
    });

  
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      termsConditions: termsConditions,
      signature: signature,
      step:6
    },
    enableReinitialize: true,
   
    onSubmit: values => {
       validate(values);
      const errors = validate(values);
      
       if(Object.keys(errors).length === 0){
        // btnRef.current.setAttribute("disabled", "disabled");
         
       //  let data = values
         handleSubmit()
       }
       setErrorsValidation(errors)
       return errorsValidation
      
    },
  })
  
  return (
    <div>
      <div>
      <h2 className='mb-5 text-center md:text-left font-bold'>Terms and Conditions</h2>
      <p className="text-sm">The terms and conditions cover the details of the training program, financial agreements, including information on refunds, student privacy and confidentiality agreements, and steps to earn your coach training certification.</p>
      <form onSubmit={formik.handleSubmit} className=" mb-10 flex flex-col justify-center ">

        <div className="h-32 bg-white overflow-y-scroll mt-5 border border-newBranding-blue border-opacity-50 rounded-md p-2 mt-2 text-sm">
        Standard Training and Business License Agreement
            <br/><br/>
            Coach Training EDU<br/>
            PO Box 2021<br/>
            Hood River, Oregon 97031
            <br/><br/>
            This Agreement is made effective as of the date of application between Coach Training    EDU ("Licensor") and __________ (“Licensee”) and will have a two (2) year term.<br/>
            <br/>
            I. Training, Certification, Business Materials, and Grant of License<br/>
            <br/>
            Coach Training EDU is dedicated to provide training in the foundation of life coaching and life coaching skills as well as to provide business materials and training to market a Life Coaching Practice. Coach Training EDU’s training courses consist of four parts: training, certification, business materials, and grant of license.<br/>
            <br/>
            Training<br/>
            All 1.0 level training courses consist of three parts: Foundational Coaching Skills, Tools and Skills for a specified coaching niche (ie. Academic, Executive or Wellness), and Business Practices associated with the the Life Coaching Practice.<br/>
            The 2.0 Coach Training course includes advanced coaching skills, based on Positive Psychology concepts and certification requirements outlined by the International Coach Federation (ICF).<br/>
            All training courses meet the training requirements of the International Coach Federation Associate Coach Certification (ACC).<br/>
            Training consists of 26 classes (including Orientation), each lasting approximately 2 hours. Classes are scheduled weekly and at times that are mutually accessible to the participants in each class.<br/>
            Training classes are held via video-conference. You can also call in on the conference, but video is recommended.<br/>
            A coach-in-training is required to have at least one ‘practice client’ to practice the delivery of the coaching skills taught in the training course It is highly recommended to have two practice clients and not charge your practice clients for the coaching sessions.<br/>
            <br/>
            Confidentiality Agreement<br/>
            As an online organization, confidentiality is of utmost importance to our team. Each class is recorded and shared on a private youtube playlist for only the class and CTEDU staff to review. By checking the box, you are agreeing to not sharing your youtube link with anyone outside of your class.<br/>
            <br/>
            Overviews<br/>
            Each coach in training will schedule three overviews with the assessor for the class. Overviews are spaced approximately every six weeks throughout the program.<br/>
            All requirements for the course are due when specified on the class page and syllabus.<br/>
            If there is an extenuating circumstance, and a coach is unable to meet any of the deadlines listed, they are required to email their trainer requesting an extension.<br/>
            A coach may complete a fourth overview at no charge if they have not yet met the necessary standard.<br/>
            If a coach in training has not received a passing score on the fourth overview and is seeking certification through the program, they can request additional overviews for $75 an overview, as well as request a different assessor(s) for subsequent overviews.<br/>
            Coaches will not receive certification if their 2nd or 3rd overview does not receive a passing score; however, if they have fulfilled all other 1.0 course requirements, they will receive a Letter of Completion with acknowledgement of their training hours, which can be used to obtain certification directly through the International Coach Federation (ICF).<br/>
            If a coach in training is not seeking certification, they can request a Letter of Completion, granted that they have fulfilled the other 1.0 course requirements.<br/>
            If a coach needs longer then a three months extension to complete certification requirements, they may re-enroll in a later program start date if they wish to take the program again at their expense.<br/>
            <br/>
            <br/>
            Certification<br/>
            <br/>
            Certification as an Associate Certified or Certified Coach requires:<br/>
            <br/>
            Participation in 24 of the 26 training classes.<br/>
            Completion of 10 coaching sessions with a practice client (or paying client for the 2.0 Coach Training course).<br/>
            Recording the audio of three coaching sessions with your practice client and having one-to-one calls with your instructor or assessor to review and receive constructive feedback on your coaching. Receiving a passing score on the 2nd or 3rd overview. A coach is required to receive permission from the client to record and share their session for an overview. <br/>
            Completion of Group Mentor Class<br/>
            There is no additional cost for the Licensee to apply for certification.<br/>
            <br/>
            Business Materials<br/>
            <br/>
            All 1.0 training courses include training in the business systems to establish and run a successful Life Coaching Practice.<br/>
            <br/>
            Materials for 1.0 level training courses:<br/>
            Presenter notes and presentations for delivering workshops<br/>
            Business building recordings and other business documents<br/>
            Week-by-week business plan<br/>
            <br/>
            The 2.0 Coach Training course does not include business-building materials or training.<br/>
            <br/>
            Grant of License<br/>
            <br/>
            This agreement grants the Licensee access to Training Course Materials, Certification Process, and Business Materials. This agreement also grants the Licensee the right to:<br/>
            Represent yourself as a trained and Associate Certified or Certified Coach (after passing certification)<br/>
            Permission to copy all marketing material (brochures, booklets, descriptions) available online in PDF form to your local computer<br/>
            Ability to leverage past media attention<br/>
            Access to all updates of course development, marketing materials, and workshop presentations.<br/>
            <br/>
            The Licensee’s certification and license does NOT expire at the end of the 2-year agreement. No additional training or continuing education courses are required for the Licensee to keep their title as an Associate Certified or Certified Coach.<br/>
            <br/>
            Licensee acknowledges that the copyright and title to the Licensed Materials and any trademarks or service marks relating to them remain with Licensor. Licensee does not have the right, title, or interest in the Licensed Materials except as expressly set forth in this Agreement.<br/>
            <br/>
            Licensor hereby grants the Licensee non-exclusive use of the Licensed Materials in accordance with this agreement.<br/>
            <br/>
            <br/>
            II. Class Delivery and Use of licensed materials<br/>
            <br/>
            Class Recordings. Each webinar and call is recorded for the primary benefit of the participants to catch up in case you have to miss a training session.<br/>
            <br/>
            Material Access. The Licensed Materials will be stored and accessible by a licensee-only page on the Internet.<br/>
            <br/>
            Training, Coaching, and Consulting. The training, coaching and consulting will take place over webinar (and sometimes phone), and will be mutually scheduled by the Licensor and Licensee to be flexible and meet both schedules.<br/>
            <br/>
            <br/>
            III. Payment<br/>
            <br/>
            Training Fee. Licensee agrees to pay Licensor the training, certification, and license fee as outlined below:<br/>
            Payment in full: $3240, $5400, $7020, $11340, or as according to the tuition level selected.<br/>
            12-Month payment plan: $300/month, $500/month, $650/month or $1050/month for 12 months as according to the tuition level selected.<br/>
            <br/>
            A Licensee must provide first month’s payment or payment in full in order to secure her or his spot in the desired training class. If, at any time, the Licensee does not complete payment as outlined by this contract, access to all training materials may be suspended by the Licensor.<br/>
            <br/>
            Refund Policy.<br/>
            <br/>
            We provide refunds for all three tuition levels.<br/>
         
            <br/>
            Installment and Pay In Full Refunds<br/>
            Minus the fees stated above, the refund schedule is as follows.<br/>
            <br/>
            Training weeks 0 (Orientation) - 12: Prorated refund based on course progression. (Minus the $200.00 application and course materials fee).<br/>
            <br/>
            No refund after 13 weeks (Orientation through Training Session 12).<br/>
            <br/>
            <br/>
            *The responsibility of the credit card processing fee issued by the bank is held by the participant. This amount is a specific percentage of your refund and will be emailed to you upon refund request.<br/>
            <br/>
            <br/>
            The Coach Training EDU Administration department handles all refunds within a week of official request.<br/>
            <br/>
            <br/>
            <br/>
            <br/>
            IV. Authorized Use of Licensed materials<br/>
            <br/>
            Authorized Uses. Licensee may make all use of the Licensed Materials as is consistent with the Fair Use Provisions of United States and international copyright laws. In addition, the Licensed Materials may be used for purposes of research, education or other non-commercial uses as follows:<br/>
            <br/>
            Display. Licensee shall have the right to electronically display the Licensed Materials.<br/>
            <br/>
            Copy. Licensee may download and digitally copy the complete Licensed Materials.<br/>
            <br/>
            Print Copy. Licensee may print the Licensed Materials.<br/>
            <br/>
            Archival/Backup Copy. Licensee may create one (1) copy of the entire set of source files of the Licensed Materials to be maintained as a backup or archival copy on their local computer during the term of this Agreement.<br/>
            <br/>
            Amount of Authorized Use. Subject to the terms of this Agreement, Licensee shall have unlimited access to the Licensed Materials.<br/>
            <br/>
            V. Specific Restrictions on Use of Licensed Materials<br/>
            <br/>
            Unauthorized Use. Licensee shall not knowingly permit anyone other than the authorized users to use the Licensed Materials and not share the password to the coach-only webpage on the internet.<br/>
            <br/>
            Unintended Use. This agreement extends only to the Licensee working one-to-one with individual clients and does not give a license to use any of the materials or portions of the materials to train others to be Life Coaches without further training and an express license to do so.<br/>
            <br/>
            Non-Compete. The Licensor agrees to not develop a similar Life Coach Training Course or Program for a period lasting two years from the start of this agreement without the written consent of Coach Training EDU.<br/>
            <br/>
            Modification of Licensed Materials. Licensee shall not modify, manipulate, or create a derivative work of the Licensed Materials without the prior written permission of Licensor (even after the two year non-compete period). You have the Licensor’s permission to add your contact information to any of the handouts you give to clients and potential clients.<br/>
            <br/>
            Removal of Copyright Notice. Licensee may not remove, obscure or modify any copyright or other notices included in the Licensed Materials.<br/>
            <br/>
            <br/>
            <br/>
            VI. Limit of Liability<br/>
            <br/>
            LIMIT OF LIABILITY. YOU EXPRESSLY UNDERSTAND AND AGREE THAT Coach Training EDU, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR:<br/>
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, OR OTHER INTANGIBLE LOSS;<br/>
            ANY LOSS OR DAMAGE WHICH A THIRD PARTY MAY BRING CASE AGAINST YOU;<br/>
            CHOICE OF LAW. NOT DUE TO ANY FRAUDULENT REASONS, THIS AGREEMENT AND ANY AND ALL RELATIONSHIPS ARISING OUT OF THIS AGREEMENT BETWEEN THE PARTIES SHALL BE GOVERNED BY THE LAWS OF THE STATE OF OREGON.<br/>
            THE LICENSEE RECOGNIZES THAT THE LICENSOR MAKES NO PROMISES ON THE SUCCESS OF THE LICENSEE’S BUSINESS, THE LICENSEE ALONE IS RESPONSIBLE FOR THE FINANCIAL SUCCESS OF THE LICENSEE’S COACHING PRACTICE.<br/>
            6.2 THE LIMITATIONS ON Coach Training EDU’S LIABILITY TO YOU IN PARAGRAPH 6.1 ABOVE SHALL APPLY WHETHER OR NOT Coach Training EDU IS NEGLIGENT OR HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.<br/>
            <br/>
            VII. Licensor Performance Obligations<br/>
            <br/>
            Availability of Licensed Materials. Upon the Effective Date of this Agreement, Licensor shall make the Licensed Materials available to Licensee.<br/>
            <br/>
            Documentation. Licensor will provide and maintain files and other appropriate user documentation.<br/>
            <br/>
            Notification of Modifications of Licensed Materials. Licensee understands that from time to time the Licensed Materials may be added to, modified, or deleted from by Licensor and/or that portions of the Licensed Materials may be changed to other formats. Licensee will have complete access to all changes and modifications made to materials.<br/>
            <br/>
            <br/>
            VII. Licensor Performance Obligations<br/>
            <br/>
            limit of Liability. the Licensor will provide the Licensee with a suggested contract that includes a limit of liability agreement for the clients of the licensee the coach-client contract is provided as general Legal information. any questions regarding limitation of liability, contract between the licensee and a client, or any other legal advice should be directed to a qualified, licensed attorney in your state.<br/>
            <br/>
            Quality Performance. Licensee shall make every effort to provide a high level of quality in delivery of coaching.<br/>
            <br/>
            Provision of Notice of Intellectual Property Right to Authorized Users. Licensee shall provide Authorized Users (Clients) with notice of any applicable Intellectual Property or other rights applicable to the Licensed Materials when appropriate.<br/>
            <br/>
            Protection from Unauthorized Use. Licensee shall protect the Licensed Materials from any use that is not permitted under this Agreement.<br/>
            <br/>
            Maintaining Confidentiality of Access. Licensee is to keep all access to materials confidential and not accessible to anyone else.<br/>
            <br/>
            <br/>
            IX. Additional Employment Agreement and Continuing the License<br/>
            <br/>
            Additional Employment. In the event that additional employment opportunities within Coach Training EDU are extended to the Licensee, at the mutual agreement of the Licensor and Licensee, the terms of this contract will be modified. Coach Training EDU is a growing organization, and there will be opportunities for Coaches to become Trainers for future classes. Each project will require an additional agreement outlining terms of engagement.
  
        </div>
        <a className='text-xs underline' href="Terms-and-conditions-PDF.pdf" download>Download the terms and conditions PDF</a>
        <label className='block relative mb-5' htmlFor="termsConditions"> 
            <input type="checkbox" className='w-6 h-6 mt-5 cursor-pointer mr-3 opacity-0' 
            onChange={e => {formik.handleChange(e)  }} 
            id="termsConditions" 
            name="termsConditions" 
            value={formik.values.termsConditions} 
            checked={formik.values.termsConditions}/> 
           {formik.values.termsConditions ? <span className="absolute inline-flex bg-white w-6 h-6 font-bold text-newBranding-blue rounded-full flex justify-center items-center border-2 border-newBranding-blue top-5 left-0">&#10003;</span> : <span className="absolute bg-white h-6 w-6 rounded-full border border-newBranding-blue top-5 left-0"></span>}
           <span className='relative -top-2  text-newBranding-darkGrey opacity-75'>If accepted, I agree to the terms and conditions outlined below.*</span> 
        </label>
        {errorsValidation.termsConditions ? <div className='text-red-500'>{errorsValidation.termsConditions}</div> : null}
        <p className='mt-10 mb-5'>SIGNATURE PAD*</p>
          <div className='h-36 max-w-400 border-newBranding-blue bg-white border-2 relative'>
            <SignaturePad canvasProps={{className:'w-full h-full'} } onEnd={transformSignatureToImage} ref={mySignature1}/>
            <div className='cursor-pointer absolute ' onClick={clearSignaturePad}>Clear</div>
          </div>
        {errorsValidation.signature ? <div className='text-red-500'>{errorsValidation.signature}</div> : null}
        {isLoading?
         <div className="animate-spin rounded-full h-10 w-10 border-b-4 border-l-4 border-t-4 border-newBranding-blue mt-10 m-auto"></div>:
        <div className='flex flex-row justify-between'>
        <button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
        
        <button type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Submit</span><span className='opacity-75 ml-2'>&#10095;</span></button>
      </div>
        }
        
      </form>
      </div>
    </div>
  );
  
}
export default TermsConditions;