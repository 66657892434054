import React, { useEffect, useState } from "react";
import {getUserToApproveDirectory, approveProfile, getAllApprovedProfile, notApproveProfileDb, getRecentlyEditedProfile, removeEditedProfile} from '../firebaseDirectory'
import { writeUserData } from "../firebase";
import axios from 'axios';


function CoachDirectoryDashboard(){
    const [profileToApprove, setProfileToApprove] = useState([])
    const [approvedProfiles, setApprovedProfiles] = useState([])
    const [recentlyEdited, setRecentlyEdited] = useState([])
    const [notApprovedReason, setNotApprovedReason] = useState('')
    const [open, setOpen] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(undefined);

    const setApproveProfile = (profile) => {
        approveProfile(profile.email)
        setProfileToApprove(profileToApprove.filter(item => item.email !== profile.email))
        let url ="https://us-central1-ctedu-lms.cloudfunctions.net/application"
        let data = {"email": profile.email, "link":'https://www.coachtrainingedu.com/life-coach/'+profile.displayName.split(' ').join('-').toLowerCase(),  'type':'profile-approved-coach-directory'}
        axios.post(url,  data, {
          headers: {
            'Content-Type': 'application/json'
          }})
    }

    const notApproveProfile = (profile, reason) => {
        notApproveProfileDb(profile.email, reason)
        setProfileToApprove(profileToApprove.filter(item => item.email !== profile.email))
        setSelectedProfile([])
        setOpen(false)
        let url ="https://us-central1-ctedu-lms.cloudfunctions.net/application"
        let data = {"email": profile.email, 'type':'profile-not-approved-coach-directory'}
        axios.post(url,  data, {
          headers: {
            'Content-Type': 'application/json'
          }})
    }

    const editVerified = (profile) => {
        approveProfile(profile.email)
        setRecentlyEdited(recentlyEdited.filter(item => item.email !== profile.email))
    }

    const showInputToDenyProfile = (i) => {
        setSelectedProfile(i);
        setOpen(true);
    }



    useEffect(() => {
        getUserToApproveDirectory().then(res => {
            setProfileToApprove(res)
        })
        getAllApprovedProfile().then(res => {
            setApprovedProfiles(res)
        })
        getRecentlyEditedProfile().then(res => {
            setRecentlyEdited(res)
        })
    }, [1])

    return(
        <div className="max-w-1220 m-auto">
           <p className="text-3xl mt-5">Profiles waiting for Approval:</p>
           {profileToApprove.map((profile, i) => {
            let certifications = []
            if(profile.professionalCtedu){
                certifications.push('Professional CTEDU')
            }else if(profile.associateCtedu){
                certifications.push('Associate CTEDU')
            }
        
            if(profile.professionalIcf){
                certifications.push('Professional ICF')
            }else if(profile.associateIcf){
                certifications.push('Associate ICF')
            }

            let specializations = []
            if(profile.wellness){specializations.push('Wellness')}
            if(profile.executive){specializations.push('Executive')}
            if(profile.academic){specializations.push('Academic')}
            if(profile.essential){specializations.push('Essential')}
            if(profile.other){specializations.push(profile.otherInfo)}

            let languages = []
            profile.languages && profile.languages.map((language) => {
                languages.push(language.label)
            })
        
            return(
                <div className="border p-5 my-5" key={i}>
                    <div className="flex flex-col sm:flex-row sn:p-5">
                        <img className="rounded-full content-center mx-auto sm:mx-0 mt-5 sm:mt-0 block object-cover h-44 w-44" src={profile.photoLink} alt="Image"/>
                        <div className="sm:ml-20 my-auto">
                            <p><span className="font-bold text-black text-lg">Display Name:</span> {profile.displayName}</p>
                            <p><span className="font-bold text-black text-lg">First Name:</span>  {profile.firstName}</p>
                            <p><span className="font-bold text-black text-lg">Last Name:</span>  {profile.lastName}</p>
                            <p><span className="font-bold text-black text-lg">Email:</span>  {profile.email}</p>
                            <p><span className="font-bold text-black text-lg">Phone:</span>  {profile.phone}</p>
                        </div>
                    </div>
                    <p><span className="font-bold text-black text-lg">First introduction:</span>  {profile.introductory}</p>
                    <p><span className="font-bold text-black text-lg">Bio:</span>  {profile.bio}</p>
                    <div className="flex flex-col sm:flex-row sm:gap-10 my-5">
                    <div>
                        <p className="font-bold text-black text-lg">Certifications:</p>
                        <ul className="list-disc ml-5">
                            {certifications.map((certification, index) => {
                                return(
                                    <li key={index}>{certification}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <p className="font-bold text-black text-lg">Specializations:</p>
                        <ul className="list-disc ml-5">
                            {specializations.map((specialization, index) => {
                                return(
                                    <li key={index}>{specialization}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <p className="font-bold text-black text-lg">Languages: </p>
                        <ul className="list-disc ml-5">
                            {languages.map((language, index) => {
                                return(
                                    <li key={index}>{language}</li>
                                )
                            })}
                        </ul>
                    </div>
                    </div>
                    <p className="font-bold text-black text-lg">Offers Coaching</p>
                    <ul className="list-disc ml-5 mb-5">
                        {profile.inPerson && <li>In-Person</li>}  
                        {profile.videoCall && <li>By Skype / Zoom</li>}
                        {profile.phoneCall &&  <li>Phone Worldwide</li>}
                    </ul>
                    {profile.website && <p><span className="font-bold text-black text-lg">Website: </span><a href={profile.website} target='_blank'>{profile.website}</a></p>}  
                    {profile.instagram && <p><span className="font-bold text-black text-lg">Instagram: </span>{profile.instagram}</p>} 
                    {profile.twitter && <p><span className="font-bold text-black text-lg">Twitter: </span>{profile.twitter}</p>}  
                    {profile.youtube && <p><span className="font-bold text-black text-lg">Youtube: </span>{profile.youtube}</p>}   
                    {profile.facebook && <p><span className="font-bold text-black text-lg">Facebook: </span>{profile.facebook}</p>}   
                    {profile.pinterest && <p><span className="font-bold text-black text-lg">Pinterest: </span>{profile.pinterest}</p>}   
                    {profile.linkedin && <p><span className="font-bold text-black text-lg">Linkedin: </span>{profile.linkedin}</p>}    

                    <p className="font-bold text-black text-lg my-5">Document Submitted: <a href={profile.fileLink}>{profile.fileName2}</a></p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 mt-5">
                        <button className="bg-newBranding-grey hover:bg-newBranding-jet hover:text-white w-full py-4" onClick={() => setApproveProfile(profile)}>APPROVE PROFILE</button>
                        <button className={`${(open === true && selectedProfile === i) && `border-red-500 text-red-500`} w-full py-2 hover:border-red-500 hover:text-red-500 border`} onClick={() => showInputToDenyProfile(i)}>NOT APPROVE</button>
                    </div>
                    {(open === true && selectedProfile === i) && 
                        <div className="grid sm:grid-cols-2">
                            <p></p>
                            <div>
                                <textarea
                                    type="text"
                                    className="block border w-full h-20 p-2"
                                    placeholder="Reasons the profile is not approved (This will appear in their profile when they edit it)"
                                    id="state"
                                    onChange={(e) => setNotApprovedReason(e.target.value)}
                                    value={notApprovedReason}/>
                                <button className="bg-newBranding-grey py-2 w-full text-center hover:text-red-500" onClick={() => notApproveProfile(profile, notApprovedReason)}>Submit</button>
                            </div>  
                        </div>
                    }
                </div>
            )
           })}
           <p className="text-3xl mt-5">Profiles recently edited:</p>
           <ul className=" ml-5 list-disc">
           {recentlyEdited.map((profile, i) => {
            return(
                <li>
                    <a target='_blank' href={`life-coach/${profile.displayName.split(' ').join('-').toLowerCase()}`}>{profile.displayName}</a>
                    <button className=" ml-2 bg-green-500 text-white px-1" onClick={() => editVerified(profile)}>&#10004;</button>
                    <button className=" ml-5 bg-red-500 text-white px-1" onClick={() => {
                        removeEditedProfile(profile.email)
                        setRecentlyEdited(recentlyEdited.filter(item => item.email !== profile.email))
                        }}>&#10006;</button>
                </li>
            )
           })}
           </ul>
           <p className="text-3xl mt-5">Profiles Approved:</p>
           <ul className=" ml-5 list-disc">
           {approvedProfiles.map(profile => {
            return(
                <li><a target='_blank' href={`https://www.coachtrainingedu.com/life-coach/${profile.displayName.split(' ').join('-').toLowerCase()}`}>{profile.displayName}</a></li>
            )
           })}
           </ul>
        </div>
        
    )
}

export default CoachDirectoryDashboard