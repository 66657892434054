import React, {  useState, useEffect } from "react";
import Logo from '../images/LOGO-CTEDU-200px-01.svg'
import Step1 from '../images/step1.png'
import Step2 from '../images/step2.png'
import Step3 from '../images/step3.png'
import Step4 from '../images/step4.png'
import Step5 from '../images/step5.png'
import Step6 from '../images/step6.png'
import Step7 from '../images/step7.png'



function Sidebar(props) {

    const steps =[
        {
            'step' : 1,
            'name' : 'CONTACT INFORMATION',
            'image': Step1
        },
        {
            'step' : 2,
            'name' : 'COURSE SPECIALIZATION',
            'image': Step2
        },
        {
            'step' : 3,
            'name' : 'TRAINING PACKAGES',
            'image': Step3
        },
        {
            'step' : 4,
            'name' : 'SHORT ANSWER QUESTIONS',
            'image': Step4
        },
        {
            'step' : 5,
            'name' : 'COMMUNITY & MEDIA',
            'image': Step5
        },
        {
            'step' : 6,
            'name' : 'EDUCATION STANDARDS',
            'image': Step6
        },
        {
            'step' : 7,
            'name' : 'TERMS AND CONDITIONS',
            'image': Step7
        }
        
    ]
   

  return (
    <div>
        {/* Sidebar for Mobile Version */}
        <div className='w-full block md:hidden text-white pb-3 z-40 pt-3 -mt-1 bg-newBranding-jet fixed'>
            <h1 className="text-white font-serif text-sm text-center font-bold  ">Application For Enrollment</h1>
            <div className="w-9/12 m-auto border-b absolute border-red pt-5  left-1/2 transform -translate-x-1/2"></div>

            <ol className=' grid grid-cols-7 relative w-11/12 m-auto pb-4'>
            {(props.activeStep === 7 ) && <p className='text-xs absolute whitespace-nowrap text-white left-1/2 mt-10 transform -translate-x-1/2'><span className="font-bold">APPLICATION COMPLETED</span></p>}

                    {steps.map((step, i) => {
                        
                        if(props.activeStep > step.step-1){
                            return  <li key={i} className='text-gray-300 my-3'><span className="text-white bg-newBranding-blue block m-auto md:mr-4  md:inline-flex w-5 h-5 rounded-full flex justify-center items-center">&#10003;</span></li>
                        }
                        else if(props.activeStep === step.step-1){
                            return (
                                <>
                                     <li key={i} className='text-newBranding-blue my-3 relative'><span className='border-2 p-2 block m-auto md:mr-4 md:inline-flex border-white bg-white w-3 h-3 rounded-full flex justify-center items-center'>{step.step}</span><span className='hidden md:inline absolute top-1 -right-6'>&#10095;</span></li>
                                     <p className='text-xs absolute whitespace-nowrap text-white left-1/2 mt-10 transform -translate-x-1/2'>STEP {step.step} : <span className="font-bold">{step.name}</span></p>
                                </>
                            )
                        }
                        else{
                            return <li key={i} className='text-white my-3'><span className='border bg-newBranding-jet p-2 block m-auto md:mr-4 md:inline-flex rounded-full border-white w-4 h-4 rounded-full flex justify-center items-center'>{step.step}</span><span className='hidden md:inline'>{step.name}</span></li>  
                        }
                    
                    })}
                </ol>
                {steps.map((step, i) =>{
                if(props.activeStep+1 === step.step){
                    return(
                        <img src={step.image} className='w-28 m-auto' alt="Schedule a 20 minute call Link"/>
                    )
                }
            })}
        </div>

        {/* Sidebar for Laptop and Tablet version*/}
        <div className='h-full hidden md:block bg-newBranding-jet fixed'>
            <h1 className="text-white font-serif text-xl lg:text-xl pt-10 pl-10 max-w-200 lg:max-w-none">Application For Enrollment</h1>
            <div className="min-w-min whitespace-nowrap bg-newBranding-jet  pl-2 pr-2 md:pl-8 md:pr-10 h-fit-content ">
                <ol className=''>
                    {steps.map((step, i) => {
                        if(props.activeStep > step.step-1){
                            return <li key={i} className='text-gray-300 my-5'><span className="text-white bg-newBranding-blue block m-auto md:mr-4 md:inline-flex w-7 h-7 rounded-full flex justify-center items-center">&#10003;</span> <span className='hidden md:inline'>{step.name}</span></li>
                        }
                        else if(props.activeStep === step.step-1){
                            return <li key={i} className='text-white bg-newBranding-white my-5 relative'><span className='border-2 p-2 block m-auto md:mr-4 md:inline-flex border-white text-newBranding-blue bg-white w-7 h-7 rounded-full flex justify-center items-center'>{step.step}</span><span className='hidden md:inline'>{step.name}</span><span className='hidden md:inline absolute top-1 -right-6'>&#10095;</span></li>
                        }
                        else{
                            return <li key={i} className='text-white my-5'><span className='border-2 p-2 block m-auto md:mr-4 md:inline-flex rounded-full border-white w-7 h-7 rounded-full flex justify-center items-center'>{step.step}</span><span className='hidden md:inline'>{step.name}</span></li>
                        }
                    
                    })}
                </ol>
            </div>
            {steps.map((step, i) =>{
                if(props.activeStep+1 === step.step){
                    return(
                        <img src={step.image} className='w-40 m-auto' alt="Schedule a 20 minute call Link"/>
                    )
                }
            })}
        </div>
    </div>
  );
}
export default Sidebar;