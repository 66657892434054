import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useFormik } from "formik"


import {useRef} from 'react';
import Academic from '../images/academy.png'
import Wellness from '../images/wellness.png'
import Executive from '../images/executive.png'
import Essential from '../images/essential.png'


const contentful = require("contentful");
const moment = require('moment-timezone');
const { google, outlook, ics } = require("calendar-link");


var dateFromContentful =[];

var contentfulClient = contentful.createClient({
accessToken: 'UlbWB8DEhyN4UDFI2W8FO3iNHUc-G3lVoGiPPn0siGw',
space: 'ylv2wajz235x'
})


var PRODUCT_CONTENT_TYPE_ID = 'columnDateSchedule'


contentfulClient.getEntries({
content_type: PRODUCT_CONTENT_TYPE_ID
})
.then(function(entries) {
dateFromContentful = entries.items
})








function ClassSelectionForm(props) {
const [course, setCourse] = useState("");
const [dateLoc, setDateLoc] = useState("");
const [errorsValidation, setErrorsValidation] = useState({})
const [initialCourse, setInitialCourse] = useState("")
const [initialDateLoc, setInitialDateLoc] = useState("")
const [dates, setDates] = useState([])
const [courseSelected, setCourseSelected] = useState('')
const [academicDates, setAcademicDates] = useState([])
const [essentialDates, setEssentialDates] = useState([])

const [showDate, setShowDate] = useState('hidden ');
const [showCalendar, setShowCalendar] = useState('hidden');




var localStart='';
var localEnd=''
function getDate(startDate, endDate){
startDate = moment.tz(startDate, "America/Los_Angeles")
endDate = moment.tz(endDate, "America/Los_Angeles")
var localStart = startDate.clone().local()
var localEnd = endDate.clone().local()
var abvTimezone = moment.tz(moment.tz.guess()).format('z')
let dateToDisplay = localStart.format("dddd, MMM Do h:mm a") + " - " + localEnd.format("h:mm a") + " " + abvTimezone
return {dateToDisplay, localStart, localEnd}
}


function getLocalDate(start, end){
localStart = getDate(start, end).localStart
localEnd = getDate(start, end).localEnd
}




let btnRef = useRef();


useEffect(() => {
props.getValue('course').then((result) => {
setCourse(result)
setInitialCourse(result)
if(result != ''){
setShowDate('block mb-5')
setShowCalendar('flex flex-row lg:flex-col mb-15')
}
})
props.getValue('dateLoc').then((result) => {
setDateLoc(result)
setInitialDateLoc(result)
})
}, [props.user]);


const showNextField = (field) => {


if(field === "date"){
setShowDate('block mb-10')
setDateLoc('')
}
}
const validate = (values) => {
const errors = {};
const required = ['course', 'dateLoc']


required.map((value) => {
if(!values[value]) {
errors[value] = 'This field is required.';
}
});


setCourse(values.course)
setDateLoc(values.dateLoc)
};


const validateOnSubmit = (values) => {
const errors = {};
const required = ['course', 'dateLoc']


required.map((value) => {
if(!values[value]) {
errors[value] = 'This field is required.';
}
});
return errors;
};


const formik = useFormik({
initialValues: {
course: course,
dateLoc: dateLoc,
step:2
},
enableReinitialize: true,
validate,


onSubmit: values => {
const errors = validateOnSubmit(values);
if(Object.keys(errors).length === 0){
btnRef.current.setAttribute("disabled", "disabled");


let data = values
let dataChanged = false
if((data.course !== initialCourse) || (data.dateLoc !== initialDateLoc)){
dataChanged = true
}
props.onSubmit(data, dataChanged)
}
setErrorsValidation(errors)
return errorsValidation
},
})


useEffect(() => 
dateFromContentful.map((date, i ) =>{
  if(date.fields.title === "Dates Main Courses"){
    
    date.fields.date.map((d, i) => {
      if(d.fields.courseName === '1.0 Essential'){
        const curr = []
        d.fields.dates.map(data => {
          contentfulClient.getEntry(data.sys.id)
          .then(function(entry) {
            curr.push(getDate(entry.fields.startDate, entry.fields.endDate).dateToDisplay)
          })
        })
        setEssentialDates(curr)
   } 
   else if(d.fields.courseName === '1.0 Academic '){
    console.log('in academic')
    const curr = []
    d.fields.dates.map(data => {
      contentfulClient.getEntry(data.sys.id)
      .then(function(entry) {
        curr.push(getDate(entry.fields.startDate, entry.fields.endDate).dateToDisplay)
      })
    })
    console.log('curr ', curr)
    setAcademicDates(curr)
   }
  })
  }


  
})
, [])


var event = {}
if(localStart !== '' && localEnd != ''){
const hours = localEnd.diff(localStart, 'hours')
event = {
title: "Your class is starting!",
description: "Be there!",
start: localStart.format("YYYY-MM-DD HH:mm:ss ZZ"),
end: localEnd.format("YYYY-MM-DD HH:mm:ss ZZ"),
duration: [hours, "hour"],
location: "https://coachtrainingedu.zoom.us/j/330785543"
};
}
const selected = 'border-2 border-newBranding-blueSelected '


return (
<div>
<div className="">
<form onSubmit={formik.handleSubmit} className=" mb-10 flex flex-col justify-center w-full ">
<label className={`block text-center md:text-left`}>What <span className='font-bold'>1.0 course specialization</span> would you like?*
<div className='items-center m-auto md:ml-0 h-full max-w-max mt-5 mb-10 grid grid-cols-2 lg:grid-cols-4 gap-7 lg:gap-3'>


<label className={`radio-item w-full h-full max-w-170 sm:ml-0 cursor-pointer bg-white py-2 px-5 rounded-2xl relative ${formik.values.course === 'Essential' ? selected : null}`}>
{formik.values.course === 'Essential' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
<input
id="Essential"
value='Essential'
checked={formik.values.course ==='Essential'}
name="course"
type="radio"
className='opacity-0'
onChange={e => {
formik.handleChange(e)
showNextField('date')
}}
/>
<img src={Essential} className='-mt-5 w-24 m-auto' alt='Woman at the window'/>
<p className='max-w-100 m-auto text-base text-center mt-2'>Essential Life Coaching</p>
</label>




<label className={`radio-item h-full w-full max-w-170 cursor-pointer bg-white py-2 px-5 rounded-2xl relative ${formik.values.course === 'Academic' ? selected : null}`}>
{formik.values.course === 'Academic' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
<input
id="Academic"
value='Academic'
checked={formik.values.course ==='Academic'}
name="course"
type="radio"
className='opacity-0'
onChange={e => {
formik.handleChange(e)
showNextField('date')
setCourseSelected('Academic')
}}
/>
<div>
<img src={Academic} className='-mt-5 w-24 m-auto' alt='Man on a bike/ Associate path radio button'/>
<p className='max-w-100 m-auto text-base text-center mt-2'>Academic Life Coaching</p>
</div>
</label>


<label className={`radio-item h-full w-full max-w-170 cursor-pointer bg-white py-2 px-5 rounded-2xl relative ${formik.values.course === 'Wellness' ? selected : null}`}>
{formik.values.course === 'Wellness' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
<input
id="Wellness"
value='Wellness'
checked={formik.values.course ==='Wellness'}
name="course"
type="radio"
className='opacity-0'
onChange={e => {
formik.handleChange(e)
showNextField('date')
}}
/>
<div>
<img src={Wellness} className='-mt-5 w-24 m-auto' alt='Man on a bike/ Associate path radio button'/>
<p className='max-w-100 m-auto text-base text-center mt-2'>Wellness Life Coaching</p>
</div>
</label>






<label className={`radio-item h-full w-full max-w-170 cursor-pointer bg-white py-2 px-5 md:px-10 rounded-2xl relative ${formik.values.course === 'Executive' ? selected : null}`}>
{formik.values.course === 'Executive' ? <span className='absolute ml-10 -top-3 text-white bg-newBranding-blueSelected rounded-full w-5 h-5 rounded-full flex justify-center items-center'>&#10003;</span> : null}
<input
id="Executive"
value='Executive'
checked={formik.values.course ==='Executive'}
name="course"
type="radio"
className='opacity-0'
onChange={e => {
formik.handleChange(e)
showNextField('date')
}}
/>
<img src={Executive} className='-mt-5 w-24 m-auto' alt='Man on a bike/ Associate path radio button'/>
<p className='max-w-100 m-auto text-base text-center mt-2'>Executive Coaching</p>
</label>


</div>


{errorsValidation.course ? <div className='text-red-500'>{errorsValidation.course}</div> : null}
</label>
<div className={`${showDate} flex flex-col `}>
<div>
<label className="hidden md:block">Please choose one <span className='font-bold'>1.0 training course</span> start dates*</label>
<label className="text-center block md:hidden">Please choose one <br/> <span className='font-bold'>1.0 training course</span> start dates*</label>


<div className='flex max-w-400 flex-col mt-5 mb-10'>
<select
id="dateLoc"
name="dateLoc"
className='border border-newBranding-blue border-opacity-50 rounded-md h-8 w-full '
value={formik.values.dateLoc}
onChange={(e) => {
formik.handleChange(e);
}}>
{formik.values.course === 'Academic' && 
<>
  <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
  {academicDates.map((date, i) =>{
    return(
      <option key={i}>{date}</option>
    )}
    )}
    </>
}
{(formik.values.course === 'Essential' || formik.values.course === 'Wellness' || formik.values.course === 'Executive') && 
<>
  <option value="" className="text-newBranding-darkGrey opacity-75">Please Select</option>
  {essentialDates.map((date, i) =>{
    return(
      <option key={i}>{date}</option>
    )}
    )}
    </>
}

</select>
<p className='text-xs mt-2 '>* For a course to run, six enrollments are required for each class. Class date subject to change depending on number of enrollments. CTEDU team will notify participants a few weeks before class begins if any schedule changes are needed.</p>
{errorsValidation.dateLoc ? <div className='text-red-500'>{errorsValidation.dateLoc}</div> : null}
</div>
</div>
</div>
<div className='flex flex-row-reverse justify-between'>
<button ref={btnRef} type="submit" className=' mt-10'><span className='duration-300 transition z-10 no-underline hover:bg-newBranding-jet bg-white text-newBranding-jet hover:text-white border-newBranding-jet rounded-3xl border text-center button-text px-5 py-0 m-1 text-base'>Next Step</span><span className='opacity-75 ml-2'>&#10095;</span></button>
<button className='mt-10 text-sm opacity-75' onClick={() => props.handleBack(formik.values)}><span className='mr-2'>&#10094;</span> PREVIOUS STEP</button>
</div>
</form>
</div>
</div>
);
}
export default ClassSelectionForm;



